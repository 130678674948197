

export function SupersetNotEnabled() {
    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: '100px' }}>
        <h1>PLIABLE BI NOT ACTIVATED ON THIS ACCOUNT</h1>
        <p>Contact  <a href="mailto:carson@pliable.co?subject=Requesting BI access"><strong>carson@pliable.co</strong></a> to have Pliable BI added or to give it a whirl.</p>

        <img src="/images/pliable/bi_sample1.png" alt="Pliable BI Sample" style={{ width: '50vw', height: 'auto', margin: '20px 0' }} />

    </div>
  }