import TenantPicker from "@components/tenant/TenantPicker.component";
import { NavBar } from "./Onboarding/OnboardingWizard.page";
import PliableLogo from '@assets/images/pliable/logo_with_text_black.png';
import { useNavigate } from "react-router-dom";

export default function AccountDisabledPage() {

    const navigate = useNavigate();

    return (
        <div className="container">
            <div className="page-content" style={{width: '100%'}}>
                <NavBar>
                    <div className="flex-1">
                        <img src={PliableLogo}/>
                    </div>
                    <div className="buttons">
                        <TenantPicker/>
                        <button onClick={() => navigate('/logout')} className="btn btn-dark ms-2">
                            <i className="mdi mdi-logout"></i>Logout
                        </button>
                    </div>
                </NavBar>
                <div className="row justify-content-center">
                <div className="col-lg-5">
                    <div className="card mt-5">
                    <div className="card-header pt-4 pb-4 text-center bg-primary">
                        <h1 className="text-white">Oops!</h1>
                    </div>
                    <div className="card-body p-4">
                        <div className="text-center">
                        <h1 className="text-error mt-4">Your account has been disabled</h1>
                        <h4 className="text-uppercase text-danger mt-3">Please contact carson@pliable.co to reactivate.</h4>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
}
                