import AsyncButton from '@components/button/AsyncButton.component';
import LoadingCard from '@components/card/LoadingCard.component';
import Dropdown, { Option } from '@components/form/Dropdown.component';
import PliableLoader from '@components/loaders/PliableLoader.component';
import DraftVersionORM, { PublishPlan } from '@models/draftVersion';
import { requireConfirmation } from '@services/alert/alert.service';
import ApiService, { SingleRecordResponse } from '@services/api/api.service';
import BackgroundService from '@services/bg.service';
import { getErrorMessage } from '@services/errors.service';
import toast from '@services/toast.service';
import { clearDraftData, enterDraftMode, exitDraftMode, invalidateEverything, useAvailableBranches, useDraftVersionId, useIsInDraftMode, useProjectConfig, useUserConfig } from '@stores/data.store';
import { current } from 'immer';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toggleThenaWidget } from "@stores/data.store";

import styled, {css} from 'styled-components';
import PublishPlanChanges from '@components/draft/PublishPlanChanges.component';
import { Pane, PaneContent } from '@pages/PageStructure.component';
import { AuthorizedContent } from "@frontegg/react";
import { Role } from "../../constants/roles";



const DraftBannerStyles = styled.div<{mode: 'DRAFT' | 'PROD'}>`
background-color: var(--ct-light);

button.start-thena-chat {
    ${props =>
        props.mode == 'DRAFT' && css`
            background-color: var(--ct-primary);
        `
    
    }
    ${props =>
        props.mode == 'PROD' && css`
            background-color: var(--ct-dark);
        `
    
    }
    border: solid 3px white;
}
.draft-banner-wrapper {
    overflow-y: hidden;
	max-height: 50px; /* approximate max height */

	transition-property: all;
	transition-duration: 1s;
	transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.draft-banner-wrapper.closed {
	max-height: 0;
}



.draft-banner-box {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0px 1rem 0px 0px;
    
    border-bottom: solid 1px var(--ct-border-color);

    box-shadow: 5px 0px 10px 0px #0000000D;
    z-index: 2;
}

.draft-banner-left {
    padding-left: 15px;
}

.draft-banner-box h1.draft-banner {
    font-size: 14px;
    font-weight: 500;
    font-family: "Poppins";
    text-transform: uppercase;
    padding: 0px;
    margin: 0px;
    line-height: 50px;
}

.page-subtitle {
    font-size: 13px;
}

.draft-banner-right {
    flex: 1;
    text-align: center;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;


    .btn {
        background-color: white;
        padding: 0px 1rem;
        height: 32px;
        line-height: 32px;
        border: 1px solid var(--ct-border-color);
    }

}
`

interface PrInfo {
    title: string;
    description?: string;
    git_source: string;
    external_id: string;
    external_url?: string;
}



const DraftBanner = () => {
    const {data: draftVersionId} = useDraftVersionId();
    const tenantConfig = useProjectConfig();

    const userConfig = useUserConfig();

    const [loadingPullRequest, setLoadingPullRequest] = useState(false);
    const [pullRequestInfo, setPullRequestInfo] = useState<PrInfo|null>(null);
    const [publishPlan, setPublishPlan] = useState<PublishPlan|null>(null);
    const [prMessage, setPrMessage] = useState('');
    const [planning, setPlanning] = useState(false);
    const [publishing, setPublishing] = useState(false);
    const [committing, setCommitting] = useState(false);
    const [publishMessage, setPublishMessage] = useState('');
    const navigate = useNavigate();

    const planPublish = useCallback(async () => {
        setPlanning(true);
        try {
            const plan = await DraftVersionORM.planPublish();
            console.log(plan);
            setPublishPlan(plan);
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setPlanning(false);
        }
        
        
    }, [draftVersionId])

    const doPublish = useCallback(async () => {
        setPublishing(true);
        try {
            const versionIdx = publishPlan?.next_version_idx;
            // clear out the plan once we attempt to use it
            setPublishPlan(null);
            await DraftVersionORM.publishChanges(versionIdx as string, publishMessage);
            await exitDraftMode();
            navigate('/');
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setPublishing(false);
        }
    }, [publishPlan])

    const [undoing, setUndoing] = useState(false);
    const abandonChanges = useCallback(async () => {
        let message: string | JSX.Element = 'Are you sure you want to undo your changes? We will copy everything in production into your develop mode.';
        const confirmed = await requireConfirmation(message, 'Revert Changes', 'Confirm', 'Cancel');
        if (confirmed) {
            setUndoing(true);
            try {
                await DraftVersionORM.undoDraftVersionId();
                await clearDraftData();
            } catch (err) {
                toast('danger', 'Error', getErrorMessage(err));
            } finally {
                setUndoing(false);
            }
        }
    }, [draftVersionId]);

    const exitDraft = useCallback(async () => {
        await exitDraftMode();
    }, [draftVersionId]);

    const showDraftBanner = useMemo(() => {
        // if we have a draftVersionId
        // and we are not on account settings (which currently have no draft mode)
        return !window.location.pathname.startsWith('/account/') && !window.location.pathname.startsWith('/onboarding');
    }, [draftVersionId]);


    const [sourceTruncate, setSourceTruncate] = useState(0);
    useEffect(() => {
        setSourceTruncate(ApiService.getInstance().sourceTruncate || 0);
    });

    const changeSourceTruncate = useCallback((newVal: number) => {
        setSourceTruncate(newVal);
        ApiService.getInstance().setSourceTruncate(newVal);
    }, []);

    const inDraftMode = useIsInDraftMode();

    const viewChanges = useCallback(() => {

    }, []);

    return (
        <DraftBannerStyles mode={inDraftMode ? 'DRAFT' : 'PROD'}>
            {planning || publishPlan && <Modal show size="lg" onHide={() => setPublishPlan(null)}>
                <Modal.Header closeButton>
                    {planning && <Modal.Title>Planning</Modal.Title> }
                    {!planning && <Modal.Title>Publish Version #{publishPlan.next_version_idx}</Modal.Title> }
                </Modal.Header>
                <Modal.Body>
                    {planning && <PliableLoader/> }

                    {publishPlan && <PublishPlanChanges plan={publishPlan} onMessageChange={setPublishMessage} message={publishMessage} /> }
                </Modal.Body>
                {!planning && <Modal.Footer>
                    <Button variant="secondary" onClick={() => setPublishPlan(null)}>
                        Cancel
                    </Button>
                    <Button 
                        variant="success"
                        disabled={!publishPlan || publishPlan.has_any_changes == false}
                        onClick={doPublish}
                    >Confirm Publish</Button>
                </Modal.Footer>}
            </Modal>}
            
            {publishing && <Modal show backdrop="static">
                <Modal.Header>
                    <Modal.Title>Publishing changes to production</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LoadingCard action='Publishing' minHeight="30px" />
                </Modal.Body>
            </Modal>}
            {undoing && <Modal show backdrop="static">
                <Modal.Header>
                    <Modal.Title>Undoing changes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LoadingCard action='Undoing changes' minHeight="30px" />
                </Modal.Body>
            </Modal>}
            


            <div className={`draft-banner-wrapper ${(!showDraftBanner) ? 'closed': ''}`}>
            <div className="draft-banner-box">
                <div className="draft-banner-left">
                    <h1 className="draft-banner">
                        {inDraftMode && <>
                            You are in <strong>Develop</strong> mode.
                           
                        </>}
                        {!inDraftMode && <>production mode</>}
                    </h1>
                </div>
                {inDraftMode && <>
                    <div className="draft-banner-right">
                        <button className="btn ms-3" onClick={exitDraft}>
                            <i className="mdi mdi-exit-to-app"></i> Production Mode
                        </button>
                    
                        
                        {/* <button className="btn ms-3" onClick={viewChanges}>
                            <i className="mdi mdi-content-save"></i> View Changes
                        </button> */}

                        <AsyncButton variant='none' icon="mdi mdi-undo" className="btn ms-3" onClick={abandonChanges} loading={undoing} text="Revert Changes" />

                        <AsyncButton variant='none' icon="mdi mdi-publish" className="btn ms-3" onClick={planPublish} loading={planning} text={(planning) ? "Gathering details..." : "Publish to Production"} />
                        
                    </div>
                </>}
                {!inDraftMode && <>
                    <div className="draft-banner-right">
                        <div>You are in production (read-only) mode. To make configuration changes, please enter develop mode.</div>
                        <AuthorizedContent requiredRoles={[Role.Admin, Role.Builder]} key ="tenant-admin-wrap">
                            <button className="btn ms-3" onClick={() => enterDraftMode('banner')}>
                                Enter Develop Mode
                            </button>
                        </AuthorizedContent>
                    </div>
                </>}
                <div className="draft-banner-end">
                    <button onClick={toggleThenaWidget} className="btn btn-primary start-thena-chat btn-rounded">Get Help</button>
                </div>
                
            </div>
            </div>
        </DraftBannerStyles>
    );
}

export default DraftBanner;

