import PipelineNodeName from "@components/pipelineNodes/PipelineNodeName.component";
import { useDimensions, useMeasures } from "@models/reportBuilder";
import PageStructure, { PageContent } from "@pages/PageStructure.component";
import ApiService from "@services/api/api.service";
import { getErrorMessage } from "@services/errors.service";
import toast from "@services/toast.service";
import { usePipelineNodeRelationships, usePipelineNodes } from "@stores/data.store";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import { Template, useMyTemplates } from "./MyTemplates.page";
import { convertToSnakeCase } from "@services/formatting.service";


const ExportTemplatePage = () => {
    const { templateId } = useParams();

    const myTemplates = useMyTemplates();
    const [templateName, setTemplateName] = useState('');

    
    const nodes = usePipelineNodes();
    const relationships = usePipelineNodeRelationships();
    const measures = useMeasures();
    const dimensions = useDimensions();

    const [templateType, setTemplateType] = useState('');
    const [templateTaxonomicId, setTemplateTaxonomicId] = useState('');
    const [dataModelTemplateId, setDataModelTemplateId] = useState('');

    const [nodeIdsToExport, setNodeIdsToExport] = useImmer<string[]>([]);
    const [relationshipIdsToExport, setRelationshipIdsToExport] = useImmer<string[]>([]);
    const [fieldIdsToExport, setFieldIdsToExport] = useImmer<string[]>([]);
    
    const formRef = useRef<HTMLFormElement>(null);

    const dataModelTemplates = useMemo(() => {
        if (!myTemplates.data) {
            return [];
        }
        return myTemplates.data.filter(t => t.type == 'DATA_MODEL');
    }, [myTemplates.dataUpdatedAt]);

    useEffect(() => {
        if (!templateId || !myTemplates.data || !formRef.current) {
            return;
        }

        const theTemplate = myTemplates.data.find(t => t.id === templateId);
        if (!theTemplate) {
            toast('danger', 'Error', 'Template not found!');
            return;
        }
        setTemplateName(theTemplate.name);
        setDataModelTemplateId(theTemplate.data_model_template_id || '');

        setTemplateType(theTemplate.type);
        setTemplateTaxonomicId(theTemplate.taxonomic_id || '');
        const fieldIds: string[] = [];
    
        theTemplate.included_nodes.forEach(n => {
            n.included_field_ids.forEach(fid => {
                fieldIds.push(`${n.pipeline_node_id}.${fid}`);
            });
        });


        setNodeIdsToExport(theTemplate.included_nodes.map(n => n.pipeline_node_id));
        setRelationshipIdsToExport(theTemplate.include_relationship_ids);
        setFieldIdsToExport(fieldIds);

        
    }, [templateId, myTemplates.dataUpdatedAt, formRef]);

    const filteredNodes = useMemo(() => {
        if (!nodes.data) {
            return [];
        }

        switch (templateType) {
            case 'DATA_MODEL':
                return nodes.data.filter(n => ['DIMENSION', 'DATE_DIMENSION'].includes(n.node_type));
            case 'REPORTING':
                return nodes.data.filter(n => n.node_type == 'REPORT');
            case 'DATA_SOURCE':
                return nodes.data.filter(n => [
                    'SOURCE',
                    'STACK',
                    'MERGE',
                    'SPLIT',
                    'SUMMARIZE',
                    'CUSTOM',
                    'IDENTIFY',
                    'VIEW',
                ].includes(n.node_type));
        }
        return [];
    }, [nodes.dataUpdatedAt, templateType]);
    

    const filteredRelationships = useMemo(() => {
        if (!relationships.data) {
            return [];
        }
        const nodeIds = filteredNodes.map(n => n.id as string);
        return relationships.data.filter(r => {
            return nodeIds.includes(r.child_node_id) || nodeIds.includes(r.parent_node_id);
        });
    }, [relationships.dataUpdatedAt, filteredNodes]);

    const includeAllFields = useCallback((nodeId: string) => {
        if (!formRef.current) {
            return;
        }

        const theNode = filteredNodes.find(n => n.id === nodeId);
        if (!theNode) {
            return;
        }

        setFieldIdsToExport(draft => {
            theNode.fields.forEach(f => {
                const fieldKey = `${nodeId}.${f.id}`
                if (!draft.includes(fieldKey)) {
                    draft.push(fieldKey);
                }
            });
        });
    }, [formRef.current, templateTaxonomicId, filteredNodes])
    const autoSetTaxonomicId = useCallback((nodeId: string) => {
        if (!formRef.current) {
            return;
        }

        const theNode = filteredNodes.find(n => n.id === nodeId);
        if (!theNode) {
            return;
        }

        const taxonomicIdField = formRef.current.elements.namedItem(`node.${theNode.id}.taxonomic_id`) as HTMLInputElement;
        if (!taxonomicIdField) {
            return;
        }

        const nodeTaxonomicId = templateTaxonomicId + '::NODE:' + convertToSnakeCase(theNode.name).toUpperCase();

        taxonomicIdField.value = nodeTaxonomicId;

        theNode.fields.map(f => {
            const taxonomicIdField = formRef.current!.elements.namedItem(`node.${theNode.id}.field.${f.id}.taxonomic_id`) as HTMLInputElement;
            if (taxonomicIdField) {
                taxonomicIdField.value = nodeTaxonomicId + '::FIELD:' + convertToSnakeCase(f.name).toUpperCase();
            }
        });

    }, [formRef.current, templateTaxonomicId, filteredNodes]);

    const [loading, setLoading] = useState(false);
    const handleSubmit = useCallback(async (e: any) => {
        e.preventDefault();
        setLoading(true);
        try {
            const data: {
                [key: string]: any;
            } = {};
    
            Object.keys(e.target.elements).filter(k => Number.isNaN(parseInt(k, 10))).forEach(k => {
                const el = e.target.elements[k];
                if (el.type == 'checkbox') {
                    data[k] = el.checked;
                } else {
                    data[k] = el.value;
                }
            });

            let url: string;
            let method: 'POST' | 'PUT';
            if (templateId) {
                url = `/templates/update/${templateId}`;
                method = 'PUT';
            } else {
                method = 'POST';
                url = `/templates/export`;
            }

            const result = await ApiService.getInstance().request(method, url, data);
            console.log(result);
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setLoading(false);
        }
        
    }, [templateId]);

    return <PageStructure>
        <PageContent>
            <form onSubmit={handleSubmit} ref={formRef}>
            <div className="p-5">
                <h1>Export Template</h1>
                <Form.Group className="mb-2">
                    <Form.Label className="small">Template Name</Form.Label>
                    <Form.Control name="template_name" value={templateName} onChange={(e) => {
                        setTemplateName(e.target.value);
                    }}/>
                </Form.Group>

                <Form.Group className="mb-2">
                    <Form.Label className="small">Template Taxonomic ID</Form.Label>
                    <Form.Control name="taxonomic_id" value={templateTaxonomicId} onChange={(e) => {
                        setTemplateTaxonomicId(e.target.value);
                    }}/>
                </Form.Group>
                
                <Form.Group className="mb-2">
                    <Form.Label className="small">Template Type</Form.Label>
                    <select value={templateType} className="form-control" name="template_type" onChange={(e) => {
                    setTemplateType(e.target.value)
                    }}>
                        <option value="">Select One</option>
                        <option value="DATA_MODEL">Data Model</option>
                        <option value="REPORTING">Data Model &rarr; Reporting</option>
                        <option value="DATA_SOURCE">Data Source &rarr; Data Model</option>
                    </select>
                </Form.Group>
                {['DATA_SOURCE', 'REPORTING'].includes(templateType) && <Form.Group className="mb-2">
                    <Form.Label className="small">Data Model Template</Form.Label>   
                    <select value={dataModelTemplateId} className="form-control" name="data_model_template_id" onChange={(e) => {
                        setDataModelTemplateId(e.target.value);
                    }}>
                        <option value="">Select One</option>
                        {dataModelTemplates.map(t => {
                            return <option value={t.id}>{t.name}</option>
                        })}
                    </select>
                </Form.Group>}
                
                    
                <hr />
                <h2>Select Nodes & Fields</h2>
                
                    {filteredNodes.map(n => {
                        return <div className="shadow-box p-3 mb-3">
                            <h2 className="mb-0">
                            <Form.Check
                                checked={nodeIdsToExport.includes(n.id as string)}
                                name={`node.${n.id}.include`}
                                onChange={(e) => {
                                    setNodeIdsToExport(draft => {
                                        const idx = draft.indexOf(n.id as string);
                                        if (idx >= 0) {
                                            draft.splice(idx, 1);
                                        } else {
                                            draft.push(n.id as string);
                                        }
                                    });
                                }}
                                label={n.name}
                            />
                            </h2>
                            {nodeIdsToExport.includes(n.id as string) && <>
                                <hr />
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    autoSetTaxonomicId(n.id as string);
                                }}>Set All Taxonomic IDs</button>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    includeAllFields(n.id as string);
                                }}>Include All Fields</button>
                                <Form.Group className="mb-2">
                                    <Form.Label className="">Taxonomic ID</Form.Label>
                                    <Form.Control type="text" name={`node.${n.id}.taxonomic_id`} defaultValue={n.taxonomic_id}/>
                                </Form.Group>
                                <Form.Label>
                                    Select Columns
                                </Form.Label>
                                <table className="table table-sm table-bordered table-centered">
                                    <thead>
                                        <tr>
                                            <th>Include</th>
                                            <th>Column</th>
                                            <th>Taxonomic ID</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {n.fields.filter(f => !['FOREIGN_KEY', 'DENORMALIZED'].includes(f.type)).map(f => {
                                            const fieldKey = `${n.id}.${f.id}`;
                                            return <tr>
                                                <td>
                                                    <Form.Check
                                                        name={`node.${n.id}.field.${f.id}.include`}
                                                        checked={fieldIdsToExport.includes(fieldKey)}
                                                        onChange={(e) => {
                                                            setFieldIdsToExport(draft => {
                                                                const idx = draft.indexOf(fieldKey as string);
                                                                if (idx >= 0) {
                                                                    draft.splice(idx, 1);
                                                                } else {
                                                                    draft.push(fieldKey);
                                                                }
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    {f.name}
                                                </td>
                                                <td>
                                                    <Form.Control type="text" name={`node.${n.id}.field.${f.id}.taxonomic_id`} defaultValue={f.taxonomic_id}/>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </>}
                        </div>
                    })}
                    <hr />
                    <h2>Select Relationships</h2>
                    <table className="table table-sm table-bordered table-centered">
                        <thead>
                            <tr>
                                <th>Include</th>
                                <th>Name</th>
                                <th>Parent</th>
                                <th>Child</th>
                                <th>Taxonomic ID</th>
                            </tr>
                        </thead>
                        <tbody>
                        {filteredRelationships.map(r => <tr>
                            <td>
                                <Form.Check
                                    name={`relationship.${r.id}.include`}
                                    checked={relationshipIdsToExport.includes(r.id as string)}
                                    onChange={(e) => {
                                        setRelationshipIdsToExport(draft => {
                                            const idx = draft.indexOf(r.id as string);
                                            if (idx >= 0) {
                                                draft.splice(idx, 1);
                                            } else {
                                                draft.push(r.id as string);
                                            }
                                        });
                                    }}
                                    label=""
                                />
                            </td>
                            <td>
                                {r.name}
                            </td>
                            <td>
                                <PipelineNodeName pipelineNodeId={r.parent_node_id}/>
                            </td>
                            <td>
                                <PipelineNodeName pipelineNodeId={r.child_node_id}/>
                            </td>
                            <td>
                                <Form.Control type="text" name={`relationship.${r.id}.taxonomic_id`} defaultValue={r.taxonomic_id}/>

                            </td>
                            </tr>)}
                        </tbody>
                    </table>
                    
                
                
                    <hr />
                    <input disabled={loading} className="btn btn-lg btn-success" type="submit"/>

            </div>
            </form>
        </PageContent>
    </PageStructure>
}

export default ExportTemplatePage;