import { PipelineNode } from "@models/pipelineNode";
import { useIsInDraftMode } from "@stores/data.store";
import { Form } from "react-bootstrap";
import { Updater } from "use-immer";

interface Props {
    node: PipelineNode;
    onChange: Updater<PipelineNode>;
}

const PipelineNodeBasicConfiguration = (props: Props) => {
    const inDraft = useIsInDraftMode();
    return <div>
        <h2>
            <i className="mdi mdi-information-outline"></i> Basic Information
        </h2>
        <Form.Group className="mb-3">
            <Form.Label>Node Name</Form.Label>
            <Form.Control className="w-100" value={props.node.label} disabled={!inDraft} onChange={(e) => {
                props.onChange(draft => {
                    draft.label = e.target.value;
                });
            }}/>
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control as="textarea" className="w-100" disabled={!inDraft} value={props.node.description} onChange={(e) => {
                props.onChange(draft => {
                    draft.description = e.target.value;
                });
            }}/>
        </Form.Group>
    </div>;
};

export default PipelineNodeBasicConfiguration;