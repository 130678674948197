import BusinessObjectFieldTypeSelector from "@components/businessObjects/BusinessObjectFieldTypeSelector.component";
import Dropdown from "@components/form/Dropdown.component";
import { PipelineNodeField } from "@models/pipelineNode";
import { Editor } from "@monaco-editor/react";
import { useIsInDraftMode, useProjectConfig } from "@stores/data.store";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Form, Offcanvas} from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { useDebouncedCallback } from "use-debounce";
import PipelineNodeFieldMap from "./PipelineNodeFieldMap.component";
import PipelineNodeFieldCellActions from "./PipelineNodeFieldCellActions.component";
import FormatForm from "@pages/SourceRecordType/FormatForm.component";
import { useImmer } from "use-immer";
import PipelineNodeSelector from "../PipelineNodeSelector.component";
import PipelineNodeColumnSelector from "../PipelineNodeColumnSelector.component";
import { shortid } from "@services/id.service";
import PipelineNodeFieldTranslation from "../PipelineNodeFieldTranslation.component";
import { DraftOnly } from "@components/project/DraftModeRequired.component";

const EditorStyles = styled.div`
.monaco-editor .suggest-widget { 
    width: 280px !important; 
    left: 0px !important;
}
`

interface Transformer {
    label: string;
    description: string;
    value: string;
    takesArguments?: boolean;
    snowflakeOnly?: boolean;
}

const TransformerOptions: Transformer[] = [
{
    label: 'Translate',
    description: 'Manually translate values using a Pliable-managed mapping table',
    value: 'TRANSLATE',
},
{
    label: 'Clean Zip Code',
    description: 'Cleans up zip codes by normalizing to 5 digits and ensuring there are leading zeroes',
    value: 'CLEAN_ZIP_CODE',
}, {
    label: 'Clean Phone Numbers',
    description: 'Normalizes phone numbers to (XXX) XXX-XXXX format',
    value: 'CLEAN_PHONE',
}, {
    label: 'Convert to Lowercase',
    description: 'Converts text values to all lowercase',
    value: 'LOWERCASE',
}, {
    label: 'Convert to Uppercase',
    description: 'Converts text values to all uppercase',
    value: 'UPPERCASE',
}, {
    label: 'Remove Non-Numeric Characters',
    description: 'Strips all characters that are not digits',
    value: 'REMOVE_NON_NUMERIC',
}, {
    label: 'Remove Special Characters',
    description: 'Strips all punctuation and special characters',
    value: 'REMOVE_SPECIAL_CHARS',
}, {
    label: 'Normalize Dates',
    description: 'Parses and cleans dates by trying a variety of common formats',
    value: 'PARSE_DATE',
}, {
    label: 'Convert to "Slug" Format',
    description: 'Converts text to a web-safe "slug" format, like "New York" -> "new-york"',
    value: 'SLUGIFY',
}, {
    label: 'Get URL Path',
    description: 'Extracts the path component from a URL (https://pliable.co/landing-page will return "/landing-page")',
    value: 'URL_PATH',
}, {
    label: 'Get URL Domain',
    description: 'Extracts the domain component from a URL (https://pliable.co/landing-page will return "pliable.co")',
    value: 'URL_DOMAIN',
}]


interface Props {
    field: PipelineNodeField;
    onChange: (attr: keyof PipelineNodeField, value: any) => void;
    isMerging?: boolean;
    combineBehavior?: string;
    allFields: PipelineNodeField[];
    onEditTranslations: (fieldId: string) => void;
    otherFieldsForAutocomplete: string[];
    disableMultiple?: boolean;
    displayJoinPath?: boolean;
    pipelineNodeId: string;
    onSave?: (updatedField: PipelineNodeField) => void;
    onCancel?: () => void;
    upstreamNodeIds?: string[];
}
const PipelineNodeFieldEditor = (props: Props) => {
    const config = useProjectConfig();

    const translationsRef = useRef<{
        save: () => void;
    }>(null);
    
    const inDraftMode = useIsInDraftMode();

    const [editingField, setEditingField] = useImmer<PipelineNodeField | null>(null);

    useEffect(() => {
        if (props.field) {
            setEditingField(props.field);
        }
    }, [props.field]);

    const editorRef = useRef<any>();
    const [completionDisposable, setCompletionDisposable] = useState<any>();
    
    const editorDidMount = useCallback((editor: any, monacoParam: any) => {
        editorRef.current = editor;

        setCompletionDisposable(
            monacoParam.languages.registerCompletionItemProvider('*', {
                provideCompletionItems: function(model: any, position: any) {

                    // add BO objects
                    const suggestions: any[] = props.otherFieldsForAutocomplete.map(f => {
                        return {
                            label: f,
                            kind: monacoParam.languages.CompletionItemKind.Variable,
                            insertText: `"${f}"`

                        }
                    })
    

                    return {
                        suggestions: suggestions
                    };
                }
            })
        );
    }, [props.otherFieldsForAutocomplete]);


    const availableTransformers = useMemo(() => {
        if (!config.data) {
            return [];
        }

        if (config.data.database == 'SNOWFLAKE') {
            return TransformerOptions.map(o => {
                return {
                    value: o.value,
                    label: o.label,
                }
            });
        }
        return TransformerOptions.filter(o => !o.snowflakeOnly).map(o => {
            return {
                value: o.value,
                label: o.label,
            }
        });
    }, [config.dataUpdatedAt]);

    const changeTitle = useDebouncedCallback((value) => {
        props.onChange('label', value);
    }, 500);

    const changeDescription = useDebouncedCallback((value) => {
        props.onChange('description', value);
    }, 500);

    const [mappingNewField, setMappingNewField] = useState(false);
    const [mappingNewFieldNodeId, setMappingNewFieldNodeId] = useState('');
    const [mappingNewFieldId, setMappingNewFieldId] = useState('');
    const mapNewField = useCallback(() => {
        setEditingField(draft => {
            draft?.map_options.push({
                source_node_id: mappingNewFieldNodeId,
                attribute_id: mappingNewFieldId,
                id: shortid(),
            });
        });
        setMappingNewField(false);
        setMappingNewFieldId('');
        setMappingNewFieldNodeId('');
    }, [mappingNewFieldId, mappingNewFieldNodeId]);

    const [showTranslations, setShowTranslations] = useState(false);

    const onSave = useCallback(async () => {
        if (!editingField) {
            return;
        }

        if (props.onSave) {
            props.onSave(editingField);
        }
        if (translationsRef.current) {
            await translationsRef.current.save();
        }
    }, [props.onSave, editingField, translationsRef.current]);   

    if (props.field.type == 'FOREIGN_KEY') {
        return <>
            <div className="p-2">
                <h3>{props.field.label}</h3>
                <p>This is a <strong>foreign key</strong> field managed by a <Link to={`/relationship/${props.field.relationship_id}`}>relationship</Link>.</p>
                {/* TODO - allow them to edit join params here */}

            </div>
            <div>
                <div className="mx-2">
                    <hr />
                    {/* <AsyncButton loading={false} variant="secondary" text="Cancel" onClick={() => props.handleClose(true)}/> */}
                </div>
            </div>
        </>
    } else if (props.field.type == 'DENORMALIZED') {
        return <>
            <div className="p-2">
                <h3>{props.field.label}</h3>
                <p>This is a <strong>lookup</strong> field managed by a <Link to={`/relationship/${props.field.relationship_id}`}>relationship</Link>.</p>
                {/* TODO - allow them to edit the lookup field info here */}
            </div>
            <div>
                <div className="mx-2">
                    <hr />
                    {/* <AsyncButton loading={false} variant="secondary" text="Cancel" onClick={() => props.handleClose(true)}/> */}
                </div>
            </div>
        </>

    } else if (props.field.type == 'IDENTITY') {
        return <>
            <div className="p-2">
                <h3>{props.field.label}</h3>
                <p>This is an <strong>identity</strong> field automatically added by Pliable</p>
            </div>
            <div>
                <div className="mx-2">
                    <hr />
                    {/* <AsyncButton loading={false} variant="secondary" text="Cancel" onClick={() => props.handleClose(true)}/> */}
                </div>
            </div>
        </>
    }
    return <>
        <Offcanvas.Header className="d-flex justify-content-between align-items-center sticky-header">
            <Offcanvas.Title>
                Configure Column
            </Offcanvas.Title>
            <div>
                <button 
                    className="btn btn-success btn-sm me-1" 
                    onClick={onSave}
                >Save Column</button>
                <button className="btn btn-outline-secondary btn-sm" onClick={() => {
                    props.onCancel && props.onCancel();
                }}>Cancel</button>
            </div>
        </Offcanvas.Header>
      <div className="p-3">
            <Form.Group className="mb-3">
                <Form.Label>Label</Form.Label>
                <Form.Control type="text" disabled={!inDraftMode} value={editingField?.label} onChange={(e) => {
                    setEditingField(draft => {
                        if (!draft) {
                            return;
                        }
                        draft.label = e.target.value;
                    });
                }}/>
            </Form.Group>
            <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control disabled={!inDraftMode} 
                    value={editingField?.description}
                    onChange={(e) => {
                        setEditingField(draft => {
                            if (!draft) {
                                return;
                            }
                            draft.description = e.target.value;
                        });
                    }}
                    as="textarea"
                />
            </Form.Group>
            <hr />
            <h2>
                <i className="mdi mdi-format-font"></i> Formatting Options
            </h2>
            <Form.Group className="mb-3">
                <Form.Label>Datatype</Form.Label>
                <BusinessObjectFieldTypeSelector
                    disabled={!inDraftMode} 
                    className="input-sm"
                    selected={editingField?.type || ''}
                    onSelect={newVal => {
                        setEditingField(draft => {
                            if (!draft) {
                                return;
                            }
                            draft.type = newVal;
                        });
                    }}
                />
            </Form.Group>
            <DraftOnly>
                <Form.Group className="mb-3">
                    <Form.Label>Display Format</Form.Label>
                    <FormatForm
                        selectedFormat={editingField?.formatter || ''}
                        onChange={(newFormat) => {
                            setEditingField(draft => {
                                if (!draft) {
                                    return;
                                }
                                draft.formatter = newFormat;
                            });
                        }}
                    />
                </Form.Group>
            </DraftOnly>
            
            {!props.field.advanced_mode && (
                <>
                    {props.isMerging && props.combineBehavior == 'AGGREGATE' && !props.field.part_of_composite_key && editingField?.map_options && editingField?.map_options.length >= 1 && (
                        <Form.Group className="mb-3">
                            <Form.Label>Aggregation Behavior</Form.Label>
                            <Dropdown
                                disabled={!inDraftMode}
                                selected={editingField?.merge_behavior}
                                options={[
                                    {
                                        value: 'PRIORITIZE',
                                        label: 'Pick One',
                                        description: 'Pliable will choose the first non-null value it can find',
                                    }, {
                                        value: 'COUNT_DISTINCT',
                                        label: 'Count Distinct',
                                        description: 'Count the total distinct values',
                                    }, {
                                        value: 'CONCAT',
                                        label: 'Concatenate',
                                        description: 'String values will be combined into a single comma-separated string',
                                    }, {
                                        value: 'SUM',
                                        label: 'Sum',
                                        description: 'Numeric values will be added together for all merged records',
                                    }, {
                                        value: 'MIN',
                                        label: 'Minimum',
                                        description: 'Pliable will pick the minimum value',
                                    }, {
                                        value: 'MAX',
                                        label: 'Maximum',
                                        description: 'Pliable will pick the maximum value',
                                    },
                                    {
                                        value: 'AVG',
                                        label: 'Average',
                                        description: 'Pliable will average the values',
                                    }
                                ]}
                                onChange={(selected: string) => {
                                    setEditingField(draft => {
                                        if (!draft) {
                                            return;
                                        }
                                        draft.merge_behavior = selected;
                                    });
                                }}
                            />     
                        </Form.Group>
                    )}
                    <Form.Group className="mb-3">
                        <Form.Label>Transform (Optional)</Form.Label>
                        <Dropdown
                            disabled={!inDraftMode}
                            placeholder="None"
                            className="input-sm"
                            selected={editingField?.transformer}
                            onChange={(selected: string) => {
                                setEditingField(draft => {
                                    if (!draft) {
                                        return;
                                    }
                                    draft.transformer = selected;
                                });
                            }}
                            options={availableTransformers}
                        />
                        {editingField?.transformer == 'TRANSLATE' && inDraftMode && (
                            <button className="mt-1 icon-button" onClick={() => {
                                setShowTranslations(!showTranslations);
                            }}>
                                {showTranslations && <span><i className="mdi mdi-eye-off"></i> Hide Translations</span>}
                                {!showTranslations && <span><i className="mdi mdi-eye"></i> Show Translations</span>}
                            </button>
                        )}
                        {editingField?.transformer == 'TRANSLATE' && inDraftMode && showTranslations && (
                            <div className="card">
                                <div className="card-body">
                                    <PipelineNodeFieldTranslation
                                        pipelineNodeId={props.pipelineNodeId}
                                        fieldId={props.field.id}
                                        ref={translationsRef}
                                    />
                                    
                                </div>
                                
                            </div>
                            
                        )}
                    </Form.Group>
                </>
            )}
            <Form.Group>
                <Form.Check
                    disabled={!inDraftMode}
                    type="switch"
                    label="Custom formula"
                    checked={editingField?.advanced_mode}
                    onChange={(e) => {
                        setEditingField(draft => {
                            if (!draft) {
                                return;
                            }
                            draft.advanced_mode = !draft.advanced_mode;
                        });
                    }}
                />
            </Form.Group>
            <hr />
            {!editingField?.advanced_mode && <>
                <div className="mb-2 d-flex center-vertically">
                    <h2 className="mb-0 flex-1">
                        <i className="mdi mdi-pipe"></i> Mapped Source Columns
                        
                    </h2>
                    <DraftOnly>
                        <button className="icon-button" onClick={() => {
                            setMappingNewField(true)
                        }}>
                            <i className="mdi mdi-plus-circle"></i> Add
                        </button>
                    </DraftOnly>
                </div>
                {mappingNewField && <div className="mb-2">
                    <Form.Label className="small">Map New Field</Form.Label>
                    <Form.Group className="mb-2">
                        <PipelineNodeSelector
                            selectedId={mappingNewFieldNodeId}
                            optionFilter={pn => props.upstreamNodeIds?.includes(pn.id as string) || false}
                            onSelect={(newNode) => {
                                setMappingNewFieldNodeId(newNode?.id as string || '');
                            }}
                        />
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <PipelineNodeColumnSelector
                            selectedId={mappingNewFieldId}
                            pipelineNodeId={mappingNewFieldNodeId}
                            disabled={!mappingNewFieldNodeId}
                            onSelect={(newFieldId) => {
                                setMappingNewFieldId(newFieldId || '');
                            }}
                        />
                    </Form.Group>
                    <button disabled={!mappingNewFieldId || !mappingNewFieldNodeId} className="me-1 btn btn-sm btn-outline-primary" onClick={mapNewField}>Add</button>
                    <button className="btn btn-sm btn-outline-secondary" onClick={() => {
                        setMappingNewField(false);
                        setMappingNewFieldId('');
                        setMappingNewFieldNodeId('');
                    }}>Cancel</button>
                </div>}
                <PipelineNodeFieldMap
                    showPriority={false}
                    mapOptions={editingField?.map_options || []}
                    onChangeMapOptions={(newOptions) => {
                        setEditingField(draft => {
                            if (!draft) {
                                return;
                            }
                            draft.map_options = newOptions;
                        });
                    }}
                    displayJoinPath={props.displayJoinPath}
                />
            </>}
            {editingField?.advanced_mode && <>
                <h2>Custom SQL</h2>
                <EditorStyles>
                    <Editor
                        onChange={(value) => {
                            setEditingField(draft => {
                                if (!draft) {
                                    return;
                                }
                                draft.custom_sql = value;
                            });
                        }}
                        height="150px"
                        defaultLanguage="sql"
                        options={{
                            readOnly: !inDraftMode,
                            minimap: { enabled: false },
                        }}
                        defaultValue={editingField.custom_sql}
                        onMount={(editor, monaco) => editorDidMount(editor, monaco)}
                    />
                </EditorStyles>
            </>}
            <hr />
            <h2>Custom Actions</h2>
            <PipelineNodeFieldCellActions 
                    cellActions={props.field.cell_actions} 
                    allFields={props.allFields}
                    onChangeCellActions={(newActions) => {
                        props.onChange('cell_actions', newActions);
                }} />
        </div>
        <div className="mb-2">
        </div>
    </>
}

export default PipelineNodeFieldEditor;
