import { useIsSupersetEnabled } from "@stores/data.store";
import { PropsWithChildren } from 'react'

export function HideIfSuperset ({ children }: PropsWithChildren) {
    const isSupersetEnabled = useIsSupersetEnabled();
    if (!isSupersetEnabled) {
        return <>{children}</>
    }
    //if enabled, hide children
    return <></>;
}

export function SupersetRequired ({ children }: PropsWithChildren) {
    const isSupersetEnabled = useIsSupersetEnabled();
    if (isSupersetEnabled) {
        return <>{children}</>
    }
    // if not enabled, hide children
    return <></>;
}

