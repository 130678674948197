import { SupportOptionRange } from "prettier";
import React, { Component, createContext, useState, ReactNode } from "react";
import ApiService, { ApiConnectionError } from "../api/api.service";
import { parseDateString } from "../time.service";
import TenantConfigService from "@services/tenantConfig.service";
import { ExecutionSchedule } from "@models/executionSchedule";



interface FeatureFlags {
  [key: string]: boolean;
}

export enum Backends { 
  Snowflake = "SNOWFLAKE",
  Pliable = "PLIABLE"
} 

export interface FormatLocale {
  decimal: string;
  thousands: string;
  grouping: number[];
  currency: [string, string];
}

export interface TenantInfra {
  is_ready: boolean;
}

export interface SurveyDataSources {
  selections: string[];
  otherCRM: string;
  otherERP: string;
  otherLMS: string;
  otherOutreachTool: string;
  otherAnalyticsTool: string;
  otherProductDatabase: string;
  otherProductivityTool: string;
  otherAdPlatform: string;
  otherAccountingTool: string;
  otherSellingPlatform: string;
  otherHRPlatform: string;
  otherAffiliatePlatform: string;
  otherSupportTool: string;
  otherSportsbook: string;
  otherSEO: string;
  otherPAM: string;
}

export interface SurveyAnswers {
  database: {
    hasWarehouse?: boolean;
    hasEtl?: boolean;
    warehouse: string;
    otherWarehouse?: string;
    etl: string;
    selfServicePreference?: string;
    onboardingEventLink?: string;
    onboardingInviteeLink?: string;
    usesDBT?: boolean;
  },
  businessType: {
      products: boolean;
      referrals: boolean;
      services: boolean;
      assets: boolean;
  };
  industry: {
      selection: string;
      otherValue: string;
  };
  customers: {
      smbs: boolean;
      enterprises: boolean;
      consumers: boolean;
  };
  useCases: {
      selections: string[];
      otherValue: string;
  };
  personal: {
    isTechnical?: boolean;
  };
  dataSources: SurveyDataSources;
};

export interface HostedSuperset {
  created_at: string;
  db_name: string;
};

// Snake case here to be interoperable with the back end
export interface Config {
  fivetran_destination_id: string;
  fivetran_group_id: string;
  flags: FeatureFlags;
  id: string;
  io_bucket: string;
  short_id: string;
  tenant_slug: string;
  tenant_uuid: string;
  infra: TenantInfra;
  is_disabled: boolean;
  database?: Backends;
  last_used_template?: string;
  last_used_template_done_at?: string;
  build_schedule: ExecutionSchedule;
  github_installation_id: string;
  github_repo_name: string;
  git_source?: string;
  git_default_branch?: string;
  pliable_repo_meta?: any;
  onboarding_info?: SurveyAnswers;
  format_locale?: FormatLocale;
  superset?: HostedSuperset;
}

export interface UserConfig {
  frontegg_id: string;
  current_branch: string;
  dbt_schema_prefix: string;
  has_github_access: boolean;
}


// A "provider" is used to encapsulate only the
// components that needs the state in this context


interface Props {
  children: ReactNode;
}

interface State {
  loading: boolean;
  config: Config|null;
}

export default class ConfigService {
  private static instance: ConfigService;

  config: Config|null;
  userConfig: UserConfig|null;
  loading: boolean;

  public static getInstance(): ConfigService {
    if (!ConfigService.instance) {
        ConfigService.instance = new ConfigService();
    }

    return ConfigService.instance;
  }

  constructor() {
    this.config = null;
    this.userConfig = null;
    this.loading = true;
  }

  public async loadUserConfig() {
    const userConfig = await ApiService.getInstance().request('GET', '/user-config') as UserConfig;
    this.userConfig = userConfig;

  }

  public async updateFormatLocale(newFormatLocale: FormatLocale) {
    if (this.config) {
        this.config.format_locale = newFormatLocale;
    }
}

  public async loadConfig() {
    try{
      const data = await ApiService.getInstance().request('GET', '/config');
      this.config = data as Config;
      this.checkRouting();
    }catch(ex){
      if(ex instanceof ApiConnectionError){
        window.location.href= '/unable-to-connect';
        return;
      }
      console.error(ex);
    }
  }

  public async checkRouting() {
    if(this.config == null) {
        return;
    }

    // If their account is disabled redirect them to the access disabled page
    if (this.config.is_disabled) {
      if (window.location.pathname !== '/account-disabled' && window.location.pathname !== '/logout') {
        window.location.href = '/account-disabled';
      }
      return;
    }

    if (this.config.infra.is_ready && this.config.last_used_template && !this.config.last_used_template_done_at) {
      // they signed up via a template
      if(!window.location.pathname.startsWith('/template')) {
          const redirectUrl = `/template/${this.config.last_used_template}?autouse=true`;
        if(!this.config.database) {
          // If they haven't chosen their database
          const setDbandRedirect = async () => {
              const newConfig = await TenantConfigService.getInstance().saveDatabaseChoice(this.config!.id, 'PLIABLE');
              window.location.href = redirectUrl;
          }
          setDbandRedirect();
        }else{
          window.location.href = redirectUrl;
        }
      }
    }else if((!this.config.database || !this.config.infra.is_ready)  && !window.location.pathname.startsWith('/onboarding')) {
      // If they haven't chosen their database yet route them to choose and set up their DB
      window.location.href = '/onboarding';
    }
  }
}

