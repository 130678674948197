import { useCallback, useMemo } from 'react';
import { Badge, Dropdown as BSDropdown, DropdownButton } from 'react-bootstrap';
import Select, { InputProps, SingleValueProps, components as ReactSelectComponents, OptionProps, SingleValue, MultiValue, IndicatorsContainerProps } from 'react-select';
import styled from 'styled-components';
import CreatableSelect from 'react-select/creatable';

const SelectorOptionStyles = styled.div`
padding: 0px;

h6 {
    font-family: "Nunito";
    font-weight: 400;
}
`

const SelectorOptionWrapperStyles = styled.div`
&:hover {
    cursor: pointer;
    background-color: var(--pliable-yellow-light);
}

&.selected {
    background-color: var(--pliable-blue-bg);
}

padding: 8px;
`

export interface Option {
    label: string;
    value: string;
    description?: string;
    badgeText?: string;
    badgeVariant?: string;
}

interface ColumnSelectorOptionProps {
    option: Option;
}

const SelectorOption = (props: ColumnSelectorOptionProps) => {
    return <SelectorOptionStyles>
            <div  style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div style={{flex: 1, display: 'block'}}>
                    <h6 className="mb-0">{props.option.label}</h6>
                    {props.option.description && (
                        <div style={{lineHeight: '12px', fontSize: '12px'}}>
                            <span>{props.option.description}</span>
                        </div>
                    )}
                </div>
                <div>
                    {props.option.badgeText && <Badge bg={props.option.badgeVariant}>{props.option.badgeText}</Badge>}
                </div>
            </div>

            
            
            
        </SelectorOptionStyles>
}

const SelectorValue = (props: SingleValueProps<Option>) => {
    return <div style={{gridArea: '1/1/2/3'}}>
        <SelectorOption option={props.data}/>
    </div>   
}

const SelectorInputWithRoomForDescription = (props: InputProps<Option>) => {
    return <div style={{height: '53.6px', gridArea: '1/1/2/3', lineHeight: '49.6px'}}>

    
        <ReactSelectComponents.Input {...props}>{props.children}</ReactSelectComponents.Input>
    </div>
}

const SelectorInputOneLiner = (props: InputProps<Option>) => {
    return <div style={{height: '36px', gridArea: '1/1/2/3', lineHeight: '28px'}}>

    
        <ReactSelectComponents.Input {...props}>{props.children}</ReactSelectComponents.Input>
    </div>
}

const SelectorInputOneLinerSmall = (props: InputProps<Option>) => {
    return <div style={{height: '16px', gridArea: '1/1/2/3', lineHeight: '16px'}}>
    
        <ReactSelectComponents.Input {...props}>{props.children}</ReactSelectComponents.Input>
    </div>
}

const SelectorOptionWrapper = (props: OptionProps<Option>) => {
    return <div ref={props.innerRef} {...props.innerProps}>
        <SelectorOptionWrapperStyles className={props.isSelected  ? 'selected' : ''}>
            <SelectorOption option={props.data}/>
        </SelectorOptionWrapperStyles>
    </div>
}

const SmallIndicatorsContainerStyles = styled.div`
div {
    padding: 0px !important;
}
`

const SelectorIndicatorsContainerSmall = (props: IndicatorsContainerProps<Option>) => {
    return (
        <SmallIndicatorsContainerStyles><ReactSelectComponents.IndicatorsContainer {...props}/></SmallIndicatorsContainerStyles>
    
    );
}

interface Props {
    selected?: string;
    placeholder?: string;
    options: Option[];
    onChange: (newValue: string) => void;
    allowEmpty?: boolean;
    className?: string;
    size?: string;
    allowCreate?: boolean;
    onCreateOption?: (inputValue: string) => any;
    disabled?: boolean;
    isClearable?: boolean;
    variant?: string;
    btnTitleIcon?: string;
    btnVariant?: string;
}

const Dropdown = (props: Props) => {
    let InputComponent: any;
    let IndicatorsComponent: any;
    

    if (props.options.length > 0 && !!props.options[0].description) {
        InputComponent = SelectorInputWithRoomForDescription;
    } else if (props.size == 'sm') {
        InputComponent = SelectorInputOneLinerSmall;
    } else {
        InputComponent = SelectorInputOneLiner;
    }

    if (props.size == 'sm') {
        IndicatorsComponent = SelectorIndicatorsContainerSmall;
    } else {
        IndicatorsComponent = ReactSelectComponents.IndicatorsContainer;
    }
    const handleChange = useCallback((e: SingleValue<Option>|MultiValue<Option>) => {
        if (e) {
            // @ts-ignore
            props.onChange(e.value);
        } else {
            props.onChange('');
        }
    }, [props.onChange]);

    const value = useMemo(() => {
        const normalize = (str: string) => str.replace(/["\\]/g, '');
        const found = props.options.find(c => normalize(c.value) === normalize(props.selected || ''));
        if (found) {
            return found;
        } else if (props.allowEmpty) {
            return {
                label: props.placeholder ? props.placeholder : 'Select',
                value: '',
            };
        }
        return undefined;
        
    }, [props.selected, props.options, props.placeholder]);

    const options = useMemo(() => {
        if (props.allowEmpty) {
            return [{
                label: props.placeholder ? props.placeholder : 'Select',
                value: '',
            }, ...props.options];
        }
        return props.options;
    }, [props.allowEmpty, props.options, props.placeholder])

    

    if (props.allowCreate) {
        return <>
            <CreatableSelect
                isDisabled={props.disabled}
                isClearable={props.isClearable !== false}
                options={options}
                value={value}
                onChange={handleChange}
                onCreateOption={props.onCreateOption}
                styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
                    input: (styles: any) => {
                        return {
                            ...styles,
                            padding: '0px',
                            margin: '0px',
                        }
                    },
                    option: (styles: any) => {
                        return {
                            ...styles,
                            margin: '0px',
                            padding: '0px 8px',
                        }
                    }
                }}
                components={{ 
                    Option: SelectorOptionWrapper, 
                    SingleValue: SelectorValue,
                    Input: InputComponent,
                    IndicatorsContainer: IndicatorsComponent,
                }}
            />
        </>
    } else if (props.variant == 'button') {
        return <DropdownButton
            title={
                <>
                    {props.btnTitleIcon && <><i className={props.btnTitleIcon}/>&nbsp;</>}
                    {value ? value.label : props.placeholder}
                </>
            }
            size="sm"
            variant={props.btnVariant || 'secondary'}
        >
            {options.map(o => {
                return <BSDropdown.Item
                    onClick={() => {
                        props.onChange(o.value)
                    }}
                >{o.label}</BSDropdown.Item>
            })}
        </DropdownButton>
        
    }
    return <>
        <Select
            isDisabled={props.disabled}
            className={props.className}
            placeholder={props.placeholder}
            isClearable={props.isClearable !== false}
            styles={{
                // Fixes the overlapping problem of the component
                menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
                input: (styles: any) => {
                    return {
                        ...styles,
                        padding: '0px',
                        margin: '0px',
                    }
                },
                option: (styles: any) => {
                    return {
                        ...styles,
                        margin: '0px',
                        padding: '0px 8px',
                    }
                }
            }}
            components={{ 
                Option: SelectorOptionWrapper, 
                SingleValue: SelectorValue,
                Input: InputComponent,
                IndicatorsContainer: IndicatorsComponent,
            }}
            options={options}
            value={value}
            onChange={handleChange}
        />
    </>
}

export default Dropdown;

interface MultiDropdownProps {
    selected: string[];
    options: Option[];
    placeholder?: string;
    onChange: (newValue: string[]) => any;
    className?: string;
    disabled?: boolean;
}

export const MultiDropdown = (props: MultiDropdownProps) => {
    const handleChange = useCallback((e: SingleValue<Option>|MultiValue<Option>) => {
        if (e) {
            // @ts-ignore
            props.onChange(e ? e.map(v => v.value) : []);
        } else {
            props.onChange([]);
        }
    }, [props.onChange]);

    const value = useMemo(() => {
        const found = props.options.filter(c => props.selected.includes(c.value));

        if (found) {
            return found;
        }
        return [{
            label: props.placeholder ? props.placeholder : 'Select',
            value: '',
        }];
    }, [props.selected, props.options, props.placeholder]);

    const options = useMemo(() => {
        return props.options;
    }, [props.options, props.placeholder])
    return <>
        <Select
            isDisabled={!!props.disabled}
            isMulti
            className={props.className}
            placeholder={props.placeholder}
            styles={{
                // Fixes the overlapping problem of the component
                menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
                input: (styles: any) => {
                    return {
                        ...styles,
                        padding: '0px',
                        margin: '0px',
                    }
                },
                option: (styles: any) => {
                    return {
                        ...styles,
                        margin: '0px',
                        padding: '0px 8px',
                    }
                }
            }}
            components={{ 
                Option: SelectorOptionWrapper, 
                SingleValue: SelectorValue,
            }}
            options={options}
            value={value}
            onChange={handleChange}
        />
    </>
}