import { DataWhitelist, getPipelineNodeData, PipelineNode, PipelineNodeCalculation, PipelineNodeDimension, PipelineNodeField, PipelineNodeJoinPath, PipelineNodeMeasure, PipelineNodeMeasureJoinTree, usePipelineNodeData, useReportingPaths } from "@models/pipelineNode";
import { Pane, PaneContent, PaneFooter } from "@pages/PageStructure.component";
import { Badge, Button, ButtonGroup, Form, Modal, Dropdown as BSDropdown, Offcanvas, DropdownButton } from "react-bootstrap";
import { DraftFunction, Updater, useImmer } from "use-immer";
import PipelineNodeSelector from "./PipelineNodeSelector.component";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Dropdown, { Option } from "@components/form/Dropdown.component";
import { invalidatePipelineNode, invalidatePipelineNodes, queryClient, savePipelineNode, usePipelineNodes, useReportingTree } from "@stores/data.store";
import { useDebounce } from "use-debounce";
import dagre, { graphlib } from 'dagre';
import { FieldTitle } from "./mapping/shared";
import { requireConfirmation } from "@services/alert/alert.service";
import PipelineNodeJoinPathLabel from "./mapping/PipelineNodeJoinPathLabel.component";
import { ColumnDistro, ColumnRecordInfo } from "./PipelineNodeColumnDrawer.component";
import SectionHeader from "@components/card/SectionHeader.component";
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { humanReadableList } from "@pages/Onboarding/OnboardingWizard.page";
import ApiService, { JobEnqueueResponse } from "@services/api/api.service";
import BackgroundService from "@services/bg.service";
import AsyncButton from "@components/button/AsyncButton.component";
import BuildOrchestrationORM, { BuildOrchestration } from "@models/buildOrchestration";
import toast from "@services/toast.service";
import { getErrorMessage } from "@services/errors.service";
import moment from "moment";
import { formatValue } from '@services/formatting.service';
import PliableLoader from "@components/loaders/PliableLoader.component";
import PipelineNodeName from "./PipelineNodeName.component";
import SuccessAlert from "@components/statusIndicators/SuccessAlert.component";
import Danger from "@components/statusIndicators/Danger.component";
import InfoAlert from "@components/statusIndicators/InfoAlert.component";
import DataWhitelistForm from "./PipelineNodeDataWhitelist.component";
import produce from "immer";
import Warning from "@components/statusIndicators/Warning.component";
import { join } from "path";
import FormatForm from "@pages/SourceRecordType/FormatForm.component";
import PipelineNodeJoinTreeLabel from "./mapping/PipelineNodeJoinTreeLabel.component";
import { ReportBuilderDimension, ReportBuilderDimensionORM, ReportBuilderMeasure, ReportBuilderMeasureORM, useDimensions, useMeasures } from "@models/reportBuilder";
import { DraftOnly } from "@components/project/DraftModeRequired.component";


const PLB_UUID = {
    id: '_PLB_UUID',
    type: 'ID',
    name: 'Record ID',
    label: 'Record ID',
    description: '',
    part_of_composite_key: false,
    taxonomic_id: '',
    map_options: [],
    cell_actions: [],
};
const EditorStyles = styled.div`
position: relative;
.buttons {
    position: absolute;
    top: -3px;
    right: -7px;
    font-size: 18px;
    padding: 0px;
}
`

interface CalculationEditorProps {
    calc: PipelineNodeCalculation;
    onChange: (calc: PipelineNodeCalculation) => any;
    onDelete: () => any;
    allOtherFields: string[];
}

const CalculationEditor = (props: CalculationEditorProps) => {
    const [showConfig, setShowConfig] = useState(false);
    const changeName = useCallback((newName: string) => {
        props.onChange({
            ...props.calc,
            name: newName,
        })
    }, [props.calc, props.onChange]);

    const changeFormula = useCallback((newFormula: string) => {
        props.onChange({
            ...props.calc,
            formula: newFormula,
        })
    }, [props.calc, props.onChange]);

    const changeFormat = useCallback((newFmt: string) => {
        props.onChange({
            ...props.calc,
            formatter: newFmt,
        })
    }, [props.calc, props.onChange]);

    const formatOptions = useMemo(() => {
        return [{
            value: 'FINANCIAL',
            label: 'Financial',
        }, {
            value: 'NUMERIC',
            label: 'Number',
        }, {
            value: 'PERCENT',
            label: 'Percent',
        }, {
            value: 'DATE',
            label: 'Date',
        }, {
            value: 'LONG_DATE',
            label: 'Long Date',  
        }];
    }, []);

    return <EditorStyles>
        <Offcanvas show={showConfig} placement="end" onHide={() => {
                setShowConfig(false);
            }}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        Configure Calculation
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Pane>
                        <PaneContent>
                            <div className="p-3">
                                <Form.Group className="mb-3">
                                    <Form.Label>Format</Form.Label>
                                    <FormatForm
                                        onChange={changeFormat}
                                        selectedFormat={props.calc.formatter || ''}
                                        placeholder="No Format"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <Form.Label>Formula</Form.Label>
                                    <Form.Control as="textarea" className="input-code" value={props.calc.formula} onChange={(e) => {
                                        changeFormula(e.target.value)
                                    }}/>
                                </Form.Group>
                                <p>
                                    To use another column in your calculation, just wrap the column name in double quotes, e.g. <code>"MY COLUMN" / "MY OTHER COLUMN"</code>
                                </p>
                                <h5>Available Columns</h5>
                                <ul>
                                    {props.allOtherFields.map(f => <li>{f}</li>)}
                                </ul>
                                
                            </div>
                            <div className="p-3">
                                <button className="btn btn-success" onClick={() => {
                                    setShowConfig(false);
                                }}>Done</button>
                            </div>
                        </PaneContent>
                        
                    </Pane>
                </Offcanvas.Body>
            </Offcanvas>
        <Form.Group>
            <Form.Label className="small">
                Column Name
            </Form.Label>
            <Form.Control type="text" onChange={(e) => {
            changeName(e.target.value);
        }} value={props.calc.name} className="w-100"/>
        </Form.Group>
        <div className="font-13 fw-normal">
            <code>{props.calc.formula}</code>
        </div>
        <div className="buttons">
            <button onClick={() => {
                setShowConfig(true);
            }} className="icon-button edit-button" title="Configure Measure">
                <i className="mdi mdi-cog"></i>
            </button>
            <button onClick={props.onDelete} className="icon-button close-button">
                <i className="mdi mdi-close-thick"></i>
            </button>
        </div>
            
    </EditorStyles>
}

interface DimensionEditorProps {
    dimension: PipelineNodeDimension;
    dimensionIdx: number;
    onChange: (dimension: PipelineNodeDimension) => any;
    onDelete: () => any;
}

const DimensionEditor = (props: DimensionEditorProps) => {
    const nodes = usePipelineNodes();

    const theNode = useMemo(() => {
        if (!nodes.data) {
            return undefined;
        }
        return nodes.data.find(n => n.id === props.dimension.pipeline_node_id);
    }, [nodes.dataUpdatedAt, props.dimension.pipeline_node_id]);

    const theField = useMemo(() => {
        if (!theNode) {
            return undefined;
        }

        if (props.dimension.field_id == '_PLB_UUID') {
            return PLB_UUID;
        }

        return theNode.fields.find(f => f.id === props.dimension.field_id);
    }, [theNode, props.dimension.field_id]);

    const changeName = useCallback((newName: string) => {
        props.onChange({
            ...props.dimension,
            name: newName,
        })
    }, [props.dimension, props.onChange]);

    const changeFormat = useCallback((newFmt: string) => {
        props.onChange({
            ...props.dimension,
            formatter: newFmt,
        })
    }, [props.dimension, props.onChange]);

    const toggleIncludeAll = useCallback(() => {
        props.onChange({
            ...props.dimension,
            include_all: !props.dimension.include_all,
        })
    }, [props.dimension, props.onChange]);

    const [showConfig, setShowConfig] = useState(false);

    const formatOptions = useMemo(() => {
        if (!theNode || !theField) {
            return [];
        }

        const options: Option[] = [];

        const today = moment();

        let outputType = theField.type;
        switch (outputType) {
            case 'DATE':
            case 'DATETIME':
            case 'DATETIME_TZ':
            case 'STRING':
                options.push({
                    value: 'SHORT_DATE',
                    label: `Short Date (${today.format('MM/DD/YYYY')})`
                });
                options.push({
                    value: 'LONG_DATE',
                    label: `Long Date (${today.format('MMMM Do, YYYY')})`
                });
                break;
            case 'INT':
            case 'DECIMAL':
                options.push({
                    value: 'FINANCIAL',
                    label: 'Financial',
                });
                options.push({
                    value: 'NUMERIC',
                    label: 'Number',
                });
                break;
        }

        return options;
    }, [theNode, theField]);

    if (!theField || !theNode) {
        return <>
            <i className="mdi mdi-loading mdi-spin"></i>
        </>
    }
    return <EditorStyles>
        <Offcanvas show={showConfig} placement="end" onHide={() => {
                setShowConfig(false);
            }}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        Configure Dimension
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Pane>
                        <PaneContent>
                            <div className="p-3">
                                
                                <Form.Group className="mb-2">
                                    <Form.Label>Format</Form.Label>
                                    <FormatForm
                                        onChange={changeFormat}
                                        selectedFormat={props.dimension.formatter || ''}
                                        placeholder="No Format"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <Form.Check
                                        disabled={props.dimensionIdx == 0}
                                        checked={props.dimensionIdx == 0 ? true : !!props.dimension.include_all}
                                        onChange={toggleIncludeAll}
                                        label="Include All"
                                    />
                                    <Form.Text>Check this box to include all values in this dimension, even if there is no data. Note that all data is included from your first dimension automatically.</Form.Text>
                                </Form.Group>
                                
                            </div>
                            <div className="p-3">
                                <button className="btn btn-success" onClick={() => {
                                    setShowConfig(false);
                                }}>Done</button>
                            </div>
                            
                            
                        </PaneContent>
                        
                    </Pane>
                </Offcanvas.Body>
            </Offcanvas>
        <Form.Group>
            <Form.Label className="small">
                Column Name
            </Form.Label>
            <Form.Control type="text" onChange={(e) => {
            changeName(e.target.value);
        }} value={props.dimension.name} className="w-100"/>
        </Form.Group>
       
        <div className="font-13 fw-normal">
            {theNode.name} &rarr; {theField.label}
        </div>
        <div className="buttons">
            <button onClick={() => {
                setShowConfig(true);
            }} className="icon-button edit-button" title="Configure Measure">
                <i className="mdi mdi-cog"></i>
            </button>
            <button onClick={props.onDelete} className="icon-button close-button">
                <i className="mdi mdi-close-thick"></i>
            </button>
        </div>
        
        
            {/* <div>
                <button className="icon-button" onClick={props.onDelete}>
                    <i className="mdi mdi-close-thick"></i>
                </button>
            </div> */}
            
    </EditorStyles>
}

const StickyTable = styled.table`
thead tr th{
    position: sticky;
}

tbody tr th {
    position: sticky;
}


`

const LoaderStyles = styled.div`
width: 100%;
height: 100%;
position: absolute;
top: 0px;
left: 0px;
z-index: 1000;
background: rgba(0, 0, 0, 0.5);

`

function joinTreeId(path: PipelineNodeMeasureJoinTree): string {
    const components: string[] = [path.node_id + ':' + (path.relationship_id || 'NONE')];
    path.downstream.forEach(ds => {
        components.push(joinTreeId(ds));
    })
    return components.join('->');
}

function nodeIdsInJoinTree(tree: PipelineNodeMeasureJoinTree): string[] {
    const ids: string[] = [tree.node_id];
    tree.downstream.forEach(ds => {
        ids.push(...nodeIdsInJoinTree(ds));
    })
    return ids;
}

interface MeasureEditorProps {
    measure: PipelineNodeMeasure;
    onChange: (measure: PipelineNodeMeasure) => any;
    onDelete: () => any;
    dimensions: PipelineNodeDimension[];
    compact?: boolean;
    chosenJoinPaths?: string[];
}

const MeasureEditor = (props: MeasureEditorProps) => {
    const nodes = usePipelineNodes();


    const theNode = useMemo(() => {
        if (!nodes.data) {
            return undefined;
        }
        return nodes.data.find(n => n.id === props.measure.pipeline_node_id);
    }, [nodes.dataUpdatedAt, props.measure.pipeline_node_id]);

    
    const rootNodeId = useMemo(() => {
        let rootNodeId: string = '';
        if (props.dimensions.length > 0) {
            rootNodeId = props.dimensions[0].pipeline_node_id;
        }
        return rootNodeId;
    }, [props.dimensions]);
    

    const includeNodeIds = useMemo(() => {
        let includeNodeIds: string[] = [];

        if (props.measure.pipeline_node_id) {
            includeNodeIds.push(props.measure.pipeline_node_id);
        }
        includeNodeIds = includeNodeIds.concat(props.dimensions.slice(1, props.dimensions.length).map(d => d.pipeline_node_id));
        return includeNodeIds;
    }, [props.measure.pipeline_node_id, props.dimensions]);

    
    const reportingPaths = useReportingPaths(rootNodeId, includeNodeIds);

    const theField = useMemo(() => {
        if (!theNode) {
            return undefined;
        }

        if (props.measure.field_id == '_PLB_UUID') {
            return PLB_UUID;
        }

        return theNode.fields.find(f => f.id === props.measure.field_id);
    }, [theNode, props.measure.field_id]);

    useEffect(() => {
        if (!props.measure.join_tree && reportingPaths.data && reportingPaths.data.length === 1) {
            props.onChange({
                ...props.measure,
                join_tree: reportingPaths.data[0],
            });
        }

        else if (reportingPaths.data && (!props.measure.join_tree) && props.chosenJoinPaths && props.chosenJoinPaths.length > 0) {
            const availablePaths = reportingPaths.data.map(joinTreeId);
            const match = props.chosenJoinPaths.findIndex(jp => availablePaths.includes(jp));
            if (match >= 0) {
                props.onChange({
                    ...props.measure,
                    join_tree: reportingPaths.data[match],
                });
            }
        }

        // Otherwise, make sure the currently selected join path is allowed to be selected
        else if (!!props.measure.join_tree  && reportingPaths.data) {
            const check = joinTreeId(props.measure.join_tree);
            const availablePaths = reportingPaths.data.map(joinTreeId);
            if (!availablePaths.includes(check)) {
                props.onChange({
                    ...props.measure,
                    join_tree: null,
                });
            }
        }
    }, [reportingPaths.dataUpdatedAt, props.onChange, props.measure, props.chosenJoinPaths]);


    const measurementOptions = useMemo(() => {
        if (!theNode || !theField) {
            return [];
        }

        const options: Option[] = [{
            value: 'COUNT_DISTINCT',
            label: 'Count Distinct',
        }, {
            value: 'PICK_ONE',
            label: 'Random',
        }]

        switch (theField.type) {
            case 'DATE':
            case 'DATETIME':
            case 'DATETIME_TZ':
                options.push({
                    value: `MIN`,
                    label: 'Min'
                });
                options.push({
                    value: `MAX`,
                    label: 'Max',
                });
                break;
            case 'INT':
            case 'DECIMAL':
                options.push({
                    value: `MIN`,
                    label: 'Min',
                });
                options.push({
                    value: `MAX`,
                    label: 'Max',
                });
                options.push({
                    value: `AVG`,
                    label: 'Avg'
                });
                options.push({
                    value: `SUM`,
                    label: 'Sum'
                });
                break;
            case 'STRING':
                options.push({
                    value: 'CONCAT',
                    label: 'Concatenate',
                });
                break;
        }
        return options;
    }, [theNode, theField]);

    const changeName = useCallback((newName: string) => {
        props.onChange({
            ...props.measure,
            name: newName,
        })
    }, [props.measure, props.onChange]);

    const changeAggregation = useCallback((newAgg: string) => {
        props.onChange({
            ...props.measure,
            aggregator: newAgg,
        });
    }, [props.onChange, props.measure]);

    const changeFormat = useCallback((newFmt: string) => {
        props.onChange({
            ...props.measure,
            formatter: newFmt,
        });
    }, [props.onChange, props.measure]);

    const [showReportingPaths, setShowReportingPaths] = useState(false);

    const thisJoinPathId = useMemo(() => {
        if (props.measure.join_tree) {
            return joinTreeId(props.measure.join_tree);
        }
        return '';
    }, [props.measure.join_tree]);

    const changeJoinTree = useCallback((newJp: PipelineNodeMeasureJoinTree) => {
        props.onChange({
            ...props.measure,
            join_tree: newJp,
        })
    }, [props.measure, props.onChange]);

    // This needs to be an immer updater
    const changeWhitelist = useCallback((cb: DataWhitelist|DraftFunction<DataWhitelist>) => {
        const newWhitelist = typeof cb == 'function' ? produce(props.measure.data_whitelist || {entries: [], logic_gate: 'AND'}, cb) : cb;
        props.onChange({
            ...props.measure,
            data_whitelist: newWhitelist,
        });
    }, [props.onChange, props.measure]);

    const [demystifying, setDemystifying] = useState(false);

    const [demystifiedValues, setDemystifiedValues] = useState<{
        response: string
    }[]>([]);

    const demystifyPaths = useCallback(async () => {
        if (!theNode || !theField) {
            return;
        }
        setDemystifying(true);

        let humanReadableAggregator: string;
        switch (props.measure.aggregator) {
            case 'SUM':
                humanReadableAggregator = 'total';
                break;
            case 'COUNT_DISTINCT':
                humanReadableAggregator = 'total distinct values'
                break;
            case 'MIN':
                humanReadableAggregator = 'minimum value';
                break;
            case 'MAX':
                humanReadableAggregator = 'maximum value';
                break;
            case 'AVG':
                humanReadableAggregator = 'average';
                break;
            default:
                humanReadableAggregator = 'value';
                
        }
        const result = await ApiService.getInstance().request('POST', '/pipelinenodes/translate-join-path', {
            paths: reportingPaths.data,
            source_column_name: theField.label,
            aggregator: humanReadableAggregator,
            dimensions: props.dimensions.map(d => d.name),
        }) as JobEnqueueResponse;
        const translated = await BackgroundService.getInstance().waitForJob(result.job_id);
        setDemystifying(false);
        setDemystifiedValues(translated);
    }, [reportingPaths.data, theNode, theField, props.measure.aggregator, props.dimensions]);

    const [showConfig, setShowConfig] = useState(false);

    const nodeIdsInJoinPath = useMemo(() => {
        if (props.measure.join_tree) {
            return nodeIdsInJoinTree(props.measure.join_tree);
        }
        return [];
    }, [props.measure.join_tree]);



    if (!theNode || !theField) {
        return <>
            <i className="mdi mdi-loading mdi-spin"></i>
        </>
    }

    if (props.compact) {
        return <>
            <Offcanvas placement="end" show={showConfig} onHide={() => {
                setShowConfig(false);
            }}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        Configure Measure
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Pane>
                        <PaneContent>
                            <div className="p-3">
                                <Form.Group className="mb-3">
                                    <Form.Label>Rollup</Form.Label>
                                    <Dropdown 
                                        options={measurementOptions}
                                        selected={props.measure.aggregator}
                                        onChange={changeAggregation}
                                        btnTitleIcon="mdi mdi-vector-combine"
                                        placeholder="Measure"
                                        btnVariant={props.measure.aggregator ? 'secondary' : 'danger'}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Format</Form.Label>
                                    <FormatForm
                                        onChange={changeFormat}
                                        selectedFormat={props.measure.formatter || ''}
                                        placeholder="No Format"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        {!props.measure.join_tree && <>
                                            <i className="mdi mdi-alert text-danger"></i>&nbsp;
                                        </>}
                                        Join Path
                                    </Form.Label>
                                    {reportingPaths.data && reportingPaths.data.length > 1 && <>
                                        <Warning>Pliable found multiple ways to connect this measure to your dimensions.</Warning>
                                    </>}
                                    <ul className="list-group">
                                        {reportingPaths.data?.map((rp, rpIdx) => {
                                            const thatJoinPathId = joinTreeId(rp);
                                            const active = thisJoinPathId == thatJoinPathId;
                                            return <li className="list-group-item" key={rpIdx}>
                                                <div className="d-flex center-vertically">
                                                    <div className="flex-1">
                                                        {demystifiedValues[rpIdx] ? <span>{demystifiedValues[rpIdx].response}</span> : <PipelineNodeJoinTreeLabel joinTree={rp}/>}
                                                        
                                                    </div>
                                                    <div>
                                                        <button className={`icon-button font-18 ${active ? 'text-success' : ''}`} onClick={() => {
                                                            changeJoinTree(rp)
                                                        }}>
                                                            <i className="mdi mdi-check-circle"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </li>;
                                        })}
                                    </ul>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Limit Data</Form.Label>
                                    <DataWhitelistForm
                                        nodeIds={nodeIdsInJoinPath}
                                        config={props.measure.data_whitelist || {
                                            entries: [],
                                            logic_gate: 'AND',
                                        }}
                                        onChange={changeWhitelist}
                                    />
                                </Form.Group>
                                
                            </div>
                            <div className="p-3">
                                <button className="btn btn-success" onClick={() => {
                                    setShowConfig(false);
                                }}>Done</button>
                            </div>
                            
                        </PaneContent>
                        
                    </Pane>
                </Offcanvas.Body>
            </Offcanvas>
            <EditorStyles>
                <Form.Group>
                    <Form.Label className="small">
                        Column Name
                    </Form.Label>
                    <Form.Control type="text" onChange={(e) => {
                    changeName(e.target.value);
                }} value={props.measure.name} className="w-100"/>
                </Form.Group>
                
                <div className="font-13 fw-normal">
                    {theNode.name} &rarr; {theField.label}
                </div>
                <div className="buttons">
                    {!props.measure.join_tree && <>
                        <button onClick={() => {
                            setShowConfig(true);
                        }} className="icon-button" title="Error">
                            <i className="mdi mdi-alert text-danger"></i>
                        </button>
                    </>}
                    <button onClick={() => {
                        setShowConfig(true);
                    }} className="icon-button edit-button" title="Configure Measure">
                        <i className="mdi mdi-cog"></i>
                    </button>
                    <button onClick={props.onDelete} className="icon-button close-button" title="Delete Measure">
                        <i className="mdi mdi-close-thick"></i>
                    </button>
                </div>
                
            </EditorStyles>
        </>
    }


    return <>
        <Modal show={showReportingPaths} size="lg" onHide={() => {
            setShowReportingPaths(false);
        }}>
            <Modal.Header closeButton >
                <Modal.Title>Select Path</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Pliable found multiple ways to get to your selected measure. Choose your preference below.
                </p>
                <AsyncButton loading={demystifying} onClick={demystifyPaths} text="I am Kait"/>
                <ul className="list-group">
                    {reportingPaths.data?.map((rp, rpIdx) => {
                        const thatJoinPathId = joinTreeId(rp);
                        const active = thisJoinPathId == thatJoinPathId;
                        return <li className="list-group-item" key={rpIdx}>
                            <div className="d-flex center-vertically">
                                <div className="flex-1">
                                    {demystifiedValues[rpIdx] ? <span>{demystifiedValues[rpIdx].response}</span> : <PipelineNodeJoinTreeLabel joinTree={rp}/>}
                                    
                                </div>
                                <div>
                                    <button className={`icon-button font-18 ${active ? 'text-success' : ''}`} onClick={() => {
                                        changeJoinTree(rp)
                                    }}>
                                        <i className="mdi mdi-check-circle"></i>
                                    </button>
                                </div>
                            </div>
                        </li>;
                    })}
                </ul>
            </Modal.Body>
        </Modal>
        <EditorStyles>
                <Form.Group>
                    <Form.Label className="small">
                        Column Name
                    </Form.Label>
                    <Form.Control type="text" onChange={(e) => {
                    changeName(e.target.value);
                }} value={props.measure.name} className="w-100"/>
                </Form.Group>
                
                <div className="font-13 mb-2">
                    Source: {theNode.name} &rarr; {theField.label}
                </div>
                <div className="d-flex">
                    
                    <Dropdown 
                        variant="button"
                        options={measurementOptions}
                        selected={props.measure.aggregator}
                        onChange={changeAggregation}
                        btnTitleIcon="mdi mdi-vector-combine"
                        placeholder="Measure"
                        btnVariant={props.measure.aggregator ? 'secondary' : 'danger'}
                    />
                        
                        
                    {reportingPaths.data && reportingPaths.data.length > 1 && <div className="ms-2">
                        {(!props.measure.join_tree) && <button className="btn btn-outline-danger btn-sm" onClick={() => {
                                setShowReportingPaths(true);
                            }}> <i className="mdi mdi-alert"></i> Set relationship path.
                        </button>}
                        {props.measure.join_tree && <div>
                            <a role="button" onClick={() => {
                                setShowReportingPaths(true);
                            }}>Edit relationship path.</a>
                        </div>}
                        
                    </div>}
                </div>
                
                <div className="buttons">
                    <button onClick={props.onDelete} className="icon-button close-button">
                        <i className="mdi mdi-close-thick"></i>
                    </button>
                </div>
               
        </EditorStyles>
    </>
}


interface NodeConfigProps {
    node: PipelineNode;
    onChange: Updater<PipelineNode>;
}

interface AvailableColumn {
    node: PipelineNode;
    field: PipelineNodeField;
    columnLabel?: string;
    aggregator?: string;
    hardCodedOptionLabel?: string;
}

interface AvailableColumnLabelProps {
    col: AvailableColumn;
    dimension: boolean;
}

const AvailableColumnLabel = (props: AvailableColumnLabelProps) => {
    if (props.col.hardCodedOptionLabel) {
        return <>{props.col.hardCodedOptionLabel}</>
    }
    if (props.col.field.id == '_PLB_UUID') {
        if (props.dimension) {
            return <>
                Break down by <strong><Link to={`/node/${props.col.node.id as string}`}>{props.col.node.singular}</Link></strong>
            </>
        }
        return <>
            # of <strong><Link to={`/node/${props.col.node.id as string}`}>{props.col.node.plural}</Link></strong>
        </>
    } else if (props.col.aggregator == 'PICK_ONE') {
        return <>
            Any value from <strong>{props.col.field.label}</strong>
        </>
    }

    switch (props.col.field.type) {
        case 'STRING':
            if (props.col.aggregator == 'COUNT_DISTINCT') {
                return <>
                    Count of distinct <strong>{props.col.field.label}</strong>
                </>
            } else if (props.col.aggregator == 'CONCAT') {
                return <>
                    Comma-separated list of distinct <strong>{props.col.field.label}</strong>
                </>
            } else if (props.col.aggregator == 'ANY_VALUE') {
                return <>
                    Any value from <strong>{props.col.field.label}</strong>
                </>
            }
            break;
        case 'INT':
        case 'DECIMAL':
            if (props.col.aggregator == 'SUM') {
                return <>
                    Total <strong>{props.col.field.label}</strong>
                </>
            } else if (props.col.aggregator == 'AVG') {
                return <>
                    Average <strong>{props.col.field.label}</strong>
                </>
            } else if (props.col.aggregator == 'MIN') {
                return <>
                    Minimum (lowest) <strong>{props.col.field.label}</strong>
                </>;
            }  else if (props.col.aggregator == 'MAX') {
                return <>
                    Maximum (highest) <strong>{props.col.field.label}</strong>
                </>;
            }
            break;
        case 'DATE':
        case 'DATETIME':
        case 'DATETIME_TZ':
            if (props.col.aggregator == 'MIN') {
                return <>
                    Minimum (earliest) <strong>{props.col.field.label}</strong>
                </>
            } else if (props.col.aggregator == 'MAX') {
                return <>
                    Maximum (latest) <strong>{props.col.field.label}</strong>
                </>
            }
            break;
    }
    if (props.dimension) {
        return <>
            <div>Break down by {props.col.node.singular} - {props.col.field.label}</div>
        </>
    }
    return <>
        <div>{props.col.node.name} - <strong>{props.col.field.label}</strong></div>
        <div className="text-muted font-13">{props.col.field.description}</div>
    </>

    
}

const getDefaultAggForType = (t: string): string => {
    switch(t) {
        case 'ID':
        case 'STRING':
            return 'COUNT_DISTINCT';
        case 'DATE':
        case 'DATETIME':
        case 'DATETIME_TZ':
            return 'MAX'
        case 'INT':
        case 'DECIMAL':
            return 'SUM'
        default:
            return 'PICK_ONE';
    }
}

const getAllSuccessors = (graph: graphlib.Graph, nodeId: string): string[] => {
    // @ts-ignore
    const successors = graph.successors(nodeId) as string[];
    if (!successors) {
        return [];
    }
    const downstream = successors?.map(s => getAllSuccessors(graph, s));
    return successors.concat(...downstream);
}

const getAllPredecessors = (graph: graphlib.Graph, nodeId: string): string[] => {
    // @ts-ignore
    const predecessors = graph.predecessors(nodeId) as string[];
    if (!predecessors) {
        return [];
    }
    const upstream = predecessors?.map(s => getAllPredecessors(graph, s));
    return predecessors.concat(...upstream);
}

const getAllConnections = (graph: graphlib.Graph, nodeId: string, currentList: string[]): string[] => {
    // @ts-ignore
    const neighbors = graph.neighbors(nodeId) as string[];
    if (!neighbors) {
        return currentList;
    }
    const filteredNeighbors = neighbors.filter(g => !currentList.includes(g));

    const newCurrentList = [...currentList].concat(filteredNeighbors);

    const expanded = filteredNeighbors?.map(s => getAllConnections(graph, s, newCurrentList));
    return filteredNeighbors.concat(...expanded);
}

interface TableCellProps {
    value: string;
    format?: string;
}

const FormattedCell = (props: TableCellProps) => {
    return <div>{formatValue(props)}</div>;
}

interface ConversionChoices {
    [key: string]: {
        action: 'EXISTING' | 'NEW' | 'IGNORE';
        existingObjectId?: string;
    }
}

const PipelineNodeReportEditor = (props: NodeConfigProps) => {
    const [reportType, setReportType] = useState('SUMMARY');

    const [changeSinceLastLoad, setChangeSinceLastLoad] = useState(false);


    const tableContainer = useRef<HTMLTableRowElement>(null);

    const [didInitialSetup, setDidInitialSetup] = useState(false);

    const nodes = usePipelineNodes();
    const tree = useReportingTree();

    const [columnFilter, setColumnFilter] = useState('');
    const [debouncedColumnFilter] = useDebounce(columnFilter, 250);

    const deleteDimension = useCallback(async (idx: number) => {
        const confirmed = await requireConfirmation('Are you sure you want to delete this dimension?', 'Delete Dimension', 'Yes', 'Cancel');
        if (confirmed) {
            const theDim = props.node.dimensions![idx];
            props.onChange(draft => {
                draft.dimensions!.splice(idx, 1);
                // draft.measures?.forEach(m => {
                //     m.join_path = [];
                // });
            });
            setChangeSinceLastLoad(true);

        }
    }, [props.node.dimensions])

    const deleteMeasure = useCallback(async (idx: number) => {
        const confirmed = await requireConfirmation('Are you sure you want to delete this measure?', 'Delete Measure', 'Yes', 'Cancel');
        if (confirmed) {
            const theMeasure = props.node.measures![idx];

            props.onChange(draft => {
                draft.measures!.splice(idx, 1);
            });
            setChangeSinceLastLoad(true);
          
        }
    }, [props.node.measures]);

    const deleteCalculation = useCallback(async (idx: number) => {
        const confirmed = await requireConfirmation('Are you sure you want to delete this calculation?', 'Delete Calculation', 'Yes', 'Cancel');
        if (confirmed) {
            const theCalc = props.node.calculations![idx];

            props.onChange(draft => {
                draft.calculations!.splice(idx, 1);
            });
            setChangeSinceLastLoad(true);

        }
    }, [props.node.calculations]);

    const graph = useMemo(() => {
        const g = new graphlib.Graph({
            directed: true,
            multigraph: true,
        });

        if (!tree.data) {
            return g;
        }

        tree.data.nodes.forEach(n => {
            g.setNode(n.id, n.title);
        });

        tree.data.edges.forEach(e => {
            g.setEdge(e.from_id, e.to_id, e.relationship_id);
        });

        return g
    }, [tree.dataUpdatedAt]);

    const availableDimensionNodeIds = useMemo(() => {
        /**
         * 
         * A dimension can be selected IF:
         * 
         * It is UPSTREAM of all currently selected measures, meaning:
         *  * it is an ancestor of the measure node
         *  * it is a less-granular column on that measure node (we can get away with just saying it's a column on the node itself)
         * 
         * AND, it is RELATED to all currently selected dimensions
         */
        
        // Step 1: get upstream nodes for all measures
        let upstreamMeasures: string[] = []
        let relatedDimensions: string[] = [];

        if (!nodes.data) {
            return [];
        }

        const dateDimNodes = nodes.data.filter(n => n.node_type == 'DATE_DIMENSION').map(n => `PipelineNode:${n.id}`);


        (props.node.measures || []).forEach(m => {
            // @ts-ignore
            const predecessors = getAllPredecessors(graph, 'PipelineNode:' + m.pipeline_node_id, ['PipelineNode:' + m.pipeline_node_id]) as string[];
            if (!predecessors) {
                return;
            }
            
            const allPredecessors = [m.pipeline_node_id].concat(predecessors.map(p => p.split(':')[1]));
            if (upstreamMeasures.length == 0) {
                upstreamMeasures = allPredecessors;
                
            } else {
                // Progressively remove items from the set
                upstreamMeasures = upstreamMeasures.filter(u => allPredecessors.includes(u));
            }
        });

        (props.node.dimensions || []).forEach(d => {
            // Skip date dimensions here because this only works if we have at least one dimension

            // @ts-ignore
            const neighbors = getAllConnections(graph, 'PipelineNode:' + d.pipeline_node_id, dateDimNodes.concat(['PipelineNode: ' + d.pipeline_node_id]));
            
            if (!neighbors) {
                return;
            }

            const allNeighbors = [d.pipeline_node_id].concat(neighbors.map(p => p.split(':')[1]));

            if (relatedDimensions.length == 0) {
                relatedDimensions = allNeighbors
                
            } else {
                // Progressively remove items from the set
                relatedDimensions = relatedDimensions.filter(u => allNeighbors.includes(u));
            }
        });

        if (upstreamMeasures.length) {
            const rv = upstreamMeasures.filter(u => relatedDimensions.includes(u) || relatedDimensions.length == 0); 
            return rv; 
        }

        if (relatedDimensions.length) {
            return relatedDimensions;
        }

        if ((!props.node.measures || props.node.measures.length == 0) && !props.node.dimensions?.length) {
            return graph.nodes().map(n => n.split(':')[1]);
        }
        
        return [];

    }, [props.node.measures, props.node.dimensions, graph, nodes.dataUpdatedAt, nodes.data]);

    const availableMeasureNodeIds = useMemo(() => {
        /**
         * A measure can be selected IF it is related to all dimensions.
         * 
         * WE can get more efficient here because we already know all dimensions are related to each other, so we just need to check the first dim
         * 
         */
        if (!nodes.data) {
            return [];
        }

        // Do not go through date dimensions to figure out if measures are related (same way the get_possible_paths works in the back-end tree)
        // If the first dimension is a date dim it's fine.
        const dateDimNodes = nodes.data.filter(n => n.node_type == 'DATE_DIMENSION').map(n => `PipelineNode:${n.id}`);
        if (props.node.dimensions && props.node.dimensions.length > 0) {
            const allConnections = getAllConnections(graph, 'PipelineNode:' + props.node.dimensions[0].pipeline_node_id, dateDimNodes.concat(['PipelineNode:' + props.node.dimensions[0].pipeline_node_id]));
            return [props.node.dimensions[0].pipeline_node_id].concat(allConnections.map(a => a.split(':')[1]));
        }
        return graph.nodes().map(n => n.split(':')[1]);  
        
    }, [props.node.dimensions, graph, nodes.dataUpdatedAt]);

    const availableColumns = useMemo(() => {
        if (!nodes.data) {
            return [];
        }

        const columns: AvailableColumn[] = [];

        nodes.data.filter(n => ['FACT', 'DIMENSION'].includes(n.node_type)).forEach(n => {

            if (!availableMeasureNodeIds.includes(n.id as string) && !availableDimensionNodeIds.includes(n.id as string)) {
                // Not able to use this node at all, given current selections.
                return;
            }
            const nodeMatches = n.name.toLowerCase().indexOf(debouncedColumnFilter) >= 0;



            if (nodeMatches) {
                if (n.alternate_identifier_field_id) {
                    const alternateField = n.fields.find(f => f.id === n.alternate_identifier_field_id);
                    if (alternateField) {
                        columns.push({
                            node: n,
                            field: alternateField,
                            columnLabel: n.singular,
                            aggregator: 'COUNT_DISTINCT',
                        });
                    } else {
                        columns.push({
                            node: n,
                            field: PLB_UUID,
                            columnLabel: n.singular,
                            aggregator: 'COUNT_DISTINCT',
                        });
                    }
                } else {
                    columns.push({
                        node: n,
                        field: PLB_UUID,
                        columnLabel: n.singular,
                        aggregator: 'COUNT_DISTINCT',
                    });
                }
                
            }
            n.fields.forEach(f => {
                if (f.type == 'FOREIGN_KEY') {
                    return;
                }
                if (nodeMatches || f.label.toLowerCase().indexOf(debouncedColumnFilter) >= 0 || f.description.toLowerCase().indexOf(debouncedColumnFilter) >= 0) {
                    columns.push({
                        node: n,
                        field: f,
                        columnLabel: f.label,
                        aggregator: 'PICK_ONE',
                        
                    });
                    
                    if (['STRING'].includes(f.type)) {
                        columns.push({
                            node: n,
                            field: f,
                            columnLabel: f.label,
                            aggregator: 'CONCAT',
                        });
                    }
                    if (['INTEGER', 'DECIMAL', 'DATETIME', 'DATE', 'DATETIME_TZ'].includes(f.type)) {
                        columns.push({
                            node: n,
                            field: f,
                            columnLabel: f.label,
                            aggregator: 'MIN',  
                        });
                        columns.push({
                            node: n,
                            field: f,
                            columnLabel: f.label,
                            aggregator: 'MAX',  
                        });
                    }
                    if (['INTEGER', 'DECIMAL'].includes(f.type)) {
                        columns.push({
                            node: n,
                            field: f,
                            columnLabel: f.label,
                            aggregator: 'AVG',  
                        });
                        columns.push({
                            node: n,
                            field: f,
                            columnLabel: f.label,
                            aggregator: 'SUM',  
                        });
                    }
                    
                }
            })
        });

        return columns;
    }, [debouncedColumnFilter, nodes.dataUpdatedAt, availableMeasureNodeIds, availableDimensionNodeIds])

    const scrollTableToRight = useCallback(() => {
        if (!tableContainer.current) {
            return;
        }
        const lastItem = tableContainer.current.lastElementChild;
        if (lastItem) {
            lastItem.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }
    }, [tableContainer]);

    const scrollTableToLeft = useCallback(() => {
        if (!tableContainer.current) {
            return;
        }
        const firstItem = tableContainer.current.firstElementChild;
        if (firstItem) {
            firstItem.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }
    }, [tableContainer]);

    const addMeasure = useCallback((pipelineNodeId: string, fieldId: string, name: string, aggregator: string = '') => {
        props.onChange(draft => {
            if (!draft.measures) {
                draft.measures = [];
            }
            draft.measures.push({
                name: name,
                pipeline_node_id: pipelineNodeId,
                field_id: fieldId,
                aggregator: aggregator,
                join_tree: null,
            });

            setTimeout(() => {
                scrollTableToRight();
            })
            setChangeSinceLastLoad(true);
        })
    }, [props.onChange, scrollTableToRight]);

    

    const addCalculation = useCallback((columnLabel: string) => {
        props.onChange(draft => {
            if (!draft.calculations) {
                draft.calculations = [];
            }

            draft.calculations.push({
                name: columnLabel,
                formula: '',
            });

            setTimeout(() => {
                scrollTableToRight();
            })
            setChangeSinceLastLoad(true);
        });
    }, [props.onChange, scrollTableToRight]);

    const addDimension = useCallback((pipelineNodeId: string, fieldId: string, name: string) => {
        props.onChange(draft => {
            if (!draft.dimensions) {
                draft.dimensions = [];
            }
            draft.dimensions.push({
                name: name,
                pipeline_node_id: pipelineNodeId,
                field_id: fieldId,
                
            })

            setTimeout(() => {
                scrollTableToLeft();
            })
            setChangeSinceLastLoad(true);
        })
    }, [props.onChange, scrollTableToLeft]);


    const [dataLibraryType, setDataLibraryType] = useState<'MEASURE'|'DIMENSION'>('MEASURE');

    const [showDataLibrary, setShowDataLibrary] = useState(false);

    const showDataLibraryWithType = useCallback((t: 'MEASURE'|'DIMENSION') => {
        setActiveColumnPipelineNodeId('');
        setActiveColumnFieldId('');
        setShowDataLibrary(true);
        setDataLibraryType(t);
    }, []);

    const getJoinPaths = useCallback((rootNodeId: string, includeNodeIds: string[]) => {
        const node = graph.node('PipelineNode:' + rootNodeId);
        
        const upstream = graph.inEdges('PipelineNode:' + rootNodeId);
        const downstream = graph.outEdges('PipelineNode:' + rootNodeId);

    }, [graph]);

    const [activeColumnPipelineNodeId, setActiveColumnPipelineNodeId] = useState('');
    const [activeColumnFieldId, setActiveColumnFieldId] = useState('');

    const activeColumnNode = useMemo(() => {
        if (!nodes.data) {
            return undefined;
        }

        const theNode = nodes.data.find(n => n.id === activeColumnPipelineNodeId);
        return theNode;
    }, [activeColumnPipelineNodeId, nodes.dataUpdatedAt])
    const activeColumnField = useMemo(() => {
        if (!activeColumnNode) {
            return undefined;
        }

        return activeColumnNode.fields.find(f => f.id === activeColumnFieldId);
        
    }, [activeColumnNode, activeColumnFieldId, ]);

    const viewColumnDetails = useCallback((nodeId: string, fieldId: string) => {
        setActiveColumnPipelineNodeId(nodeId);
        setActiveColumnFieldId(fieldId);
    }, []);

    const hideColumnDetails = useCallback(() => {
        setActiveColumnPipelineNodeId('');
        setActiveColumnFieldId('');
    }, []);

    

    const wizardMeasureOptions = useMemo(() => {
        if (!nodes.data) {
            return [];
        }

        const columns: AvailableColumn[] = [];

        nodes.data.filter(n => ['FACT', 'DIMENSION'].includes(n.node_type) && availableMeasureNodeIds.includes(n.id as string)).forEach(n => {
            columns.push({
                node: n,
                field: PLB_UUID,           
                columnLabel: 'NUMBER OF ' + (n.plural ? n.plural.toUpperCase() : n.name.toUpperCase()),
                aggregator: 'COUNT_DISTINCT',
            });

            n.fields.forEach(f => {
                if (['INT', 'DECIMAL'].includes(f.type)) {
                    columns.push({
                        node: n,
                        field: f,
                        columnLabel: 'TOTAL ' + f.label.toUpperCase(),
                        aggregator: 'SUM',
                    });


                    columns.push({
                        node: n,
                        field: f,
                        columnLabel: 'AVG ' + f.label.toUpperCase(),
                        aggregator: 'AVG',
                    });
                } else if (['DATE', 'DATETIME', 'DATETIME_TZ'].includes(f.type)) {
                    columns.push({
                        node: n,
                        field: f,
                        columnLabel: 'LATEST ' + f.label.toUpperCase(),
                        aggregator: 'MAX',
                    });

                    columns.push({
                        node: n,
                        field: f,
                        columnLabel: 'EARLIEST ' + f.label.toUpperCase(),
                        aggregator: 'MIN',
                    });
                }
            })
        });

        return columns;
    }, [availableMeasureNodeIds, nodes.dataUpdatedAt])


    const wizardDimensionOptions = useMemo(() => {
        if (!nodes.data) {
            return [];
        }

        const columns: AvailableColumn[] = [];

        const existing = (props.node.dimensions || []).map(d => `${d.pipeline_node_id}.${d.field_id}`);

        nodes.data.filter(n => ['DATE_DIMENSION', 'FACT', 'DIMENSION'].includes(n.node_type) && availableDimensionNodeIds.includes(n.id as string)).forEach(n => {
            if (n.node_type === 'DATE_DIMENSION') {
                

                // Find the columns we want, by name
                const columnsByName: {
                    [name: string]: PipelineNodeField
                } = {};

                n.fields.map(f => {
                    columnsByName[f.name] = f;
                })
                
                columns.push({
                    node: n,
                    field: columnsByName['DATE'],
                    columnLabel: 'DATE',
                    hardCodedOptionLabel: 'Day-over-Day',
                });

                columns.push({
                    node: n,
                    field: columnsByName['FIRST_DAY_OF_WEEK'],
                    hardCodedOptionLabel: 'Week-over-Week',
                    columnLabel: 'WEEK_BEGINNING',
                });

                columns.push({
                    node: n,
                    field: columnsByName['DAY_OF_WEEK'],
                    columnLabel: 'DAY_OF_WEEK',
                    hardCodedOptionLabel: 'Day of the Week (Sun, Mon, Tue, etc)',
                });

                columns.push({
                    node: n,
                    field: columnsByName['FIRST_DAY_OF_MONTH'],
                    columnLabel: 'MONTH_BEGINNING',
                    hardCodedOptionLabel: 'Month-over-Month',
                });

                columns.push({
                    node: n,
                    field: columnsByName['QUARTER'],
                    columnLabel: 'QUARTER',
                    hardCodedOptionLabel: 'Quarter-over-Quarter',
                });

                columns.push({
                    node: n,
                    field: columnsByName['YEAR'],
                    hardCodedOptionLabel: 'Year-over-Year',
                    columnLabel: 'YEAR',
                });
            } else if (n.alternate_identifier_field_id) {
                const alternateField = n.fields.find(f => f.id === n.alternate_identifier_field_id);
                if (!alternateField) {
                    columns.push({
                        node: n,
                        field: PLB_UUID,
                        hardCodedOptionLabel: 'Per ' + n.singular || n.name,
                        columnLabel: n.singular,
                    });
                } else {
                    columns.push({
                        node: n,
                        field: alternateField,
                        hardCodedOptionLabel: 'Per ' + n.singular || n.name,
                        columnLabel: n.singular,
                    })
                }
            } else {
                columns.push({
                    node: n,
                    field: PLB_UUID,
                    hardCodedOptionLabel: 'Per ' + n.singular || n.name,
                    columnLabel: n.singular,
                })
            }
            
        });

        return columns.filter(c => !existing.includes(`${c.node.id}.${c.field.id}`));
    }, [nodes.dataUpdatedAt, availableDimensionNodeIds, props.node.dimensions]);

    const [bgSaving, setBgSaving] = useState(false);
    const [activeOrchestration, setActiveOrchestration] = useState<BuildOrchestration|undefined>(undefined);

    const [loadingSampleData, setLoadingSampleData] = useState(false);

    
    const [sampleData, setSampleData] = useState<any[]>([]);
    const loadSampleData = useCallback(async () => {
        setLoadingSampleData(true);
        try {
            const newData = await getPipelineNodeData(props.node.id as string, 10, 1);
            setSampleData(newData);
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setLoadingSampleData(false);
        }
    }, [props.node.id]);

    

    // Just used for the first load
    const firstTimeSampleData = usePipelineNodeData(props.node.id as string, 10, 1);

    useEffect(() => {
        if (firstTimeSampleData.data && !changeSinceLastLoad) {
            setSampleData(firstTimeSampleData.data);
        }
    }, [firstTimeSampleData.dataUpdatedAt, changeSinceLastLoad]);

    const checkActiveOrchestration = useCallback(async () => {
        if (!activeOrchestration) {
            return;
        }

        if (!['ERROR', 'COMPLETE', 'IN_REVIEW'].includes(activeOrchestration.status)) {
            
            setTimeout(async () => {
                // Keep polling, which should then re-run this effect, and so on.
                const updatedOrch = await BuildOrchestrationORM.findById(activeOrchestration.id as string);
                setActiveOrchestration(updatedOrch);
            }, 1000);
        } else if (activeOrchestration.status == 'COMPLETE') {
            setActiveOrchestration(undefined);
            loadSampleData();
            setBgSaving(false);
        } else if (activeOrchestration.status == 'ERROR') {
            toast('danger', 'Error', getErrorMessage(activeOrchestration.error));
            setBgSaving(false);
        }

    }, [activeOrchestration, props.node.id, loadSampleData]);
    useEffect(() => {
        if (activeOrchestration) {
            checkActiveOrchestration();
        }
    }, [activeOrchestration]);

    

    const saveAndLoadSampleData = useCallback(async () => {
        setBgSaving(true);
        setChangeSinceLastLoad(false);
        const result = await savePipelineNode({
            ...props.node,
            done_with_wizard: true,
        });

        try {
            const orchestration = await BuildOrchestrationORM.buildWithSelector(props.node.name, true);
            setActiveOrchestration(orchestration);
            
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        }
    }, [props.node]);

    const measuresWithIssue = useMemo(() => {
        if (!props.node.measures) {
            return 0;
        }

        return props.node.measures.filter(m => !m.join_tree).length ;
    }, [props.node.measures]);  

    const reportInfo = useMemo(() => {
        return <div>
            <strong>{humanReadableList((props.node.measures || []).map(m => m.name))}</strong> broken down by <strong>{humanReadableList((props.node.dimensions || []).map(d => d.name))}</strong>
        </div>
    }, [props.node.dimensions, props.node.measures]);

    const changeMeasure = useCallback((idx: number, measure: PipelineNodeMeasure) => {
        props.onChange(draft => {
            draft.measures![idx] = measure;
        });
        setChangeSinceLastLoad(true);
    }, [props.onChange]);
    const changeDimension = useCallback((idx: number, dim: PipelineNodeDimension) => {
        props.onChange(draft => {
            draft.dimensions![idx] = dim;
        });
        setChangeSinceLastLoad(true);
    }, [props.onChange]);

    const changeCalculation = useCallback((idx: number, calc: PipelineNodeCalculation) => {
        props.onChange(draft => {
            draft.calculations![idx] = calc;
        })
        setChangeSinceLastLoad(true);

    }, [props.onChange]);

    const availableColumnsByNodeId = useMemo(() => {
        const rv: {
            [nodeId: string]: AvailableColumn[];
        } = {};
        availableColumns.forEach(ac => {
            if (!rv.hasOwnProperty(ac.node.id as string)) {
                rv[ac.node.id as string] = [];
            }
            rv[ac.node.id as string].push(ac);
        });
        return rv;
    }, [availableColumns]);

    const chosenJoinPaths = useMemo(() => {
        if (props.node.measures) {
            return props.node.measures.map(m => m.join_tree ? joinTreeId(m.join_tree) : '');
        }
        return [];
    }, [props.node.measures])

    const DataUpdateRequired = useMemo(() => {
        return <><i className="mdi mdi-alert text-warning"></i> Data update required.</>
    }, []);

    const [convertingToNewFormat, setConvertingToNewFormat] = useState(false);

    const dimensions = useDimensions();
    const measures = useMeasures();

    const [conversionChoices, setConversionChoices] = useImmer<ConversionChoices>({});

    const [runningConvert, setRunningConvert] = useState(false);
    const convertFormat = useCallback(async () => {
        setRunningConvert(true);

        // Go through and create any dimensions and measures needed
        const newDimensions: ReportBuilderDimension[] = [];
        const newMeasures: ReportBuilderMeasure[] = [];
        const newDimensionIds: string[] = [];
        const newMeasureIds: string[] = [];

        Object.keys(conversionChoices).forEach(key => {
            const choice = conversionChoices[key];
            const parts = key.split(':');
            if (parts[0] == 'DIM') {
                if (choice.action == 'NEW') {
                    const dim = props.node.dimensions![parseInt(parts[1])];
                    newDimensions.push({
                        id: null,
                        name: dim.name,
                        column_name: dim.name,
                        description: '',
                        pipeline_node_id: dim.pipeline_node_id,
                        field_id: dim.field_id,
                    });
                } else if(choice.action == 'EXISTING') {
                    newDimensionIds.push(choice.existingObjectId as string);
                }
            } else if (parts[0] == 'MEASURE') {
                if (choice.action == 'NEW') {
                    const measure = props.node.measures![parseInt(parts[1])];
                    newMeasures.push({
                        id: null,
                        description: '',
                        name: measure.name,
                        column_name: measure.name,
                        is_calculation: false,
                        pipeline_node_id: measure.pipeline_node_id as string,
                        field_id: measure.field_id,
                        aggregator: measure.aggregator,
                        formatter: measure.formatter, 
                        data_whitelist: measure.data_whitelist,
                    })
                } else if (choice.action == 'EXISTING') {
                    newMeasureIds.push(choice.existingObjectId as string);
                }
            } else if (parts[0] == 'CALC') {
                if (choice.action == 'NEW') {
                    const calc = props.node.calculations![parseInt(parts[1])];
                    newMeasures.push({
                        id: null,
                        description: '',
                        name: calc.name,
                        column_name: calc.name,
                        is_calculation: true,
                        formula: calc.formula,
                    })

                } else if (choice.action == 'EXISTING') {
                    newMeasureIds.push(choice.existingObjectId as string);
                }
            }
        });

        newDimensions.forEach(async d => {
            const result = await ReportBuilderDimensionORM.save(d);
            newDimensionIds.push(result.id as string);
        });

        newMeasures.forEach(async d => {
            const result = await ReportBuilderMeasureORM.save(d);
            newMeasureIds.push(result.id as string);
        });

        const updatedNode = await savePipelineNode({
            ...props.node,
            dimensions: [],
            measures: [],
            calculations: [],
            dimension_ids: newDimensionIds,
            measure_ids: newMeasureIds,
        });

        invalidatePipelineNodes();

    }, [conversionChoices, props.node]);

    
    return <Pane>
        <Modal size="lg" show={convertingToNewFormat} onHide={() => {
            setConvertingToNewFormat(false);
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Convert</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {runningConvert && <div>Converting...</div>}
                {!runningConvert && <>
                    <p>
                    This report is using an older format. Let's convert it to the new format now.
                </p>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>Action</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {(props.node.dimensions || []).map((d, idx) => {
                            const key = `DIM:${idx}`;
                            return <tr>
                                <td>
                                    Dimension: <br />
                                    <strong>{d.name}</strong>
                                </td>
                                <td>
                                    <select className="form-control" value={conversionChoices[key]?.action || 'IGNORE'} onChange={(e) => {
                                        setConversionChoices(draft => {
                                            if (!draft[key]) {
                                                draft[key] = {
                                                    action: 'IGNORE',
                                                }
                                            }
                                            draft[key].action = e.target.value as 'EXISTING'|'NEW'|'IGNORE';
                                        });
                                    }}>
                                        <option value="IGNORE">Ignore</option>
                                        <option value="EXISTING">Use Existing</option>
                                        <option value="NEW">Create New</option>
                                    </select>
                                </td>
                                <td>
                                    {conversionChoices[key]?.action == 'EXISTING' && <>
                                        <select className="form-control" value={conversionChoices[key].existingObjectId || ''} onChange={(e) => {
                                            setConversionChoices(draft => {
                                                if (!draft[key]) {
                                                    draft[key] = {
                                                        action: 'IGNORE',
                                                    }
                                                }
                                                draft[key].existingObjectId = e.target.value;
                                            });
                                        }}>
                                            <option value=""></option>
                                            {dimensions.data?.map(d => {
                                                return <option value={d.id as string}>{d.name}</option>
                                            })}
                                        </select>
                                    </>}
                                </td>
                            </tr>
                        })}
                        {(props.node.measures || []).map((d, idx) => {
                            const key = `MEASURE:${idx}`;
                            return <tr>
                                <td>
                                    Dimension: <br />
                                    <strong>{d.name}</strong>
                                </td>
                                <td>
                                    <select className="form-control" value={conversionChoices[key]?.action || 'IGNORE'} onChange={(e) => {
                                        setConversionChoices(draft => {
                                            if (!draft[key]) {
                                                draft[key] = {
                                                    action: 'IGNORE',
                                                }
                                            }
                                            draft[key].action = e.target.value as 'EXISTING'|'NEW'|'IGNORE';
                                        });
                                    }}>
                                        <option value="IGNORE">Ignore</option>
                                        <option value="EXISTING">Use Existing</option>
                                        <option value="NEW">Create New</option>
                                    </select>
                                </td>
                                <td>
                                    {conversionChoices[key]?.action == 'EXISTING' && <>
                                        <select className="form-control" value={conversionChoices[key].existingObjectId || ''} onChange={(e) => {
                                            setConversionChoices(draft => {
                                                if (!draft[key]) {
                                                    draft[key] = {
                                                        action: 'IGNORE',
                                                    }
                                                }
                                                draft[key].existingObjectId = e.target.value;
                                            });
                                        }}>
                                            <option value=""></option>
                                            {measures.data?.map(d => {
                                                return <option value={d.id as string}>{d.name}</option>
                                            })}
                                        </select>
                                    </>}
                                </td>
                            </tr>
                        })}
                        {(props.node.calculations || []).map((d, idx) => {
                            const key = `CALC:${idx}`;
                            return <tr>
                                <td>
                                    Dimension: <br />
                                    <strong>{d.name}</strong>
                                </td>
                                <td>
                                    <select className="form-control" value={conversionChoices[key]?.action || 'IGNORE'} onChange={(e) => {
                                        setConversionChoices(draft => {
                                            if (!draft[key]) {
                                                draft[key] = {
                                                    action: 'IGNORE',
                                                }
                                            }
                                            draft[key].action = e.target.value as 'EXISTING'|'NEW'|'IGNORE';
                                        });
                                    }}>
                                        <option value="IGNORE">Ignore</option>
                                        <option value="EXISTING">Use Existing</option>
                                        <option value="NEW">Create New</option>
                                    </select>
                                </td>
                                <td>
                                    {conversionChoices[key]?.action == 'EXISTING' && <>
                                        <select className="form-control" value={conversionChoices[key].existingObjectId || ''} onChange={(e) => {
                                            setConversionChoices(draft => {
                                                if (!draft[key]) {
                                                    draft[key] = {
                                                        action: 'IGNORE',
                                                    }
                                                }
                                                draft[key].existingObjectId = e.target.value;
                                            });
                                        }}>
                                            <option value=""></option>
                                            {measures.data?.map(d => {
                                                return <option value={d.id as string}>{d.name}</option>
                                            })}
                                        </select>
                                    </>}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
                </>}
                

            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-primary" onClick={() => {
                    convertFormat();
                }}>Convert</button>
            </Modal.Footer>
        </Modal>
        <Offcanvas show={showDataLibrary} placement="end" onHide={() => {
            setShowDataLibrary(false);
        }}>
            <Offcanvas.Header closeButton className="p-3">
                    <div className="flex-1">
                        {dataLibraryType == 'DIMENSION' && <>
                            <h2 className="mb-0">Add Dimension</h2>
                            {/* <div className="text-muted">A dimension is how you break down your report, like one row per product, campaign, or week.</div> */}
                        </>}
                        {dataLibraryType == 'MEASURE' && <>
                            <h2 className="mb-0">Add Measure</h2>
                            {/* <div className="text-muted">A measure is what you're tracking across your dimensions, like the number of orders, the amount of revenue, or the total clicks.</div> */}
                        </>}
                    
                    </div>
                    {!activeColumnField && <>
                        <div className="me-3" style={{width: '45%'}}>
                            <input type="text" className="form-control round-input w-100" placeholder="Search available columns" value={columnFilter} onChange={(e) => setColumnFilter(e.target.value)}/>

                        </div>
                    </>}
                    
                
            </Offcanvas.Header>
            <Offcanvas.Body>
            
                    
                    {!activeColumnField && <Pane><PaneContent>
                    <div className="p-3">
                        {Object.keys(availableColumnsByNodeId).map(nodeId => <>
                            <h3><PipelineNodeName singular pipelineNodeId={nodeId}/> Columns</h3>
                            <ul className="list-group">
                                {availableColumnsByNodeId[nodeId].map(ac => {
                                    return <li className="list-group-item list-group-item-action" onClick={() => {
                                        setShowDataLibrary(false);
                                        if (dataLibraryType == 'MEASURE') {
                                            addMeasure(ac.node.id as string, ac.field.id, ac.columnLabel || ac.field.label, ac.aggregator || getDefaultAggForType(ac.field.type))

                                        } else {
                                            addDimension(ac.node.id as string, ac.field.id, ac.columnLabel || ac.field.label);

                                        }
                                    }}>
                                        <AvailableColumnLabel col={ac} dimension={dataLibraryType == 'DIMENSION'}/>
                                        
                                    </li>
                                })}
                            </ul>
                            <hr />
                        </>)}
                    
                    </div></PaneContent></Pane>
                    }
                    

            </Offcanvas.Body>
        </Offcanvas>
        
        <PaneContent>
            <DraftOnly>
                <InfoAlert>
                    <div>
                        <strong>Hey!</strong> We have a new, better report builder. To use it, we'll just need to convert this report to a slightly different format.
                        <br />
                        <button className="btn btn-outline-secondary" onClick={() => {
                            setConvertingToNewFormat(true);
                        }}>Convert</button>


                    </div>
                </InfoAlert>
            </DraftOnly>
            
            
            <div className="p-3">
                {true && <>
                    <div className="d-flex mb-2 center-vertically">
                        <div className="flex-1">
                            <h2 className="mb-0">
                                Report Builder
                            </h2>
                            <div className="font-13">Change your report structure and view sample data below. Once you're ready, hit "Build" to see your entire report.</div>
                            
                            {/* <div className="font-13">This report contains one row per <code>DATE</code></div> */}
                        </div>
                        <button
                            className="btn btn-outline-primary me-1 ms-3"
                            disabled={bgSaving || (!changeSinceLastLoad && sampleData.length > 0) || measuresWithIssue > 0}
                            onClick={saveAndLoadSampleData}
                        >
                            <i className={`mdi mdi-refresh ${bgSaving ? 'mdi-spin' : ''}`}></i> Update Data
                        </button>
                        <DropdownButton
                            title="Add Data"
                            variant="outline-secondary"
                            disabled={bgSaving}
                        >
                            <BSDropdown.Item
                                onClick={() => {
                                    showDataLibraryWithType('DIMENSION');
                                }}
                            ><i className="mdi mdi-set-split"></i> Dimension</BSDropdown.Item>
                            <BSDropdown.Item
                                onClick={() => {
                                    showDataLibraryWithType('MEASURE');
                                }}
                            ><i className="mdi mdi-pound"></i> Measure</BSDropdown.Item>
                            <BSDropdown.Item
                                onClick={() => {
                                    addCalculation('NEW CALCULATION');
                                }}
                            ><i className="mdi mdi-calculator-variant"></i> Calculation</BSDropdown.Item>
                        </DropdownButton>
                          
                    </div>
                    
                </>}
                
                {true && <>
                    <div style={{width: '100%', overflow: 'scroll', position: 'relative', whiteSpace: 'no-wrap'}}>
                        {(bgSaving || loadingSampleData) && <>
                            <LoaderStyles>
                                <PliableLoader/>
                            </LoaderStyles>
                        </>}
                        
                        <StickyTable className="table table-fixed table-bordered table-top" >
                            
                            <thead className="bg-light">
                                <tr ref={tableContainer}>
                                    {(props.node.dimensions || []).length == 0 && <th style={{width: '50%'}}>
                                        Dimensions
                                    </th>}
                                    {(props.node.dimensions || []).map((d, idx)=> {
                                        return <th style={{width: '200px'}} key={idx}>
                                            <DimensionEditor
                                                dimensionIdx={idx}
                                                onDelete={() => {
                                                    deleteDimension(idx);
                                                }}
                                                dimension={d}
                                                onChange={(dim) => {
                                                    changeDimension(idx, dim)
                                                }}
                                            />
                                        </th>
                                    })}
                                    {(props.node.measures || []).map((m, idx) => {
                                        return <th style={{width: '200px'}} key={idx}>
                                            <MeasureEditor
                                                compact
                                                onDelete={() => {
                                                    deleteMeasure(idx)
                                                }}
                                                measure={m}
                                                onChange={(m) => {
                                                    changeMeasure(idx, m);
                                                }}
                                                dimensions={(props.node.dimensions || [])}
                                                chosenJoinPaths={chosenJoinPaths}
                                            />
                                        </th>
                                    })}
                                    {(props.node.measures || []).length == 0 && <th style={{width: '50%'}}>
                                        Measures
                                    </th>}
                                    {(props.node.calculations || []).map((c, idx) => {
                                        return <th style={{width: '200px'}} key={idx}>
                                        <CalculationEditor
                                            onDelete={() => {
                                                deleteCalculation(idx)
                                            }}
                                            calc={c}
                                            onChange={(newCalc) => {
                                                changeCalculation(idx , newCalc)
                                            }}
                                            allOtherFields={(props.node.dimensions || []).map(d => d.name).concat((props.node.measures || []).map(m => m.name))}
                                        />
                                    </th>
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {measuresWithIssue > 0 && (props.node.dimensions || []).length > 0 && <tr>
                                    <td colSpan={(props.node.measures || []).length + (props.node.dimensions || []).length + (props.node.calculations || []).length}>
                                        <Warning>
                                            In order to give you accurate data, we need some more information about one or more of your measures. Click on the&nbsp;&nbsp;<i className="mdi mdi-alert text-danger"></i>&nbsp;&nbsp;icon above.
                                        </Warning>
                                    </td>
                                </tr>}
                                {((props.node.dimensions || []).length == 0 || (props.node.measures || []).length == 0) && <tr>
                                    {(props.node.dimensions || []).length == 0 && <td>
                                        <div style={{height: '100px'}}>
                                            <InfoAlert>
                                                Dimensions are how you break down your report, like by week, by product, or by campaign (or even by product, by week).  

                                            </InfoAlert>
                                        </div> 
                                        <hr />
                                        <div className="d-flex center-vertically mb-2">
                                            <h5 className="mb-0 flex-1">
                                                <i className="mdi mdi-robot-love"></i> AI Suggestions
                                            </h5>
                                            <button className="btn btn-outline-primary btn-sm" onClick={() => {
                                                showDataLibraryWithType('DIMENSION');
                                            }}>Show All Options</button>
                                        </div>
                                        <ul className="list-group">
                                            {wizardDimensionOptions.map(o => {
                                                return <li className="list-group-item list-group-item-action" onClick={() => {
                                                    addDimension(o.node.id as string, o.field.id, o.columnLabel || o.field.label)
                                                }}>
                                                    <AvailableColumnLabel col={o} dimension/>
                                                </li>
                                            })}
                                        </ul>
 
                                    </td>}
                                    {(props.node.dimensions || []).length > 0 && <td>
                                        <SuccessAlert>Nice. Now add a measure to see some data.</SuccessAlert>    
                                    </td>}
                                    {(props.node.measures || []).length == 0 && <td>
                                        <div style={{height: '100px'}}>
                                            <InfoAlert>Measures are what you track by each dimension, like the total revenue or number of clicks.</InfoAlert>

                                        </div>
                                        <hr />
                                        <div className="d-flex center-vertically mb-2">
                                            <h5 className="mb-0 flex-1">
                                                <i className="mdi mdi-robot-love"></i> AI Suggestions
                                            </h5>
                                            <button className="btn btn-outline-primary btn-sm" onClick={() => {
                                                showDataLibraryWithType('MEASURE');
                                            }}>Show All Options</button>
                                        </div>
                                        <ul className="list-group">
                                            
                                            {wizardMeasureOptions.map(o => {
                                                return <li className="list-group-item list-group-item-action" onClick={() => {
                                                    addMeasure(o.node.id as string, o.field.id, o.columnLabel || o.field.label, o.aggregator);
                                                }}>
                                                    <AvailableColumnLabel col={o} dimension={false}/>
                                                    
                                                    
                                                </li>
                                            })}
                                        </ul>
   
                                    </td>}
                                    {(props.node.measures || []).length > 0 && <td colSpan={(props.node.measures || []).length}>
                                        <SuccessAlert>Nice. Now add a dimension to see some data.</SuccessAlert>    
                                    </td>}
                                </tr>}
                                
                                {}
                                {measuresWithIssue == 0 && (props.node.dimensions || []).length > 0 && (props.node.measures || []).length > 0 &&  <>
                                    {sampleData.length == 0 && <tr>
                                        <td colSpan={(props.node.measures || []).length + (props.node.dimensions || []).length + (props.node.calculations || []).length}>{DataUpdateRequired}</td>
                                    </tr>}
                                    {sampleData.length > 0 && sampleData.map(r => {
                                        return <tr>
                                        
                                         {(props.node.dimensions || []).map(d => {
                                             return <th className="bg-light">
                                                 {!r.hasOwnProperty(d.name) && <>{DataUpdateRequired}</>}
                                                 {r.hasOwnProperty(d.name) && <FormattedCell value={r[d.name]} format={d.formatter || ''}/>}
                                             </th>
                                         })}
                                         {(props.node.measures || []).map(m => {
                                             return <td>
                                                 {!r.hasOwnProperty(m.name) && <>{DataUpdateRequired}</>}
                                                 {r.hasOwnProperty(m.name) && <FormattedCell value={r[m.name]} format={m.formatter || ''}/>}
                                             </td>
                                         })}
                                         {(props.node.calculations || []).map(c => {
                                             return <td>
                                                 {!r.hasOwnProperty(c.name) && <>{DataUpdateRequired}</>}
                                                 {r.hasOwnProperty(c.name) && <FormattedCell value={r[c.name]} format={c.formatter || ''}/>}
                                                 
                                             </td>
                                         })}    
                                        </tr>
                                    })}
                                
                                </>}
                            </tbody>
                        </StickyTable>
                    </div>
                </>}
                
                
            </div>
            
        </PaneContent>
    </Pane>
}

export default PipelineNodeReportEditor;
