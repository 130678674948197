import DataModelSubnav from "@components/datamodel/DataModelSubnav.component";
import { MissionControlDataFlowDiagramManager } from "@components/missionControl/dataflow/MissionControlDataFlow";
import { DraftOnly } from "@components/project/DraftModeRequired.component";
import PageStructure, { PageContent, PageContentHeader, PageContentInner, Pane, PaneContent } from "@pages/PageStructure.component";
import { getPromptAnswer } from "@services/alert/alert.service";
import { useQuery } from "@services/url.service";
import { savePipelineNode, useMissionControlDataFlowData } from "@stores/data.store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const DataModelBusinessObjectsPage = () => {
    
    const data = useMissionControlDataFlowData();

    const filteredNodes = useMemo(() => {
        if (!data.data) {
            return [];
        }

        return data.data.nodes.filter((node) => {
            return ['DIMENSION', 'DATE_DIMENSION'].includes(node.data.nodeType);
        }).sort((a, b) => {
            return a.data.title.localeCompare(b.data.title);
        });
    }, [data.dataUpdatedAt]);

    const query = useQuery();

    const [focusNodeId, setFocusNodeId] = useState('');
    useEffect(() => {
        const focusNodeId = query.get('focusNodeId');
        setFocusNodeId(focusNodeId ? focusNodeId: '');

    }, [query]);

    const navigate = useNavigate();;
    const changeFocusNode = useCallback((id: string) => {
        navigate(`?focusNodeId=${id}`);
    }, [navigate]);

    const addNewBusinessObject = useCallback(async () => {
        const name = await getPromptAnswer('Enter the name of the new business object');
        if (!name) {
            return;
        }
        const res = await savePipelineNode({
            id: null,
            name: name,
            label: name,
            node_type: 'DIMENSION',
            description: '',
            table_name: '',
            upstream_node_ids: [],
            fields: [],
        });
        navigate('/node/' + res.id + '/config');
    }, []);

    return <PageStructure>
        <PageContent>
            <DataModelSubnav />
            <PageContentInner hasHeader noScroll>
                <div className="row" style={{height: '100%'}}>
                    <div className="col-3 pe-3">
                        <Pane>
                            <PaneContent>
                                <h2>Business Objects</h2>
                                <DraftOnly>
                                    <button onClick={addNewBusinessObject} className="btn btn-sm btn-outline-primary mb-3">Add New</button>
                                </DraftOnly>
                                <ul className="list-group">
                                    {filteredNodes.map((node) => {
                                        return <li className={`list-group-item list-group-item-action ${(focusNodeId == 'PipelineNode:' + node.id) ? 'active': ''}`} onClick={() => {
                                            changeFocusNode(node.id)
                                        }}>

                                            <div className="d-flex">

                                                <div className="flex-grow-1 overflow-ellipsis"><strong>{node.data.title}</strong></div> 
                                                
                                                <div className="px-1">
                                                    <a role="button" onClick={(e) => {
                                                        e.stopPropagation();
                                                        navigate('/node/' + node.id.replace('PipelineNode:', ''));
                                                    }} title="View Output"><i className="mdi mdi-table"></i></a>
                                                </div>
                                                <div className="px-1">
                                                    <a role="button" onClick={(e) => {
                                                        e.stopPropagation();
                                                        navigate('/node/' + node.id.replace('PipelineNode:', '')+ '/config');
                                                    }} title="Configure"><i className="mdi mdi-cog"></i></a>
                                                </div>
                                            </div>

                                            {node.data.description && <div className="small text-muted overflow-ellipsis">{node.data.description}</div>}

                                            
                                        </li>
                                    })}
                                </ul>
                                <div className="mb-3"></div>
                            </PaneContent>
                        </Pane>
                    </div>
                    <div className="col-9">
                        <div className="card" style={{height: 'calc(100% - 5rem)'}}>
                            <MissionControlDataFlowDiagramManager
                                nodeData={filteredNodes}
                                edgeData={data.data?.edges || []}
                                focusNodeId={focusNodeId}
                                onDeleteEdge={() => {

                                }}
                                onClickNode={() => {

                                }}
                                onEditEdge={() => {

                                }}
                                onClickEdgeStatusIndicator={() => {
                                    
                                }}
                                onToggleNodeLineage={() => {

                                }}
                            />
                        </div>
                        
                    </div>
                </div>
            
            </PageContentInner>
        </PageContent>
    </PageStructure>
}

export default DataModelBusinessObjectsPage;