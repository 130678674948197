import { useCallback, useEffect, useRef, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageStructure, { PageContent, PageContentInner } from './PageStructure.component';
import AuthService from '@services/auth/auth.service';
import ConfigService from '@services/config/config.service';
import AsyncButton from '@components/button/AsyncButton.component';
import TenantConfigService from '@services/tenantConfig.service';
import { useEntitlements } from '@frontegg/react';
import PliableLoader from '@components/loaders/PliableLoader.component';
import LoadingCard from '@components/card/LoadingCard.component';
import { exitDraftMode, useDraftVersionId } from '@stores/data.store';
import toast from '@services/toast.service';
import { getErrorMessage } from '@services/errors.service';
import { SupersetNotEnabled } from '@components/bi/SupersetDisabled.component';


const SupersetBiPage = () => {
    const navigate = useNavigate();
    const {data: draftVersionId} = useDraftVersionId();

    const [biStartUrl, setBiStartUrl] = useState('');
    const [ensuringSupersetSetup, setEnsuringSupersetSetup] = useState(false);
    const iframeRef = useRef<HTMLIFrameElement>(null)

    const { isEntitled: hostedSupersetEnabled } = useEntitlements({
        featureKey: "hosted_superset",
    });

    const enterProduction = useCallback(async () => {
        await exitDraftMode();
    }, [draftVersionId]);


    useEffect(() => {
        window.addEventListener('message', handleIframeMessage);

        const app_url = window.location.href;
        const bi_path = app_url.substring(app_url.indexOf('/bi')+3);
        setBiStartUrl(`https://bi.${window.location.hostname}${bi_path}`);
        
        return () => {
            window.removeEventListener('message', handleIframeMessage);
        }
    }, []);

    const ensureSuperset = async () => {
        setEnsuringSupersetSetup(true);
        try {
            await TenantConfigService.getInstance().ensureHostedSuperset(ConfigService.getInstance().config!.id);
            toast('success', 'Success', 'BI setup complete');
            window.location.reload();
        }catch(err){
            toast('danger', 'Error', getErrorMessage(err));
        }finally{
            setEnsuringSupersetSetup(true);
        } 
    }

    let content = null;

    if(!hostedSupersetEnabled){
        content = <SupersetNotEnabled />
    }else if(!!ConfigService.getInstance().config?.superset) {
        if(!!draftVersionId){
            content = <div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: '100px' }}>
                    <p>You need to be in production mode to view BI data.</p>
                    <button className="btn btn-pliable" onClick={enterProduction}><i className="mdi mdi-exit-to-app"></i> Production Mode</button>
                </div>
                </div>

        }else if(biStartUrl != ''){
            content = <iframe src={biStartUrl} 
                style={{width: '100%', height: 'calc(100vh - 50px)', border: 'none', overflow: 'auto'}}
                ref={iframeRef}
                ></iframe>
        }else{
            content = <LoadingCard />
        }
    }else{
        content = <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: '100px' }}>
            <h1>BI Warehouse is not setup yet</h1>

            <AsyncButton 
                onClick={ensureSuperset}
                text={'Run BI Setup'}
                loading={ensuringSupersetSetup}
            ></AsyncButton>

            {ensuringSupersetSetup && <p className="text-muted" style={{ padding: '15px'}}>This may take a couple minutes</p>}
        </div>
    }


    const handleIframeMessage = useCallback((event: MessageEvent<any>) => {
        if(event.data.request_type === 'location-change'){
            const newLocation = new URL(event.data.location);
            const path = newLocation.pathname;
            navigate(`/bi${path}${newLocation.search}${newLocation.hash}`);
            return
        }

        if (event.data.request_type !== 'login-request') {
            return;
        }

        event.source!.postMessage({
            request_type: 'login-return', payload: {
                token: AuthService.getInstance().getAccessToken(),
                config: {
                    formatLocale: ConfigService.getInstance().config?.format_locale || {}
                }
            }
        }, {targetOrigin: event.origin});
    }, []);

    return <PageStructure>
        <PageContent>
            <PageContentInner noScroll noPadding >
               {content}
            </PageContentInner>
        </PageContent>
    </PageStructure>
        
}

export default SupersetBiPage;

