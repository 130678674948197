import LoadingCard from "@components/card/LoadingCard.component";
import { PipelineNodeORM } from "@models/pipelineNode";
import { getPromptAnswer, requireConfirmation } from "@services/alert/alert.service";
import { getErrorMessage } from "@services/errors.service";
import toast from "@services/toast.service";
import { invalidateEverything, usePipelineNode } from "@stores/data.store";
import { useCallback, useState } from "react";
import { ButtonGroup, Form, Dropdown as BSDropdown, Button, Offcanvas, OffcanvasBody, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PipelineNodeConnector from "./PipelineNodeConnector.component";
import { Option } from "@components/form/Dropdown.component";
import DagDataLibrary from "@components/nav/DagDataLibrary.component";
import { Pane, PaneContent } from "@pages/PageStructure.component";
import { DraftOnly } from "@components/project/DraftModeRequired.component";


interface Props {
    pipelineNodeId: string;
    onForceBuild: () => any;
    searchTextForDownload?: string;
}

const PipelineNodeExtrasMenu = (props: Props) => {
    const navigate = useNavigate();

    const pipelineNode = usePipelineNode(props.pipelineNodeId);

    const deleteNode = useCallback(async () => {
        const confirmed = await requireConfirmation(`Are you sure you want to delete this node? Type out the full node name ("${pipelineNode.data?.name}") to continue.`, 'Delete Node', 'Delete', 'Cancel', pipelineNode.data?.name);
        if (confirmed) {
            // setPageDirty(false)
            await PipelineNodeORM.deleteById(props.pipelineNodeId);
            navigate('/');
            invalidateEverything();
            toast('success', 'Node deleted', 'Node deleted successfully.');
        }
    }, [props.pipelineNodeId, pipelineNode.dataUpdatedAt]);

    const copyNode = useCallback(async () => {
        const newLabel = await getPromptAnswer('Enter new name for the copied node', 'Copy Node', false, `${pipelineNode.data?.name} copy`);
        if (!newLabel) {
            return;
        }
        const newNode = await PipelineNodeORM.copyPipelineNode(props.pipelineNodeId, newLabel);
        navigate(`/node/${newNode.id}/config`);
    }, [props.pipelineNodeId, pipelineNode.dataUpdatedAt]);

    const [showConnectModal, setShowConnectModal] = useState(false);
    const [showDag, setShowDag] = useState(false);

    return <>
        <PipelineNodeConnector
            connectingNodeId={props.pipelineNodeId}
            show={showConnectModal}
            onHide={() => setShowConnectModal(false)}
            actionFilter={(action: Option) => {
                return ['ADD_AS_SOURCE', 'MAP_TO_NEW_NODE'].includes(action.value);
            }}
        />
        <Offcanvas show={showDag} onHide={() => setShowDag(false)} placement="end" scroll={true}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Data Flow</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Pane>
                    <PaneContent>
                        <div className="p-3">
                            <DagDataLibrary
                                activeNodeId={'PipelineNode:' + props.pipelineNodeId}
                            />
                        </div>
                    </PaneContent>
                </Pane>
                
            </Offcanvas.Body>
        </Offcanvas>
        <BSDropdown>
            <BSDropdown.Toggle  variant="light" className="nocaret btn btn-light ms-1 btn-lg" >
                <i className="mdi mdi-dots-vertical"></i>
            </BSDropdown.Toggle>
            <BSDropdown.Menu>
                <DraftOnly>
                    <BSDropdown.Item onClick={deleteNode}>
                        Delete Node
                    </BSDropdown.Item>
                </DraftOnly>
                <DraftOnly>
                    <BSDropdown.Item onClick={copyNode}>
                        Clone
                    </BSDropdown.Item>
                </DraftOnly>
                <BSDropdown.Item onClick={() => {
                    navigate(`/dag?focusNodeId=PipelineNode:${props.pipelineNodeId}`);
                }}>
                    View in Context
                </BSDropdown.Item>
                <DraftOnly>
                    <BSDropdown.Item onClick={() => {
                        setShowConnectModal(true);
                    }}>
                        Map Data
                    </BSDropdown.Item>
                </DraftOnly>
                <BSDropdown.Item onClick={() => {
                    setShowDag(true);
                }}>
                    View Connections
                </BSDropdown.Item>
                <BSDropdown.Item onClick={props.onForceBuild}>
                    Force Rebuild
                </BSDropdown.Item>
                
            </BSDropdown.Menu>
        </BSDropdown>
    </>
}

export default PipelineNodeExtrasMenu;