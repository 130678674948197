import { DraftOnly } from "@components/project/DraftModeRequired.component";
import { useEntitlements } from "@frontegg/react";
import { PipelineNodeORM } from "@models/pipelineNode";
import { PageContentHeader } from "@pages/PageStructure.component";
import { getPromptAnswer } from "@services/alert/alert.service";
import { summarizeNumber } from "@services/formatting.service";
import { getNodeTypeConfig } from "@services/modeling.service";
import { timeAgo } from "@services/time.service";
import { useIsInDraftMode, usePipelineNode } from "@stores/data.store";
import { ReactNode, useMemo } from "react";
import { Badge } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

interface Props {
    pipelineNodeId: string;
    children?: ReactNode;
}

const PipelineNodeSubnav = (props: Props) => {
    const location = useLocation();
    const pipelineNode = usePipelineNode(props.pipelineNodeId);
    const inDraftMode = useIsInDraftMode();

    const { isEntitled: hostedSupersetEnabled } = useEntitlements({
        featureKey: "hosted_superset",
    });

    const activePage = useMemo(() => {
        let path = location.pathname;

        if (path.endsWith('/')) {
            path = path.substring(0, path.length - 1);
        }
        
        if (path.endsWith('/config')) {
            return 'config';
        } else if (path.endsWith('/files')) {
            return 'files';
        } else if (path.indexOf('/relationships') >= 0) {
            return 'relationships';
        } else if (path.endsWith('/insights')) {
            return 'insights';
        } else if (path.endsWith('/filters')) {
            return 'filters';
        } else if (path.indexOf('/visualizations') >= 0) {
            return 'visualizations';
        }

        return 'data';
    }, [location]);

    const nodeConfig = useMemo(() => {
        if (!pipelineNode.data) {
            return undefined;
        }
        return getNodeTypeConfig(pipelineNode.data);
    }, [pipelineNode.dataUpdatedAt]);

    const nodeTypeConfig = useMemo(() => {
        if (!pipelineNode.data) {
            return undefined;
        }

        return getNodeTypeConfig(pipelineNode.data);
    }, [pipelineNode.dataUpdatedAt]);
    
    const currentNodeType = pipelineNode.data?.node_type;
    const nodeTypeOptions = [
        { value: 'STACK', label: 'Stack' },
        { value: 'MERGE', label: 'Merge' },
        { value: 'SPLIT', label: 'Split' },
        { value: 'SUMMARIZE', label: 'Summarize' },
        { value: 'IDENTIFY', label: 'Identify' },
        { value: 'VIEW', label: 'View' },
    ];

    const updateNodeType = async (nodeId: string, newNodeType: string) => {
        const updateData: any = { node_type: newNodeType };
        switch (newNodeType) {
            case 'SUMMARIZE':
                updateData.combine_behavior = 'AGGREGATE';
                break;
            case 'STACK':
                updateData.combine_behavior = 'MERGE';
                if (pipelineNode.data?.fields) {
                    pipelineNode.data.fields.forEach((field: any) => {
                        if (field.part_of_composite_key) {
                            field.part_of_composite_key = false;
                        }
                    });
                    updateData.fields = pipelineNode.data.fields;
                }
                break;
        }
        await PipelineNodeORM.patch(nodeId, updateData);
    };
    
    const onNodeTypeChange = async () => {
        const newNodePrompt = await getPromptAnswer('Select a node type' , 'Change Node Type', false, currentNodeType, nodeTypeOptions);
        if (!newNodePrompt) {
            return;
        }
        if (pipelineNode.data && pipelineNode.data.id) {
            await updateNodeType(pipelineNode.data.id, newNodePrompt);
        }
        pipelineNode.refetch();
    };
    
    const badge = useMemo(() => {
        if (!pipelineNode.data || !nodeTypeConfig) {
            return <></>
        }

        const bg = nodeTypeConfig.group.color;
        const icon = nodeTypeConfig.nodeType.icon;
        const label = nodeTypeConfig.nodeType.label;

        const cleaningNodeTypes = ['Stack', 'Merge', 'Split', 'Summarize', 'Identify', 'View'];
        const isCleaningNode = cleaningNodeTypes.includes(label);

        return (
            <Badge
                pill
                bg={bg}
                onClick={isCleaningNode && inDraftMode ? () => onNodeTypeChange() : undefined}
                style={{ cursor: isCleaningNode && inDraftMode ? 'pointer' : 'default' }}
            ><i className={icon}></i> {label}
            </Badge>
        );
    }, [pipelineNode.data, nodeTypeConfig]);


    if (!pipelineNode.data || !nodeConfig) {
        return <></>;   
    }

    return <PageContentHeader>
        <div className="top">
            <div className="d-flex center-vertically mb-2">
                <div className="flex-1 overflow-ellipsis">
                    
                    <h1 className="mb-0 overflow-ellipsis" style={{ maxWidth: '40vw' }} title={pipelineNode.data?.label}>
                        {pipelineNode.data?.label}
                    </h1>
                    <div className="mb-1 font-poppins text-muted font-13">
                        <span>{badge}</span>
                        <span className="me-3">&nbsp;</span>
                        <span>
                            <i className="mdi mdi-file-multiple"></i> Records: {pipelineNode.data.total_records ? summarizeNumber(pipelineNode.data.total_records) : '0'}
                        </span>
                        <span className="me-3">&nbsp;</span>
                        <span>
                            <i className="mdi mdi-clock"></i> Last Updated: {pipelineNode.data.last_build_completed ? timeAgo(pipelineNode.data.last_build_completed, 'mini-minute-now') : 'never'}
                        </span>
                    </div>
                    <p className="overflow-ellipsis" title={pipelineNode.data?.description}>{pipelineNode.data?.description}</p>
                </div>
                <div>
                    {props.children}
                    
                </div>
            </div>
        </div>        
        
        <nav className="subnav mt-2">
            <Link to={`/node/${props.pipelineNodeId}`} className={`nav-link ${activePage == 'data' ? 'active' : ''}`}>
                <i className="mdi mdi-table"></i> Output
            </Link>
            <Link to={`/node/${props.pipelineNodeId}/config`} className={`nav-link ${activePage == 'config' ? 'active' : ''}`}>
                <i className="mdi mdi-cog"></i> Configure
            </Link>
            {pipelineNode.data && pipelineNode.data.flat_file && (
                <DraftOnly>
                    <Link to={`/node/${props.pipelineNodeId}/files`} className={`nav-link ${activePage === 'files' ? 'active' : ''}`}>
                        <i className="mdi mdi-file-multiple"></i> Files
                    </Link>
                </DraftOnly>
            )}
            {['STAGING', 'DATA_MODELING'].includes(nodeConfig?.group.value || '') &&  (
                <>
                    
                    <Link to={`/node/${props.pipelineNodeId}/relationships`} className={`nav-link ${activePage == 'relationships' ? 'active' : ''}`}>
                        <i className="mdi mdi-transit-connection-variant"></i> Relationships
                    </Link>
                </>
                
            )}
            {!hostedSupersetEnabled &&
                <Link to={`/node/${props.pipelineNodeId}/visualizations`} className={`nav-link ${activePage == 'visualizations' ? 'active' : ''}`}>
                    <i className="mdi mdi-chart-bar"></i> Visualizations
                </Link>}
            {/* <Link to={`/node/${props.pipelineNodeId}/insights`} className={`nav-link ${activePage === 'insights' ? 'active' : ''}`}>
                <i className="mdi mdi-lightbulb-on"></i> Insights
            </Link> */}
        </nav>
    </PageContentHeader>;
}

export default PipelineNodeSubnav;