import Dropdown, { MultiDropdown, Option } from "@components/form/Dropdown.component";
import PipelineNodeJoinTreeLabel from "@components/pipelineNodes/mapping/PipelineNodeJoinTreeLabel.component";
import PipelineNodeColumnSelector from "@components/pipelineNodes/PipelineNodeColumnSelector.component";
import DataWhitelistForm from "@components/pipelineNodes/PipelineNodeDataWhitelist.component";
import PipelineNodeSelector from "@components/pipelineNodes/PipelineNodeSelector.component";
import { DraftOnly } from "@components/project/DraftModeRequired.component";
import Warning from "@components/statusIndicators/Warning.component";
import { PipelineNode, PipelineNodeMeasureJoinTree, useReportingPaths } from "@models/pipelineNode";
import { ReportBuilderDimensionJoinTree, ReportBuilderMeasure, useDimensions, useMeasures } from "@models/reportBuilder";
import FormatForm from "@pages/SourceRecordType/FormatForm.component";
import { useIsInDraftMode, usePipelineNodes, useReportingTree } from "@stores/data.store";
import { graphlib } from "dagre";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { Updater, useImmer } from "use-immer";

const getAllPredecessors = (graph: graphlib.Graph, nodeId: string): string[] => {
    // @ts-ignore
    const predecessors = graph.predecessors(nodeId) as string[];
    if (!predecessors) {
        return [];
    }
    const upstream = predecessors?.map(s => getAllPredecessors(graph, s));
    return predecessors.concat(...upstream);
}


interface DimensionJoinPathEditorProps {
    measurePipelineNodeId: string;
    joinTree: ReportBuilderDimensionJoinTree;
    onChange: (updatedJoinTree: ReportBuilderDimensionJoinTree) => void;
    nodeOptionFilter: (node: PipelineNode) => boolean;
    additionalNodeIdsInWhitelist?: string[];
}


export function joinTreeId(path: PipelineNodeMeasureJoinTree): string {
    const components: string[] = [path.node_id + ':' + (path.relationship_id || 'NONE')];
    path.downstream.forEach(ds => {
        components.push(joinTreeId(ds));
    })
    return components.join('->');
}


const DimensionJoinPathEditor = (props: DimensionJoinPathEditorProps) => {
    const [expanded, setExpanded] = useState(false);
    const inDraft = useIsInDraftMode();
    const nodesInPath = [props.measurePipelineNodeId].concat(props.additionalNodeIdsInWhitelist || []);

    const reportingPaths = useReportingPaths(props.joinTree.pipeline_node_id, nodesInPath);

    const thisJoinPathId = useMemo(() => {
        if (props.joinTree && props.joinTree.join_tree) {
            return joinTreeId(props.joinTree.join_tree);
        }
        return '';
    }, [props.joinTree]);

    const changeJoinTree = useCallback((newJp: PipelineNodeMeasureJoinTree) => {
        props.onChange({
            ...props.joinTree,
            join_tree: newJp,
        });
        setExpanded(false);
    }, [props.joinTree, props.onChange]);

    return <div>
        <div className="mb-2">
            <Form.Label>Connects to </Form.Label>
            <PipelineNodeSelector
                disabled={!inDraft}
                selectedId={props.joinTree.pipeline_node_id}
                onSelect={(node) => {
                    props.onChange({
                        ...props.joinTree,
                        pipeline_node_id: node ? node.id as string : ''
                    });
                }}
                optionFilter={props.nodeOptionFilter}
                
                
            />
        </div>
        {props.joinTree.pipeline_node_id &&
        <div>
            <strong>Join Path: </strong>
            {props.joinTree.join_tree && <>
                <PipelineNodeJoinTreeLabel singlePath={(props.additionalNodeIdsInWhitelist && props.additionalNodeIdsInWhitelist.length > 0) ? false : true} joinTree={props.joinTree.join_tree}/>
                <DraftOnly>
                    <button className="btn btn-sm btn-outline-secondary" onClick={() => {
                        setExpanded(true);
                    }}>
                        <i className="mdi mdi-pencil"></i> Edit
                    </button>
                </DraftOnly>
            </>}
            {!props.joinTree.join_tree && <a role="button" onClick={() => {
                setExpanded(true);
            }}>Select Path</a>}
            {expanded && <ul className="list-group">
                {reportingPaths.data?.map((rp, rpIdx) => {
                    const thatJoinPathId = joinTreeId(rp);
                    const active = thisJoinPathId == thatJoinPathId;
                    return <li className="list-group-item overflow-auto" key={rpIdx}>
                        <div className="d-flex center-vertically">
                            <div className="flex-1"><PipelineNodeJoinTreeLabel joinTree={rp} singlePath={(props.additionalNodeIdsInWhitelist && props.additionalNodeIdsInWhitelist.length > 0) ? false : true}/>
                                
                            </div>
                            <div>
                                <button className={`icon-button font-18 ${active ? 'text-success' : ''}`} onClick={() => {
                                    changeJoinTree(rp);
                                }}>
                                    <i className="mdi mdi-check-circle"></i>
                                </button>
                            </div>
                        </div>
                    </li>;
                })}
            </ul>}
            
        </div>
        }
    </div>
}


interface DimensionPickerProps {
    businessObjectGraph: graphlib.Graph;
    measurePipelineNodeId: string;
    existingDimensions: ReportBuilderDimensionJoinTree[];
    onChange: Updater<ReportBuilderMeasure>;
    additionalNodeIdsInWhitelist?: string[];
}

const DimensionPicker = (props: DimensionPickerProps) => {
    const nodes = usePipelineNodes();
    const dimensions = useDimensions();

    const availableDimensionNodeIds = useMemo(() => {
        if (!dimensions.data) {
            return [];
        }
        const allDimensionNodeIds = dimensions.data.map(d => d.pipeline_node_id);
        // Get all the nodes IDs that are connected to the selected node
        const thisNodeId = 'PipelineNode:' + props.measurePipelineNodeId;
        const allPredecessors = getAllConnections(props.businessObjectGraph, thisNodeId, []).map(s => s.replace('PipelineNode:', ''));
        return allPredecessors.filter(p => allDimensionNodeIds.includes(p));
    }, [dimensions.dataUpdatedAt, props.businessObjectGraph, props.measurePipelineNodeId]);

    const availableDimensions = useMemo(() => {
        if (!nodes.data) {
            return [];
        }
        return nodes.data?.filter(n => availableDimensionNodeIds.includes(n.id as string));
    }, [nodes.dataUpdatedAt, availableDimensionNodeIds]);

    const selectedNodeIds = useMemo(() => {
        return props.existingDimensions.map(d => d.pipeline_node_id);
    }, [props.existingDimensions]);

    const removeDimensionJoinTree = useCallback((idx: number) => {
        props.onChange(measure => {
            if (!measure.dimension_join_trees) {
                return;
            }
            measure.dimension_join_trees.splice(idx, 1);
        });
    }, [props.onChange]);
    return <div>
        <div className="mb-3">
            {props.existingDimensions.map((d, idx) => {
                
                return <div className="shadow-box p-2 mb-1" key={idx}>
                    <div className="d-flex center-vertically">
                        <div className="flex-1">
                        <DimensionJoinPathEditor
                            additionalNodeIdsInWhitelist={props.additionalNodeIdsInWhitelist}
                            measurePipelineNodeId={props.measurePipelineNodeId}
                            nodeOptionFilter={(node) => availableDimensionNodeIds.includes(node.id as string)}
                            joinTree={d}
                            onChange={(updatedJoinTree) => {
                                props.onChange(measure => {
                                    if (!measure.dimension_join_trees) {
                                        measure.dimension_join_trees = [];
                                    }
                                    measure.dimension_join_trees[idx] = updatedJoinTree;
                                });
                            }}
                        />
                        </div>
                        
                        <div style={{width: '50px'}} className="text-end">
                            <DraftOnly>
                                <button className="icon-button text-18" onClick={() => {
                                    removeDimensionJoinTree(idx);
                                }}>
                                    <i className="mdi mdi-delete"></i>
                                </button>
                            </DraftOnly>
                        </div>
                    </div>
                    
                    
                    
                </div>
            })}
        </div>
        
        
        
    </div>
}


export const getAllConnections = (graph: graphlib.Graph, nodeId: string, skipNodeIds: string[]): string[] => {
    // @ts-ignore
    const neighbors = graph.neighbors(nodeId) as string[];
    if (!neighbors) {
        return [];
    }

    const neighborsToCheck = neighbors.filter(n => !skipNodeIds.includes(n));
    const newSkipNodeIds = skipNodeIds.concat(neighborsToCheck);
    const upstream = neighborsToCheck.map(s => getAllConnections(graph, s, newSkipNodeIds)).reduce((acc, val) => acc.concat(val), []);
    return neighborsToCheck.concat(...upstream); 
}

export const getConnectionsForAllGraphNodes = (graph: graphlib.Graph): {[key: string]: string[]} => {
    const nodes = graph.nodes();
    const connections: {[key: string]: string[]} = {};
    const connectionGroups: string[][] = [];

    let nodesWeCanSkip: string[] = [];
    
    nodes.forEach(n => {
        if (nodesWeCanSkip.includes(n)) {
            return;
        }
        const results = getAllConnections(graph, n, []);
        connectionGroups.push(results);
        nodesWeCanSkip = nodesWeCanSkip.concat(results);
    });

    connectionGroups.forEach(g => {
        g.forEach(n => {
            if (!connections[n]) {
                connections[n] = [];
            }
            connections[n] = connections[n].concat(g);
        });
    })
    return connections;
}

interface MeasureFormProps {
    measure: ReportBuilderMeasure;
    onSubmit: (updatedMeasure: ReportBuilderMeasure) => void;
    onCancel: () => void;
}

const MeasureForm = (props: MeasureFormProps) => {
    const inDraft = useIsInDraftMode();
    const [selectedNode, setSelectedNode] = useState<PipelineNode | null>(null);
    const [measure, setMeasure] = useImmer<ReportBuilderMeasure>(props.measure);
    const tree = useReportingTree();

    const nodes = usePipelineNodes();

    useEffect(() => {
        if (!nodes.data) {
            return;
        }
        if (props.measure.pipeline_node_id && !selectedNode) {
            setSelectedNode(nodes.data?.find(n => n.id === props.measure.pipeline_node_id) || null);
        }
    }, [props.measure.pipeline_node_id, selectedNode, nodes.dataUpdatedAt]);

    const graph = useMemo(() => {
        const g = new graphlib.Graph({
            directed: true,
            multigraph: true,
        });

        if (!tree.data) {
            return g;
        }

        tree.data.nodes.forEach(n => {
            g.setNode(n.id, n.title);
        });

        tree.data.edges.forEach(e => {
            g.setEdge(e.from_id, e.to_id, e.relationship_id);
        });

        return g
    }, [tree.dataUpdatedAt]);

    const allNodeConnections = useMemo(() => {
        return getConnectionsForAllGraphNodes(graph);
    }, [graph]);

    // Set the field based on the selected field_id and selectedNode
    const selectedField = useMemo(() => {
        if (!selectedNode || !measure) return null;
        if (measure.field_id === '_PLB_UUID') {
            return {
                id: '_PLB_UUID',
                name: 'Pliable Record ID',
                type: 'ID',
            }
        }
        return selectedNode.fields.find(f => f.id === measure.field_id);
    }, [selectedNode, measure]);

    const aggregationOptions = useMemo(() => {
        if (!selectedField) return [];
        
        const options: Option[] = [{
            value: 'COUNT_DISTINCT',
            label: 'Count Distinct',
        }, {
            value: 'PICK_ONE',
            label: 'Random',
        }];

        switch (selectedField.type) {
            case 'DATE':
            case 'DATETIME':
            case 'DATETIME_TZ':
                options.push({
                    value: `MIN`,
                    label: 'Min'
                });
                options.push({
                    value: `MAX`,
                    label: 'Max',
                });
                break;
            case 'INT':
            case 'DECIMAL':
                options.push({
                    value: `MIN`,
                    label: 'Min',
                });
                options.push({
                    value: `MAX`,
                    label: 'Max',
                });
                options.push({
                    value: `AVG`,
                    label: 'Avg'
                });
                options.push({
                    value: `SUM`,
                    label: 'Sum'
                });
                break;
            case 'STRING':
                options.push({
                    value: 'CONCAT',
                    label: 'Comma-separated list of unique values',
                });
                break;
        }
        return options;

    }, [selectedField]);

    const allMeasures = useMeasures();

    const otherMeasures = useMemo(() => {
        if (!allMeasures.data) {
            return [];
        }
        return allMeasures.data.filter(m => m.id !== measure.id);
    }, [allMeasures.dataUpdatedAt, measure.id]);

    const filterableNodeIds = useMemo(() => {
        /**
         * Provides a list of nodes that can be used for filtering. Note that each node selected here that is NOT the pipeline_node for this measure
         * will need to be in every single join path for every connected dimension. Thus, the moment a user selects a node here, we need to add it to the
         * join path lookups
         */
        if (!measure.pipeline_node_id || !allNodeConnections || Object.keys(allNodeConnections).length === 0) {
            return [];
        }


        return [measure.pipeline_node_id].concat((allNodeConnections['PipelineNode:' + measure.pipeline_node_id] || []).map(n => n.replace('PipelineNode:', '')));

    }, [allNodeConnections, measure.pipeline_node_id]);

    const saveEnabled = useMemo(() => {
        if (!measure.name || !measure.column_name) {
            return false;
        } 

        if (measure.is_calculation) {
            return !!measure.formula && measure.parent_measure_ids && measure.parent_measure_ids.length > 0;
        } else {
            return !!measure.pipeline_node_id && !!measure.field_id && !!measure.aggregator;
        }
    }, [measure.name, measure.column_name, measure.is_calculation, measure.formula, measure.parent_measure_ids, measure.aggregator, measure.pipeline_node_id, measure.field_id]);

    const additionalNodeIdsInWhitelist = useMemo(() => {
        return measure.data_whitelist?.entries.map(e => e.pipeline_node_id).filter(n => n !== measure.pipeline_node_id) || [];
    }, [measure.data_whitelist, measure.pipeline_node_id]);

    return <>
        <h2>
            <i className="mdi mdi-information-outline"></i> Basic Information
        </h2>
        <Form.Group className="mb-3">
            <Form.Label>Name*</Form.Label>
            <Form.Control disabled={!inDraft} value={measure?.name || ''} type="text" isValid={!!measure.name} onChange={(e) => {
                setMeasure(draft => {
                    if (!draft) return;
                    draft.name = e.target.value;
                });
            }}/>
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Column Label*</Form.Label>
            <Form.Control disabled={!inDraft} value={measure?.column_name || ''} isValid={!!measure.column_name} type="text" onChange={(e) => {
                setMeasure(draft => {
                    if (!draft) return;
                    draft.column_name = e.target.value;
                });
            }}/>
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control disabled={!inDraft} value={measure?.description || ''} as="textarea" onChange={(e) => {
                setMeasure(draft => {
                    if (!draft) return;
                    draft.description = e.target.value;
                });
            }}/>
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Format</Form.Label>
            <FormatForm
                disabled={!inDraft}
                onChange={(formatter) => {
                    setMeasure(draft => {
                        if (!draft) return;
                        draft.formatter = formatter;
                    });
                }}
                selectedFormat={measure?.formatter || ''}
                placeholder="Select Output Format"
            />
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Check
                type="switch"
                checked={measure?.is_calculation || false}
                onChange={(e) => {
                    setMeasure(draft => {
                        if (!draft) return;
                        draft.is_calculation = e.target.checked;
                    });
                }}
                label="Calculated measure"
            />
            <Form.Text>Enable this to calculate a value from other measures.</Form.Text>
        </Form.Group>
        <hr />

        {measure?.is_calculation && <div>
            <Form.Group className="mb-3">
                <Form.Label>Connected Measures*</Form.Label>
                <MultiDropdown
                    options={otherMeasures.map(m => ({
                        value: m.id as string,
                        label: m.name,
                    }))}
                    selected={measure?.parent_measure_ids || []}
                    onChange={(selected) => {
                        setMeasure(draft => {
                            if (!draft) return;
                            draft.parent_measure_ids = selected;
                        });
                    }}
                />
                <Form.Text>Select the other measures you need for this calculation.</Form.Text>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Calculation Formula*</Form.Label>
                <textarea className="form-control" value={measure.formula} onChange={(e) => {
                    setMeasure(draft => {
                        if (!draft) return;
                        draft.formula = e.target.value;
                    });
                }}/>
            </Form.Group>
            
        </div>}

        {!measure?.is_calculation && <div>
            <h2>
                <i className="mdi mdi-database-import"></i> Data Source
            </h2>
             {/* If it's not a calculation, the user has to select the node, field, and aggregation  */}
            <Form.Group className="mb-3">
                <Form.Label>Select Node</Form.Label>
                <PipelineNodeSelector 
                    disabled={!inDraft}
                    optionFilter={(pn: PipelineNode) => ['DIMENSION', 'DATE_DIMENSION', 'BUSINESS_OBJECT'].includes(pn.node_type)}
                    selectedId={measure?.pipeline_node_id || ''}
                    onSelect={(node: PipelineNode|undefined) => {
                        setSelectedNode(node || null);
                        setMeasure(draft => {
                            if (!draft) return;
                            draft.pipeline_node_id = node ? node.id as string : '';
                        });
                    }} 
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Select Column</Form.Label>
                <PipelineNodeColumnSelector 
                    disabled={!inDraft}
                    excludeForeignKeys
                    includeRecordId
                    selectedId={measure?.field_id || ''}
                    onSelect={(fieldId: string) => {
                        setMeasure(draft => {
                            if (!draft) return;

                            draft.field_id = fieldId;
                        });
                    }}
                    pipelineNodeId={measure?.pipeline_node_id || ''}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Rollup</Form.Label>
                <Dropdown
                    disabled={!inDraft}
                    options={aggregationOptions}
                    selected={measure?.aggregator || ''}
                    onChange={(key) => {
                        setMeasure(draft => {
                            if (!draft) return;
                            draft.aggregator = key;
                        });
                    }}
                />
            </Form.Group>

            <hr />
            
            <div className="d-flex center-vertically mb-1">

                <h2 className="mb-0 flex-1">
                    <i className="mdi mdi-transit-connection-variant"></i> Connected Dimensions
                </h2>
                <DraftOnly>
                    <button className="icon-button" onClick={() => setMeasure(draft => {
                        if (!draft.dimension_join_trees) {
                            draft.dimension_join_trees = [];
                        }
                        draft.dimension_join_trees.push({
                            pipeline_node_id: '',
                            label: '',
                            join_tree: null
                        })
                    })}>
                        <i className="mdi mdi-plus-circle"></i> Add New
                    </button>
                </DraftOnly>
            </div>
            <p className="small">Choose all dimensions that this measure can be sliced and diced by.</p>
            {!measure?.pipeline_node_id && <>
                <Warning>
                    <div>Please select a node for this measure before connecting dimensions.
                    </div>
                </Warning>
            </>}
            {measure && measure.pipeline_node_id && 
                <DimensionPicker 
                    additionalNodeIdsInWhitelist={additionalNodeIdsInWhitelist}
                    businessObjectGraph={graph}
                    measurePipelineNodeId={measure?.pipeline_node_id || ''}
                    existingDimensions={measure?.dimension_join_trees || []}
                    onChange={setMeasure}
                />
            }
            <hr />
            <h2>Data Whitelist</h2>
            <DataWhitelistForm
                nodeIds={filterableNodeIds}
                config={measure?.data_whitelist || {
                    entries: [],
                    logic_gate: 'AND',
                }}
                onChange={(newWhitelist) => {
                    setMeasure(draft => {
                        if (!draft) return;
                        draft.data_whitelist = newWhitelist;
                    });
                }}
            />
        </div>}
        <DraftOnly>
            <button className="btn btn-success btn-lg me-1" disabled={!saveEnabled} onClick={() => {
                if (measure) {
                    props.onSubmit(measure);
                }
            }}>Save</button>
            <button className="btn btn-outline-secondary btn-lg" onClick={() => {
                props.onCancel();
            }}>Cancel</button>
        </DraftOnly>
    </>

}

export default MeasureForm;