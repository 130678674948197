import AsyncButton from "@components/button/AsyncButton.component";
import SaveButton from "@components/button/SaveButton.component";
import LoadingCard from "@components/card/LoadingCard.component";
import PliableLoader from "@components/loaders/PliableLoader.component";
import SeedEditor from "@components/seeds/SeedEditor.component";
import Danger from "@components/statusIndicators/Danger.component";
import InfoAlert from "@components/statusIndicators/InfoAlert.component";
import { regenerateSeed, saveSeed, useTranslationSeed } from "@models/seed";
import { Pane, PaneContent } from "@pages/PageStructure.component";
import ApiService, { JobEnqueueResponse, ListRecordsResponse } from "@services/api/api.service";
import BackgroundService from "@services/bg.service";
import { getErrorMessage } from "@services/errors.service";
import { convertToSnakeCase } from "@services/formatting.service";
import toast from "@services/toast.service";
import { usePipelineNode } from "@stores/data.store";
import produce from "immer";
import { set } from "immer/dist/internal";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";


interface Props {
    pipelineNodeId: string;
    fieldId: string;
}



const PipelineNodeFieldTranslation = forwardRef((props: Props, ref) => {
    const pipelineNode = usePipelineNode(props.pipelineNodeId);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');

    const [error, setError] = useState('');

    

    const fieldName = useMemo(() => {
        if (!pipelineNode.data) {
            return '';
        }

        const theField = pipelineNode.data.fields.find(f => f.id === props.fieldId);
        if (theField) {
            return theField.label;
        }
        return '';
    }, [pipelineNode.dataUpdatedAt, props.fieldId]);

    const translationSeed = useTranslationSeed(props.pipelineNodeId as string, props.fieldId);
    const [tableData, setTableData] = useState<string[][]>([]);

    useEffect(() => {
        if (translationSeed.data) {
            console.log('Setting table data:', translationSeed.data.seed_data);
            setTableData(translationSeed.data.seed_data || []);
        }
    }, [translationSeed.dataUpdatedAt]);
    const [loading, setLoading] = useState(false);

    const [generating, setGenerating] = useState(false);
    const navigate = useNavigate();

    useImperativeHandle(ref, () => {
        return {
            save: async () => {
                if (!translationSeed.data) {
                    return;
                }
                await saveSeed(produce(translationSeed.data, draft => {
                    draft.seed_data = tableData;
                }))
            }
        }
    })
    const generateSeed = useCallback(async () => {
        console.log('Generating seed', pipelineNode.data, fieldName);
        if (!pipelineNode.data) {
            return;
        }

        if (fieldName == '') {
           toast('danger', 'Error', 'Please build before generating a translation file')
           return;
        }
        
        setGenerating(true);

        const model_name = convertToSnakeCase('translate_' + pipelineNode.data.name + '_' + fieldName.toLowerCase());
        
        // Create a new seed
        const savedSeed = await saveSeed({
            id: null,
            name: model_name,
            label: model_name,
            description: '',
            table_name: '',
            upstream_node_ids: [],
            fields: [],
            node_type: 'SEED',
            seed_type: 'TRANSLATION',
            seed_headers: ['VALUE', 'TRANSLATION'],
            seed_data: [],
            dbt_model_path: `pliable/translation/${model_name}.csv`,
            generated_from_node_column: props.fieldId,
            generated_from_node_id: pipelineNode.data.id as string,
        });

        const jobInfo = await regenerateSeed(savedSeed.id as string) as JobEnqueueResponse;
        const result = await BackgroundService.getInstance().waitForJob(jobInfo.job_id);
        await translationSeed.refetch();
        setGenerating(false);



    }, [pipelineNode.dataUpdatedAt, fieldName, props.fieldId]);

    const regenerate = useCallback(async () => {
        if (!translationSeed.data) {
            return;
        }
        setGenerating(true);
        const jobInfo = await regenerateSeed(translationSeed.data.id as string) as JobEnqueueResponse;
        try {
            const result = await BackgroundService.getInstance().waitForJob(jobInfo.job_id);
            await translationSeed.refetch();
            toast('success', 'Success', 'Table updated with latest distinct values.');

        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setGenerating(false);

        }
        
    }, [translationSeed.dataUpdatedAt]);

   
    if (error) {
        return <Danger>{error}</Danger>
    }

    if (translationSeed.isLoading) {
        return <PliableLoader/>
    }

    if (!translationSeed.data) {
        return <div>
            {generating && (
                <>
                    <i className="mdi mdi-loading mdi-spin"></i> Populating distinct values...
                </>
            )}
            {!generating && (
                <div>
                    <p>Click on "Generate translation file" below to create a list of distinct values in this column.</p>
                    
                    <button className="btn btn-primary btn-sm" onClick={generateSeed}>Generate translation file</button>
                </div>
            )}
        </div>
            
            
    } else if (generating) {
        return <>
            <i className="mdi mdi-loading mdi-spin"></i> Populating distinct values...
        </>
    }

    return <>
        <SeedEditor 
            seedId={translationSeed.data.id as string}
            seedHeaders={['VALUE', 'TRANSLATION']}
            seedData={tableData}
            onChangeData={setTableData}
        />
        <button className="btn btn-outline-secondary btn-sm" onClick={regenerate}>
            <i className="mdi mdi-refresh"></i> Regenerate data
        </button>
    </>;
})

export default PipelineNodeFieldTranslation;