import { getErrorMessage } from '@services/errors.service';
import TenantService from '@services/tenant/tenant.service';
import TenantConfigService from '@services/tenantConfig.service';
import AsyncButton from '@components/button/AsyncButton.component';
import produce from 'immer';
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Modal, Spinner } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { CopyBlock, atomOneLight } from "react-code-blocks";
import { Link, useNavigate } from 'react-router-dom';
import BackgroundService from '@services/bg.service';
import useGlobalState from '@stores/global.state';
import pliablemark from '@assets/images/pliable/brand_mark_orange.png';
import snowflakemark from '@assets/images/snowflake_logo.png';
import dbtmark from '@assets/images/dbt.png';
import styled, { css } from 'styled-components';
import ApiService from '@services/api/api.service';
import toast from '@services/toast.service';
import PageStructure, { PageContentNoSidebar, Pane, PaneContent } from '@pages/PageStructure.component';
import { useHotkeys } from 'react-hotkeys-hook';
import { invalidateEverything } from '@stores/data.store';
import ConfigService, { SurveyAnswers } from '@services/config/config.service';
import { useAuthState } from '@services/auth/auth.service';
import { EventScheduledEvent, InlineWidget, useCalendlyEventListener } from "react-calendly";
import { isValidSnowflakeURL } from '@services/url.service';

const HeaderImage = styled.img`
max-height: 50px;
`
interface SnowflakeDetails {
    host: string;
    username: string;
    password: string;
    database: string;
    query_warehouse: string;
    loading_warehouse: string;
    build_warehouse: string;
    role: string;
    loader_user?: string;
    loader_password?: string;
    loader_database?: string;
    loader_role?: string;
}

const StepHeaderStyles = styled.div<{active?: boolean;}>`
display: flex;
align-items: center;

h4 {
    margin-bottom: 0px;
    flex: 1;
}

${props => !props.active && 
    css`
        margin-bottom: 0px;

        h4 {
            color: var(--ct-text-muted);
        }
    `
}

${props => !!props.active && 
    css`
        margin-bottom: 1.5rem;
        button {
            display: none;
        }
    `
}
`

const DbtIcon = () => {
    return <img style={{maxWidth: '15px'}} src={dbtmark}/>
}

const DBTInfo = (props: PropsWithChildren) => {
    return <div className="mb-1 font-13">
        <span className="me-1"><DbtIcon/></span> {props.children}
    </div>
}

interface StepHeaderProps {
    onGoBack: () => any;
    active: boolean;
    stepNumber: string;
    title: string;
    allowNavigation?: boolean;
}

const StepHeader = (props: StepHeaderProps) => {
    return <StepHeaderStyles active={props.active}>
        <h4>{props.stepNumber}. {props.title}</h4>
        {props.allowNavigation && (
            <button className="btn btn-sm btn-light" onClick={props.onGoBack}>Edit</button>
        )}
        
    </StepHeaderStyles>
}

const DatabaseSetupPage = () => {
    const navigate = useNavigate();
    const setDataLibraryEnabled = useGlobalState((state: any) => state.setLibraryEnabled);
    setDataLibraryEnabled(false);
    const [snowflakeDetails, setSnowflakeDetails] = useState<SnowflakeDetails>({
        host: '',
        username: 'PLIABLE_USER',
        password: '',
        database: 'PLIABLE_DATABASE',
        role: 'PLIABLE_ROLE',
        query_warehouse: 'PLIABLE_QUERY_WAREHOUSE',
        loading_warehouse: 'PLIABLE_LOADING_WAREHOUSE',
        build_warehouse: 'PLIABLE_BUILD_WAREHOUSE',
        loader_user: 'PLIABLE_LOADER_USER',
        loader_database: 'PLIABLE_LOADER_DATABASE',
        loader_role: 'PLIABLE_LOADER_ROLE',
    
    });

    const authState = useAuthState();

    // useEffect(() => {
    //     if (authState?.user) {
    //         // @ts-ignore
    //         Calendly.initInlineWidget({
    //             url: 'https://calendly.com/d/ckc3-gd4-2wh/pliable-onboarding',
    //             parentElement: document.getElementById('calendly-widget'),
    //             prefill: {
    //                 email: authState?.user.email,
    //                 name: authState?.user.name,
    //             },
    //             utm: {}
    //         });
    //     }
    // }, [authState?.user]);

    const [tenantConfigId, setTenantConfigId] = useState('');

    const [activationCode, setActivationCode] = useState('');
    const [tenantInfraIsReady, setTenantInfraIsReady] = useState(false);
    const [showPliableHostedSnowflakeCode, setShowPliableHostedSnowflakeCode] = useState(false);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');



    useHotkeys('ctrl+h', () => {
        console.log('Hit ctrl h');
        if(!tenantInfraIsReady){
            setShowPliableHostedSnowflakeCode(!showPliableHostedSnowflakeCode);
        }
    }, [tenantInfraIsReady, showPliableHostedSnowflakeCode]);

    const [hasWarehouse, setHasWarehouse] = useState<boolean|undefined>(undefined);
    const [warehouseSelection, setWarehouseSelection] = useState<string>('');
    const [hasETL, setHasETL] = useState<boolean|undefined>(undefined);
    const [etlSelection, setEtlSelection] = useState('');
    const [onboardingInfo, setOnboardingInfo] = useState<undefined|SurveyAnswers>(undefined);
    const [calendlyEventUri, setCalendlyEventUri] = useState('');
    const [calendlyInviteeUri, setCalendlyInviteeUri] = useState('');

    const loadData = async () => {
        setLoading(true);
        try {
            const {
                tenantRegistration,
                tenant_config,
                connection_creds,
                loader_creds
            } : any = await TenantService.getInstance().getTenantRegistration();
    
            setTenantConfigId(tenant_config.id);
            setTenantInfraIsReady(tenant_config.infra.is_ready);
            if (tenant_config.onboarding_info) {
                if (tenant_config.onboarding_info.database) {
                    setHasWarehouse(tenant_config.onboarding_info.database.hasWarehouse);
                    setWarehouseSelection(tenant_config.onboarding_info.database.warehouse);
                    setCalendlyEventUri(tenant_config.onboarding_info.database.onboardingEventLink || '');
                    setCalendlyInviteeUri(tenant_config.onboarding_info.database.onboardingInviteeLink || '');
                }
                
                setOnboardingInfo(tenant_config.onboarding_info);
            }
            

            setChosenDatabase(tenant_config.database);
            setUseTemplate(tenant_config.last_used_template);
    
            setSnowflakeDetails(produce(snowflakeDetails, draft => {
                return {
                    host: connection_creds.host,
                    username: connection_creds.username,
                    password: connection_creds.password,
                    database: connection_creds.database,
                    role: connection_creds.role,
                    query_warehouse: connection_creds.query_warehouse,
                    loading_warehouse: loader_creds.loading_warehouse,
                    build_warehouse: connection_creds.build_warehouse,
                    loader_role: loader_creds.role,
                    loader_user: loader_creds.username,
                    loader_password: loader_creds.password,
                    loader_database: loader_creds.database,
                };
            }));
        } catch (err) {
            setError(getErrorMessage(err));
        } finally {
            setLoading(false);
        }
        
    }

    useEffect(() => {
        loadData();
    }, []);

    



    const [savingDatabaseChoice, setSavingDatabaseChoice] = useState(false);
    const chooseDatabase = useCallback(async (db: string) => {
        setSavingDatabaseChoice(true);
        
        try {
            const newConfig = await TenantConfigService.getInstance().saveDatabaseChoice(tenantConfigId, db);
            setChosenDatabase(db);

        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setSavingDatabaseChoice(false);
        }
    }, [tenantConfigId]);

    const [advanced, setAdvanced] = useState(false);

    const [onboardingMethod, setOnboardingMethod] = useState('');
    const [chosenDatabase, setChosenDatabase] = useState('');
    const [useTemplate, setUseTemplate] = useState('');
    const [savingConfig, setSavingConfig] = useState(false);
    const [creatingHostedSnowflake, setCreatingHostedSnowflake] = useState(false);
    const [configSaved, setConfigSaved] = useState(false);
    const [saveConfigError, setSaveConfigError] = useState('');
    const [setupSql, setSetupSql] = useState('');
    const [generatingScript, setGeneratingScript] = useState(false);
    const [generatingScriptError, setGeneratingScriptError] = useState('');
    const [skipReveal, setSkipReveal] = useState(false);

    const [testingCredentials, setTestingCredentials] = useState(false);
    const [credentialsError, setCredentialsError] = useState('');
    const [credentialsAreValid, setCredentialsAreValid] = useState(false);

    const validHost = useMemo(() => {
        const isValid = isValidSnowflakeURL(snowflakeDetails.host);
        return isValid;
    }, [snowflakeDetails.host]);

    const formIsValid = useMemo(() => {
        return validHost && !!snowflakeDetails.username && !!snowflakeDetails.database && !!snowflakeDetails.role && !!snowflakeDetails.query_warehouse;
    }, [snowflakeDetails, validHost]);

    const setSnowflakeValue = (key: keyof SnowflakeDetails, value: string) => {
        setSnowflakeDetails(produce(snowflakeDetails, draft => {
            draft[key] = value;
        }));
    }

    const saveSnowflakeDetails = useCallback(async () => {
        setSavingConfig(true);

        try {
            await TenantConfigService.getInstance().saveSnowflakeDetails(tenantConfigId, snowflakeDetails);
            setConfigSaved(true);
            setOnboardingStep(1);
        } catch (err) {
            setSaveConfigError(getErrorMessage(err));
        } finally {
            setSavingConfig(false);
        }
        
    }, [snowflakeDetails, tenantConfigId]);

    const [showScriptModal, setShowScriptModal] = useState(false);

    const generateScript = useCallback(async () => {
        setGeneratingScript(true);

        try {
            const { snowflake_setup_sql } : any = await TenantConfigService.getInstance().showSetupScript(tenantConfigId);
            setSetupSql(snowflake_setup_sql);
            setShowScriptModal(true);
        } catch (err) {
            setGeneratingScriptError(getErrorMessage(err));
        } finally {
            setGeneratingScript(false);
        }
        
    }, [snowflakeDetails, tenantConfigId]);

    const onSetupComplete = useCallback(() => {
        if(useTemplate) {
            navigate(`/template/${useTemplate}?autouse=true`);
        }

    }, [useTemplate]);

    const testCredentials = useCallback(async () => {
        setTestingCredentials(true);
        setCredentialsAreValid(false);
        setCredentialsError('');
        try {
            const { has_db_access } : any = await TenantConfigService.getInstance().verifySnowflakeAccess(tenantConfigId);

            if (!has_db_access) {
                throw new Error('Unable to connect to Snowflake.');
            } 
            const {job_id} : any = await TenantConfigService.getInstance().initInfrustructure(tenantConfigId);
            const {infra} : any = await BackgroundService.getInstance().waitForJob(job_id);
            if (infra.is_ready) {
                setCredentialsAreValid(true);
                setOnboardingStep(2);
            } else {
                throw new Error('Error setting up infrastructure.');
            }
        } catch (err) {
            setCredentialsError(getErrorMessage(err));
            setCredentialsAreValid(false);
        } finally {
            setTestingCredentials(false);
        }
    }, [snowflakeDetails, tenantConfigId]);

    const createPliableHostedSnowflake = useCallback(async () => {
        setCreatingHostedSnowflake(true);

        try {
            await TenantConfigService.getInstance().createHostedSnowflake(tenantConfigId, activationCode);
            ConfigService.getInstance().loadConfig(); // need to flush tenant config
            setCredentialsAreValid(true);
            setOnboardingStep(2);
        } catch (err) {
            setSaveConfigError(getErrorMessage(err));
        } finally {
            setCreatingHostedSnowflake(false);
        }
    }, [activationCode, tenantConfigId]);

    const [creatingPliableHostedGit, setCreatingPliableHostedGit] = useState(false);

    const savePliableGitSource = useCallback(async () => {
        setCreatingPliableHostedGit(true);

        try {
            const response = await TenantConfigService.getInstance().saveRepo(tenantConfigId, 'PLIABLE', '');
            navigate('/');
        } catch (err) {
            setSaveConfigError(getErrorMessage(err));
        } finally {
            setCreatingPliableHostedGit(false);
        }
    }, [tenantConfigId]);

    const [onboardingStep, setOnboardingStep] = useState(0);
   
    
    const runSave = useCallback(async (hasWarehouseValue: undefined|boolean, warehouseSelectionValue: string, calendlyEventUri?: string, calendlyInviteeUri?: string) => {
        if (!onboardingInfo) {
            return;
        }
        if (!onboardingInfo.database) {
            onboardingInfo.database = {
                warehouse: '',
                etl: '',
            }
        }


        if (hasWarehouseValue === undefined) {
            delete onboardingInfo.database.hasWarehouse;
        } else {
            onboardingInfo.database.hasWarehouse = hasWarehouseValue;
        }

        if (calendlyEventUri) {
            onboardingInfo.database.onboardingEventLink = calendlyEventUri;
        }
        if (calendlyInviteeUri) {
            onboardingInfo.database.onboardingInviteeLink = calendlyInviteeUri;
        }
        onboardingInfo.database.warehouse = warehouseSelectionValue;
        try {
            await TenantConfigService.getInstance().updateOnboardingInfo(tenantConfigId, onboardingInfo);
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        }
    }, [tenantConfigId, onboardingInfo]);

    useEffect(() => {
        runSave(hasWarehouse, warehouseSelection, calendlyEventUri, calendlyInviteeUri);
    }, [warehouseSelection, hasWarehouse, runSave, calendlyEventUri, calendlyInviteeUri]);
    const goBack = useCallback(() => {
        if (hasWarehouse && warehouseSelection == 'snowflake') {
            setWarehouseSelection('');
        } else if (hasWarehouse !== undefined) {
            setWarehouseSelection('');
            
            setHasWarehouse(undefined);
        } else if (hasWarehouse === undefined) {
            navigate('/onboarding');
        }
    }, [hasWarehouse, warehouseSelection]);

    const onEventScheduled = useCallback((e: EventScheduledEvent) => {
        console.log('EVENT SCHEDULED:', e);
        setCalendlyEventUri(e.data.payload.event.uri);
        setCalendlyInviteeUri(e.data.payload.invitee.uri);

    }, [hasWarehouse, warehouseSelection]);
    useCalendlyEventListener({
       onEventScheduled: onEventScheduled,
    });
    if (loading) {
        return <>
            <Spinner/>
        </>
    }
    if (calendlyEventUri) {
        return <PageStructure
            pageTitle="Project Setup">
            <PageContentNoSidebar>
                <Pane>
                    <PaneContent>
                        <div className="p-onboarding">
                            <div className="shadow-box p-3">
                                <h1>Your onboarding is confirmed.</h1>
                                <p>Once we've set you up, you'll be able to log into your account and start using your data.</p>
                                <h2>While you wait...</h2>
                                <ul>
                                    <li>Grab your API key from Foobar</li>
                                    <li>Get the hostname, username, and password for your database</li>
                                    <li>etc...</li>
                                </ul>
                            </div>
                        
                        </div>
                    </PaneContent>
                </Pane>
            </PageContentNoSidebar>
        </PageStructure>
    }
    return <PageStructure
        pageTitle="Project Setup"
    >
        <Modal show={showScriptModal} size="lg" onHide={() => setShowScriptModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Setup Script</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Log into your Snowflake account and run the following script in a new worksheet. Make sure "All Queries" is checked. When you're done, close this window and click on "Test Credentials".
                </p>
                <div style={{maxHeight: 400, overflowX: 'scroll'}} className="mb-3">
                    <CopyBlock
                        text={setupSql}
                        language="sql"
                        showLineNumbers={false}
                        theme={atomOneLight}
                        codeBlock={true}
                    />
                </div>
            </Modal.Body>
        </Modal>
        
    </PageStructure>

       
}

export default DatabaseSetupPage;