import React from "react";
import { useLocation } from "react-router";
import urlcat from 'urlcat';

type ParamMap = Record<string, any>;


export function isValidSnowflakeURL(url: string): boolean {
    if(!!url){
        // The URL should contain the account name followed by ".snowflakecomputing.com/"
        const regex = /^([^.]+\.)?([^.]+\.)([^.]+\.)?snowflakecomputing\.com$/;
        const match = url.match(regex);
        if (match !== null) {
        return true;
        }
    }
    return false;
  }

export function apiurl(pathTemplate: string, params: ParamMap){
    let url =  urlcat(pathTemplate, params);

    // Quickfix: urlcat doesn't support adding params to a url that already has params, so we'll replace question marks after the first one with ampersands.
    const spl = url.split('?');
    if (spl.length > 1) {
        const first = spl.shift();
        url = first + '?' + (spl.join('&'));
    }
    return url;

}

export function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}
