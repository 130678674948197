import React, { useMemo } from "react";
import Dropdown, { Option } from "@components/form/Dropdown.component";

interface Props {
    onChange: (newFormat: string) => void;
    selectedFormat: string;
    placeholder?: string;
    options?: Option[];
    disabled?: boolean;
}

const FormatForm: React.FC<Props> = (props: Props) => {

    const formatOptions = useMemo(() => {
        return [{
            value: 'FINANCIAL',
            label: 'Financial',
        }, {
            value: 'NUMERIC',
            label: 'Number',
        }, {
            value: 'PERCENT',
            label: 'Percent',
        }, {
            value: 'DATE',
            label: 'Date',
        }, {
            value: 'LONG_DATE',
            label: 'Long Date',  
        }];
    }, []);

    const handleChange = (newValue: string) => {
        props.onChange(newValue);
    };

    return (
        <div>
            <Dropdown
                selected={props.selectedFormat || ''}
                options={formatOptions || []}
                onChange={handleChange}
                placeholder={props.placeholder || 'Select'}
                className="input-sm"
                disabled={props.disabled}
            />
        </div>
    );
};

export default FormatForm;
