import form from "modules/rfb/form";
import ApiService, { JobEnqueueResponse } from "./api/api.service";
import BackgroundService from "./bg.service";
import { Config, FormatLocale, SurveyAnswers } from "./config/config.service";

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));



export default class TenantConfigService {
  private static instance: TenantConfigService;

  public static getInstance(): TenantConfigService {
    if (!TenantConfigService.instance) {
      TenantConfigService.instance = new TenantConfigService();
    }

    return TenantConfigService.instance;
  }

  constructor() {
  }

  public async saveBuildSchedule(tenantConfigId: string, executionSchedule: any) {
      return await ApiService.getInstance().request('PUT', `/tenant-config/${tenantConfigId}/build-schedule`, executionSchedule);
  }

  public async saveFormatLocale(tenantConfigId: string, formatLocale: FormatLocale) {
    console.log(formatLocale, 'before save');
    return await ApiService.getInstance().request('PUT', `/tenant-config/${tenantConfigId}/format-locale`, { format_locale: formatLocale });
  }

  public async autoSetupSnowflake(tenantConfigId: string, adminUsername: string, adminPassword: string) {
    return await ApiService.getInstance().request('POST', `/tenant-config/${tenantConfigId}/auto-setup-snowflake`, {
      username: adminUsername,
      password: adminPassword,
    })
  }

  public async saveSnowflakeDetails(tenantConfigId: string, details: any) {
      return await ApiService.getInstance().request('PUT', `/tenant-config/${tenantConfigId}/snowflake-creds`, details);
  }

  public async saveSnowflakeBiDetails(tenantConfigId: string, details: any) {
    return await ApiService.getInstance().request('PUT', `/tenant-config/${tenantConfigId}/snowflake-bi-creds`, details);
}

  public async updateOnboardingInfo(tenantConfigId: string, answers: SurveyAnswers) {
    return await ApiService.getInstance().request('POST', `/tenant-config/${tenantConfigId}/set-onboarding`, answers);
  }

  public async showSetupScript(tenantConfigId: string) {
    return await ApiService.getInstance().request('POST', `/tenant-config/${tenantConfigId}/snowflake-setup-script`, {});
  }

  public async showBiSetupScript(tenantConfigId: string) {
    return await ApiService.getInstance().request('POST', `/tenant-config/${tenantConfigId}/snowflake-bi-setup-script`, {});
  }

  public async verifySnowflakeAccess(tenantConfigId: string) {
      return await ApiService.getInstance().request('GET', `/tenant-config/${tenantConfigId}/verify-snowflake-creds`);
  }

  public async verifySnowflakeBiAccess(tenantConfigId: string) {
    return await ApiService.getInstance().request('GET', `/tenant-config/${tenantConfigId}/verify-snowflake-bi-creds`);
}

  public async initInfrustructure(tenantConfigId: string) {
    return await ApiService.getInstance().request('POST', `/tenant-config/${tenantConfigId}/init-infrustructure`, {});
  }

  public async saveDatabaseChoice(tenantConfigId: string, databaseChoice: string) {
    return await ApiService.getInstance().request('POST', `/tenant-config/${tenantConfigId}/set-database`, {
      'database': databaseChoice
    }) as Config;
  }

  public async saveRepo(tenantConfigId: string, git_source:string, repo: string) {
    return await ApiService.getInstance().request('POST', `/tenant-config/${tenantConfigId}/set-repo`, {
      'git_source': git_source,
      'repo': repo
    }) as Config;
  }

  public async createHostedSnowflake(tenantConfigId: string, activationCode: string) {
    const createHostedResp = await ApiService.getInstance().request('POST', `/tenant-config/${tenantConfigId}/create-pliable-hosted-snowflake`, {activationCode}) as JobEnqueueResponse;
    const jobResults = await BackgroundService.getInstance().waitForJob(createHostedResp.job_id);
    return jobResults;
  }

  public async ensureHostedSuperset(tenantConfigId: string) {
    const createHostedResp = await ApiService.getInstance().request('POST', `/tenant-config/${tenantConfigId}/ensure-pliable-hosted-superset`, {}) as JobEnqueueResponse;
    const jobResults = await BackgroundService.getInstance().waitForJob(createHostedResp.job_id);
    return jobResults;
  }

  public async syncSupersetDatasets(tenantConfigId: string) {
    const createSyncResp = await ApiService.getInstance().request('POST', `/tenant-config/${tenantConfigId}/sync-superset-datasets`, {}) as JobEnqueueResponse;
    const jobResults = await BackgroundService.getInstance().waitForJob(createSyncResp.job_id);
    return jobResults;
  }

  public async commandAssistant(command: string) {
    const commandResp = await ApiService.getInstance().request('POST', `/tenant-config/data-assistant`, {command}) as JobEnqueueResponse;
    const jobResults = await BackgroundService.getInstance().waitForJob(commandResp.job_id);
    return jobResults;
  }
}
