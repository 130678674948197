import { getErrorMessage } from '@services/errors.service';
import TenantService from '@services/tenant/tenant.service';
import TenantConfigService from '@services/tenantConfig.service';
import produce from 'immer';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { CopyBlock, atomOneLight } from "react-code-blocks";
import { useNavigate } from 'react-router-dom';
import useGlobalState from '@stores/global.state';
import PageStructure, { PageContent, PageSidebar, Pane, PaneContent } from '@pages/PageStructure.component';
import { useProjectConfig } from '@stores/data.store';
import ConfigService from '@services/config/config.service';
import AuthService from '@services/auth/auth.service';
import TenantConfigSubnav from '@components/tenantConfig/TenantConfigSubnav.component';
import PliableLoader from '@components/loaders/PliableLoader.component';
import { isValidSnowflakeURL } from '@services/url.service';
import Danger from '@components/statusIndicators/Danger.component';
import SuccessAlert from '@components/statusIndicators/SuccessAlert.component';
import AsyncButton from '@components/button/AsyncButton.component';
import { Offcanvas } from 'react-bootstrap';
import { useEntitlements } from '@frontegg/react';
import LoadingCard from '@components/card/LoadingCard.component';
import toast from '@services/toast.service';
import { SupersetNotEnabled } from '@components/bi/SupersetDisabled.component';


interface SnowflakeFormProps {
    snowflakeDetails: SnowflakeDetails;
    tenantConfigId: string;
    showPliableHostedSnowflakeCode: boolean;
    onChange: (attr: keyof SnowflakeDetails, value: any) => any;
    onCredentialsAreValid: () => any;
}

const SnowflakeBISetupForm = ({ snowflakeDetails, onChange, tenantConfigId, onCredentialsAreValid, showPliableHostedSnowflakeCode }: SnowflakeFormProps) => {
    const validHost = useMemo(() => {
        const isValid = isValidSnowflakeURL(snowflakeDetails.host);
        return isValid;
    }, [snowflakeDetails.host]);

    const formIsValid = useMemo(() => {
        return validHost && !!snowflakeDetails.username && !!snowflakeDetails.database && !!snowflakeDetails.role && !!snowflakeDetails.query_warehouse;
    }, [snowflakeDetails, validHost]);

    const [savingConfig, setSavingConfig] = useState(false);
    const [saveConfigError, setSaveConfigError] = useState('');
    const [generatingScript, setGeneratingScript] = useState(false);
    const [generatingScriptError, setGeneratingScriptError] = useState('');
    const [setupSql, setSetupSql] = useState('');
    const [showScriptModal, setShowScriptModal] = useState(false);

    const [testingCredentials, setTestingCredentials] = useState(false);
    const [credentialsAreValid, setCredentialsAreValid] = useState(false);
    const [credentialsError, setCredentialsError] = useState('');

    const generateScript = useCallback(async () => {
        setGeneratingScript(true);

        try {
            const { snowflake_setup_sql } : any = await TenantConfigService.getInstance().showBiSetupScript(tenantConfigId);
            setSetupSql(snowflake_setup_sql);
            setShowScriptModal(true);
        } catch (err) {
            setGeneratingScriptError(getErrorMessage(err));
        } finally {
            setGeneratingScript(false);
        }
        
    }, [snowflakeDetails, tenantConfigId]);

    const saveSnowflakeBiDetails = useCallback(async (doGenerateScript: boolean = false) => {
        setSavingConfig(true);

        try {
            await TenantConfigService.getInstance().saveSnowflakeBiDetails(tenantConfigId, snowflakeDetails);
            if (generateScript) {
                await generateScript();
            }
        } catch (err) {
            setSaveConfigError(getErrorMessage(err));
        } finally {
            setSavingConfig(false);
        }
        
    }, [snowflakeDetails, tenantConfigId]);
    
    const testCredentials = useCallback(async () => {
        setTestingCredentials(true);
        setCredentialsAreValid(false);
        setCredentialsError('');
        try {
            const { has_db_access } : any = await TenantConfigService.getInstance().verifySnowflakeBiAccess(tenantConfigId);

            if (!has_db_access) {
                throw new Error('Unable to connect to Snowflake.');
            } 
            
            setCredentialsAreValid(true);
            onCredentialsAreValid();
        } catch (err) {
            setCredentialsError(getErrorMessage(err));
            setCredentialsAreValid(false);
        } finally {
            setTestingCredentials(false);
        }
    }, [snowflakeDetails, tenantConfigId]);
    const [showAll, setShowAll] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    return <div style={{textAlign: 'left', overflowY: 'scroll'}}>
        <Modal show={showScriptModal} size="lg" onHide={() => setShowScriptModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Setup Script</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Log into your Snowflake account and run the following script in a new worksheet. Make sure "All Queries" is checked. When you're done, close this window and click on "Validate Connection".
                </p>
                <div style={{maxHeight: 400, overflowX: 'scroll'}} className="mb-3">
                    <CopyBlock
                        text={setupSql}
                        language="sql"
                        showLineNumbers={false}
                        theme={atomOneLight}
                        codeBlock={true}
                    />
                </div>
            </Modal.Body>
        </Modal>
        <Offcanvas show={showHelp} onHide={() => setShowHelp(false)} placement="end">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Help</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Pane>
                    <PaneContent>
                    </PaneContent>
                </Pane>
                
            </Offcanvas.Body>
        </Offcanvas>

        {!showPliableHostedSnowflakeCode && <>

            <Row style={{padding: '25px'}}>
                <div className="col-12 px-5">
                    <p className="text-muted">We'll use these details to generate a script for you to run on your Snowflake account, which will create a BI user Pliable will use to read you report data.</p>
                </div>
                <div className="col-6 px-2">
                    <Form.Group className="mr-3">
                        <Form.Label>Username</Form.Label>
                        
                        <Form.Control
                            name="username"
                            type="text"
                            placeholder=""
                            value={snowflakeDetails.username}

                            onChange={(e) => onChange('username', e.target.value)}
                        />
                        <Form.Text>
                            BI username.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mr-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            name="password"
                            type="password"
                            placeholder=""
                            value={snowflakeDetails.password}

                            onChange={(e) => onChange('password', e.target.value)}
                        />
                        <Form.Text>Leave blank and we will automatically generate one for you.</Form.Text>
                    </Form.Group>
                    <Form.Group className="mr-3">
                        <Form.Label>Role</Form.Label>
                        <Form.Control
                            name="role"
                            type="text"
                            placeholder=""
                            value={snowflakeDetails.role}

                            onChange={(e) => onChange('role', e.target.value)}
                        />
                        <Form.Text>
                            The role Pliable should use when interacting with Snowflake. 
                        </Form.Text>
                    </Form.Group>

                </div>
                <div className="col-6 px-2">
                    <Form.Group className="mr-3">
                        <Form.Label>Host</Form.Label>
                        <Form.Control
                            name="host"
                            type="text"
                            placeholder="your-account.snowflakecomputing.com"
                            value={snowflakeDetails.host}
                            disabled={true}
                        />
                        <Form.Text>
                            This is set by your snowflake configuration.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mr-3">
                        <Form.Label>Database</Form.Label>
                        <div></div>
                        <Form.Control
                            name="database"
                            type="text"
                            placeholder=""
                            value={snowflakeDetails.database}
                            disabled={true}
                        />
                        <Form.Text>
                            This is set by your snowflake configuration.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mr-3">
                        <Form.Label>Warehouse</Form.Label>
                        <Form.Control
                            name="query_warehouse"
                            type="text"
                            placeholder=""
                            value={snowflakeDetails.query_warehouse}

                            onChange={(e) => onChange('query_warehouse', e.target.value)}
                        />
                        <Form.Text>
                            The warehouse Pliable should use for querying your bi datasets.
                        </Form.Text>
                    </Form.Group>

                </div>
                <div className="col-12 p-2">
                    <hr />
                    <p>Once you're ready, generate and run the setup script to connect Pliable to your Snowflake.</p>     
                    <AsyncButton
                        variant="pliable"
                        icon='mdi mdi-database-edit-outline'
                        disabled={!formIsValid}
                        loading={savingConfig}
                        onClick={() => saveSnowflakeBiDetails(true)}
                        text="Generate Setup Script"
                    />
                    <hr />

                    <p>Once you've run the script, we just need to validate the connection.</p>                       
            
                    <AsyncButton
                        variant="primary"
                        icon='mdi mdi-database-edit-outline'
                        disabled={!formIsValid}
                        loading={testingCredentials}
                        onClick={() => testCredentials()}
                        text="Validate Connection"
                    />
                    {testingCredentials && (
                        <div>
                            <Form.Text>
                                We're running a few different checks, which may take up to 30 seconds.
                            </Form.Text>
                        </div>
                        
                    )}
                    {credentialsError && (
                        <>
                            <p>
                                <strong className="text-danger">Oops!</strong> We were unable to connect to your Snowflake. Please check your credentials and make sure the setup script ran successfully.
                            </p>
                            <Danger>
                                {credentialsError}
                            </Danger>
                        </>
                    )}
                    {!testingCredentials && credentialsAreValid && <div className="mt-2">
                        <SuccessAlert>Connection successful!</SuccessAlert>

                    </div>
                    }

                </div>
            </Row>
        </>}
        
                    
    </div>
}


interface SnowflakeDetails {
    host: string;
    username: string;
    password: string;
    database: string;
    query_warehouse: string;
    loading_warehouse: string;
    build_warehouse: string;
    role: string;
    loader_user?: string;
    loader_password?: string;
    loader_database?: string;
    loader_role?: string;
}



const SupersetSetupPage = () => {
    const navigate = useNavigate();
    const {dataUpdatedAt, data: tenantConfig} = useProjectConfig();
    const tenant_name = AuthService.getInstance().getTenantName();
    const [ensuringSupersetSetup, setEnsuringSupersetSetup] = useState(false);

    const { isEntitled: hostedSupersetEnabled } = useEntitlements({
        featureKey: "hosted_superset",
    });

    const setDataLibraryEnabled = useGlobalState((state: any) => state.setLibraryEnabled);
    setDataLibraryEnabled(false);

    const [snowflakeDetails, setSnowflakeDetails] = useState<SnowflakeDetails>({
        host: '',
        username: 'SUPERSET_BI_USER',
        password: '',
        database: 'PLIABLE_DATABASE',
        role: 'PLIABLE_BI_ROLE',
        query_warehouse: 'PLIABLE_BI_WH',
        loading_warehouse: '',
        build_warehouse: '',
        loader_user: '',
        loader_database: '',
        loader_role: '',
    });


    const [tenantConfigId, setTenantConfigId] = useState('');
    const [hasSuperSetCreds, setHasSupersetCreds] = useState(false);
    const [confirming, setConfirming] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [syncingDatasets, setSyncingDatasets] = useState(false);
    const [showPliableHostedSnowflakeCode, setShowPliableHostedSnowflakeCode] = useState(false);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const loadData = async () => {
        setLoading(true);
        try {
            const {
                tenant_config,
                connection_creds,
                superset_creds
            } : any = await TenantService.getInstance().getTenantRegistration();
    
            setTenantConfigId(tenant_config.id);
    
            if(superset_creds) {
                setSnowflakeDetails(produce(snowflakeDetails, draft => {
                    return {
                        host: connection_creds.host,
                        username: superset_creds.username,
                        password: superset_creds.password,
                        database: connection_creds.database,
                        role: superset_creds.role,
                        query_warehouse: superset_creds.query_warehouse,
                        loading_warehouse: superset_creds.loading_warehouse,
                        build_warehouse: superset_creds.build_warehouse,
                        loader_role: superset_creds.role,
                        loader_user: superset_creds.username,
                        loader_password: superset_creds.password,
                        loader_database: superset_creds.database,
                    };
                }));
                setHasSupersetCreds(true);
            }else{
                setSnowflakeDetails(produce(snowflakeDetails, draft => {
                    draft.host = connection_creds.host;
                    draft.database = connection_creds.database;
                }));
            }
        } catch (err) {
            setError(getErrorMessage(err));
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    const setSnowflakeValue = (key: keyof SnowflakeDetails, value: string) => {
        setSnowflakeDetails(produce(snowflakeDetails, draft => {
            draft[key] = value;
        }));
    }

    const ensureSuperset = async () => {
        setEnsuringSupersetSetup(true);
        try {
            await TenantConfigService.getInstance().ensureHostedSuperset(ConfigService.getInstance().config!.id);
            toast('success', 'Success', 'BI setup complete');
        }catch(err){
            toast('danger', 'Error', getErrorMessage(err));
        }finally{
            setEnsuringSupersetSetup(true);
        } 
    }

    const syncSupersetDatasets = async () => {
        setSyncingDatasets(true);
        try {
            await TenantConfigService.getInstance().syncSupersetDatasets(ConfigService.getInstance().config!.id);
            toast('success', 'Success', 'Dataset sync complete.');
        }catch(err){
            toast('danger', 'Error', getErrorMessage(err));
        }finally{
            setSyncingDatasets(false);
        }          
    }


    const getContent = useCallback(() => {
        let content = null;

        if (loading){
            <LoadingCard />
        }else if(!hostedSupersetEnabled){
            content = <SupersetNotEnabled />
        }else if(!hasSuperSetCreds || editMode){
            content = <Row>
                <div className="col-12 px-5">
                    <h2>BI Setup  
                        {editMode && <button className="btn btn-small btn-outline-secondary float-right" onClick={() => setEditMode(false)}>Cancel Edit</button> }
                    </h2>
                    <hr />
                    <SnowflakeBISetupForm
                        snowflakeDetails={snowflakeDetails}
                        onChange={setSnowflakeValue}
                        tenantConfigId={tenantConfigId}
                        showPliableHostedSnowflakeCode={showPliableHostedSnowflakeCode}
                        onCredentialsAreValid={() => {
                            setHasSupersetCreds(true);
                            setEditMode(false);
                        }}
                    />
                </div>
            </Row>;
        }else if(!!ConfigService.getInstance().config?.superset) {
            content = <Row>
                <div className="col-12 px-5">
                    <h2>BI Setup  
                        <button className="btn btn-small btn-outline-secondary float-right" onClick={() => setEditMode(true)}>Edit BI User Creds <i className='mdi mdi-pencil'></i></button> 
                    </h2>
                    <hr />
                    <AsyncButton
                        onClick={syncSupersetDatasets}
                        text={'Sync BI Datasets'}
                        loading={syncingDatasets}
                        />
                </div>
            </Row>;
        }else{
            content = <Row>
                <div className="col-12 px-5">
                    <h2>BI Setup  
                        <button className="btn btn-small btn-outline-secondary float-right" onClick={() => setEditMode(true)}>Edit BI User Creds <i className='mdi mdi-pencil'></i></button> 
                    </h2>
                    <hr />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: '100px' }}>
                        <h1>BI Warehouse is not configured</h1>
                        <p>Please contact your administrator about pricing.</p>
            
                        <AsyncButton 
                            onClick={ensureSuperset}
                            text={'Enable Superset BI'}
                            loading={confirming}
                        ></AsyncButton>
            
                        {confirming && <p className="text-muted" style={{ padding: '15px'}}>This may take a couple minutes</p>}
                    </div>
                </div>
            </Row>;
        }

        return content;

    }, [snowflakeDetails, tenantConfigId, hasSuperSetCreds, editMode, syncingDatasets, confirming]);


    return <PageStructure
            pageTitle="Bi Setup"
            breadcrumbs={[
                {
                    title: 'Account',
                }
                
            ]}
        >
            <PageSidebar>
            <Pane>
                <PaneContent>
                {!tenantConfig && (
                                <PliableLoader />
                            )}

                {tenantConfig && (
                    <div className="p-3">
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Account Name
                            </Form.Label>
                            <Form.Control value={tenant_name as string} disabled={true} />
                            <Form.Text></Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>
                                Account ID
                            </Form.Label>
                            <Form.Control value={tenantConfig.tenant_slug as string} disabled={true} />
                            <Form.Text>
                                <strong>cannot be changed</strong> after creation
                            </Form.Text>
                        </Form.Group>
                        <hr />
                    </div> 
                )}
                </PaneContent>
            </Pane>
        </PageSidebar>
        <PageContent>

            <TenantConfigSubnav>
            </TenantConfigSubnav>
            <Row style={{padding: '25px'}}>
                <div className="col-12">
                    {getContent()}
                </div>  
                </Row>
        </PageContent>
        </PageStructure>; 
}

export default SupersetSetupPage;