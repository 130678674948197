import formatNumber from 'format-number';
import moment from 'moment';
import ConfigService from './config/config.service';


export const integerFormatter = (num: number): string => {
    if (num == null) {
        return '-';
    }
    
    let formatted = decimalFormatter(Math.abs(num));
    if (isNaN(num)) {
        formatted = num.toString();
    }
    return formatted;
}

export const decimalFormatter = formatNumber({
    round: 2,
});

export const formatFinancial = (num: number) => {
    const formatLocale = ConfigService.getInstance().config?.format_locale;
    const isNegative = num < 0;
    let formatted = decimalFormatter(Math.abs(num));

    if (num == null) {
        return '-'
    }
    if (isNaN(num)) {
        return num.toString();
    }

    if (formatted.indexOf('.') >= 0) {
        if (formatted.split('.')[1].length == 1) {
            formatted = formatted + '0';
        }
    } else {
        formatted = formatted + '.00';
    }

    const eurFormat = (value: string): string => {
        return value
            .replace(/\./g, '#')  
            .replace(/,/g, '.') 
            .replace(/#/g, ',') 
    }

    if (formatLocale?.decimal === ',') {
        formatted = eurFormat(formatted);
    }

    if (isNegative) {
        return '(' + formatted + ')';
    }
    return formatted;
}

export const summarizeNumber = (num: number) => {
    if (num == null) {
        return '';
    }
    if (num < 1000) {
        return num.toString();
    } else if (num < 100000) {
        return ((Math.round(num/100)) / 10).toString() + 'k';
    } else if (num < 1000000) {
        return Math.floor(num/1000).toString() + 'k';
    } else if (num < 1000000000) {
        return (Math.round(num/100000) / 10).toString() + 'M';
    } else if (num < 1000000000000) {
        return (Math.floor(num/100000000) / 10).toString() + 'B';
    }
    return '';
}

export const formatPercentage = (num: number) => {
    if (num == null) {
        return '-'
    }
    if (isNaN(num)) {
        return num.toString();
    }
    return decimalFormatter(Math.round(num * 10000) / 100) + '%';
}

export const convertToSnakeCase = (val: string) => {
    const matched = val.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    
    if (matched) {
        return matched.map(s => s.toLowerCase()).join('_');
    }
    return val;
}

export const convertToTitleCase = (str: string) => {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    ).replace('_', ' ');
}

export const removeFileExtension = (filename: string) => {
    const lastIndex = filename.lastIndexOf('.');
    if (lastIndex !== -1 && lastIndex > 0) {
        return filename.substring(0, lastIndex);
    }
    return filename;
}

export const urlEncodeObject = (obj: any) => {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

export const varEx = (inputString: string, inputObject: any) => {
    // Early return if there are no $[] blocks
    if (!inputString.includes("$[")) return inputString;
  
    // Find all $[] blocks, and run a function for each block found.
    // Returns a complete string where all VarEx blocks have been parsed
    return inputString.replace(/\$\[(['"`\w[\].]+)\]/g, (_: string, key) => {
      // Initialize inputObject to be recursively parsed
      var resolvedValue = inputObject;
  
      // Check that we have equal amounts of open brackets [ as closing brackets ]
      // This allows us to check if the VarEx block is complete
      if (key.split("[").length !== key.split("]").length) return `$[${key}]`;
  
      // Replace all array blocks [] with .
      // eg. table['a'] becomes table.a
      // table[1] becomes table.1
      const cleanKey = key.replace(/\[["'`]?(\w*)["'`]?\]/g, ".$1");
  
      // Now we split the variable by . to resolve child elements if provided
      const variableBlocks = cleanKey.split(".");
  
      // Loop through each variableBlock variable (e.g variable.children.grandchildren.tableindex)
      variableBlocks.forEach((varKey: string) => {
        // Resolve value step-by-step
        // Prevent errors by checking if resolvedValue is set
        if (resolvedValue)
          resolvedValue =
            resolvedValue[varKey] !== null ? resolvedValue[varKey] : "";
      });
  
      // Return the resolved variable
      return resolvedValue as string;
    });
  }

  interface FormatProps {
    value: any;
    format?: string;
}

export const formatValue = ({ value, format }: FormatProps) => {
    let formattedValue: string;
    const parsedDate = moment(value);

    switch (format) {
        case 'PERCENT':
            formattedValue = formatPercentage(value);
            break;
        case 'FINANCIAL':
            formattedValue = formatFinancial(value);
            break;
        case 'NUMERIC':
            formattedValue = integerFormatter(value);
            break;
        case 'DATE':
            formattedValue = parsedDate.isValid() ? parsedDate.format('MM/DD/YYYY') : `${value} (not a date)`;
            break;
        case 'LONG_DATE':
            formattedValue = parsedDate.isValid() ? parsedDate.format('MMMM/DD/YYYY') : `${value} (not a date)`;
            break;
        default:
            formattedValue = value?.toString() || '-';
    }

    return formattedValue;
};
