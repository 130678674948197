import { useNavigate} from "react-router-dom";
import ForceSupersetLogout from "@components/nav/ForceSupersetLogout.component";




const LogoutPage = () => {
    const navigate = useNavigate();

    const onSupersetLogoutLoaded = () => {
        navigate('/do/logout');
    }

    return <>
        <ForceSupersetLogout showLoader={true} onComplete={onSupersetLogoutLoaded} />
    </>
    
}

export default LogoutPage;
