import { useNavigate } from "react-router-dom";
import LoadingCard from "@components/card/LoadingCard.component";


interface ForceSupersetLogoutProps {
    showLoader?: boolean;
    onComplete?: () => void;
}


const ForceSupersetLogout = ({onComplete, showLoader = false}: ForceSupersetLogoutProps) => {
    const biLogoutUrl = `https://bi.${window.location.hostname}/api/v1/security/force-logout`;

    const onSupersetLogoutLoaded = () => {
        if(onComplete){
            onComplete();
        }
    }

    return <>
        <iframe src={biLogoutUrl} 
            style={{width: '0%', height: '0vh', border: 'none'}}
            onLoad={onSupersetLogoutLoaded} onError={onSupersetLogoutLoaded}
        ></iframe>

        { showLoader && <LoadingCard action="Logging out" /> }
    </>
    
}

export default ForceSupersetLogout;